import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function BulkEditEntitlements({ children }) {
  const [selected, setSelected] = useState([]);
  const [status, setStatus] = useState({ message: null, state: null });

  return (
    <>{children({ selected, onSelect: setSelected, status, setStatus })}</>
  );
}

BulkEditEntitlements.propTypes = {
  children: PropTypes.func
};
