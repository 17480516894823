import React from 'react';
import PropTypes from 'prop-types';
import AccountsFilter from './AccountsFilter';

function EntitlementAccountFilter({ onChange, onReset, value }) {
  return (
    <div className="my pt">
      <AccountsFilter onChange={onChange} value={value} onReset={onReset}>
        <h3>Select End Use Account</h3>
      </AccountsFilter>
      <div className="form-group checkbox">
        <label htmlFor="showSubAccounts">
          Only accounts related to Purchasing Account
          <input
            id="showSubAccounts"
            name="showSubAccounts"
            type="checkbox"
            onChange={onChange}
            checked={value.showSubAccounts ?? false}
          />
        </label>
      </div>
    </div>
  );
}

EntitlementAccountFilter.propTypes = {
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  value: PropTypes.object
};

export default EntitlementAccountFilter;
