// local storage keys
export const KEY_USER = 'cs:user';
export const KEY_TOKEN = 'cs:token';
export const KEY_EXPIRES = 'cs:expires';

export const DEFAULT_PAGINATION = {
  page: 1,
  perPage: process.env.REACT_APP_PER_PAGE
    ? parseInt(process.env.REACT_APP_PER_PAGE, 10)
    : 50
};

// The max entitlement results that can be bulk edited with the SelectAllItems component.
// Determined by database performance.
export const MAX_SELECT_ALL_RESULTS = 15e3;
export const MAX_ENTITLEMENT_SORT_RESULTS = 50e3;

export const PAGER_THRESHOLD = 500000;
export const PAGER_STEP = 10;

export const SQS_TEMPLATE_URL = process.env.REACT_APP_TEMPLATE_URL ?? '';

//ROLES
export const ROLE = {
  SUPER_ADMIN: {
    roleName: 'Super Admin',
    roleId: 1
  },
  DISTRICT: {
    roleName: 'Multi-Site Admin',
    roleId: 2
  },
  STUDENT: {
    roleName: 'Student',
    roleId: 3
  },
  TEACHER: {
    roleName: 'Teacher',
    roleId: 4
  },
  BUILDING: {
    roleName: 'Building Admin',
    roleId: 5
  },
  CONTENT_MANAGER: {
    roleName: 'Content Management',
    roleId: 6
  },
  REPORTING: {
    roleName: 'Reporting',
    roleId: 7
  },
  EULA_SIGNER: {
    roleName: 'EULA Signer',
    roleId: 8
  }
};

export const NON_EXCLUSIVE_ROLES = [
  ROLE.REPORTING.roleId,
  ROLE.CONTENT_MANAGER.roleId
];

/**
 * On the backend, this value is stored as a bit in the "perpetual" column.
 * Sometimes that's inconsistent with the form input's name prop. However, since
 * the values have consistent titles and are often used as part of a select,
 * those titles are specified here for consistency.
 */
export const LICENSE = {
  SUBSCRIPTION: { id: false, name: 'Subscription' },
  PERPETUAL: { id: true, name: 'Perpetual' }
};

export const PANEL = {
  ISBN: 'isbn',
  SEARCH: 'search'
};

export const FORM_STEP = {
  SELECT_ACCOUNT: 1,
  EDIT_DETAILS: 2,
  REVIEW: 3
};

export const paths = {
  login: '/login',
  accounts: {
    index: `/accounts`,
    create: `/accounts/create`,
    show: `/accounts/:accountId`,
    edit: '/accounts/:accountId/edit',
    connections: `/accounts/:accountId/connections`,
    batches: `/accounts/:accountId/batches`,
    entitlements: `/accounts/:accountId/entitlements`,
    authenticators: `/accounts/:accountId/authenticators`
  },
  merge: {
    select: `/accounts/:accountId/merge/select`,
    direction: `/accounts/:accountId/merge/direction/:archiveId`,
    review: `/accounts/:accountId/merge/review/:archiveId`
  },
  authenticators: {
    index: '/authenticators',
    create: '/authenticators/create',
    edit: '/authenticators/:authenticator_id/edit',
    show: '/authenticators/:authenticator_id'
  },
  entitlements: {
    index: '/entitlements'
  },
  batches: {
    index: '/batches',
    create: '/batches/create',
    show: '/batches/:batch_id',
    edit: '/batches/:batch_id/edit'
  },
  manual: '/manual'
};

export const AUTH = {
  password: {
    id: 'password',
    name: 'Password',
    button: 'Password',
    labels: {
      username: 'Username',
      sqs: 'SQS Code',
      link: 'Login Link'
    }
  },
  oauth: {
    id: 'oauth',
    name: 'OAuth',
    button: 'OAuth',
    labels: {
      issuer: 'Issuer',
      external_id: 'External ID'
    }
  },
  lti: {
    id: 'lti',
    name: 'LTI',
    button: 'LTI',
    labels: {
      lti_application_id: 'Tool ID'
    }
  },
  ip: {
    id: 'ip',
    name: 'IP Range',
    button: 'IP Range',
    labels: {
      starting_ip: 'Starting IP',
      ending_ip: 'Ending IP'
    }
  },
  google: {
    id: 'google',
    name: 'Google Classroom',
    button: 'Google Classroom',
    labels: {
      classroom_id: 'Classroom ID'
    }
  }
};

export const SORT_ORDER = {
  asc: 'ASC',
  desc: 'DESC'
};

export const SOURCE = {
  salesforce: 'Salesforce',
  ax: 'AX',
  pebblego: 'PebbleGo',
  cil: 'CI'
};
