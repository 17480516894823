import React, { useState } from 'react';
import { ShowIf, MessageBlock } from '../fragments';
import PropTypes from 'prop-types';

function useClipboard() {
  const [message, setMessage] = useState('');
  const handle = {
    onCopy: (text, data) => {
      setMessage(`Your ${data} has been copied to the clipboard.`);
      navigator.clipboard.writeText(text);
    }
  };

  return {
    clearCopySuccess: () => setMessage(''),
    copy: ({ text, data }) => (
      <ShowIf value={navigator.clipboard}>
        <a href={text}>{text}</a>
        <button
          className="copy-btn"
          onClick={() => handle.onCopy(text, data)}
          title={`Copy ${data}`}
        >
          <i className="far fa-copy"></i>
        </button>
      </ShowIf>
    ),
    message: () => (
      <MessageBlock className="msg mb msg-success msg-outline status">
        {message}
      </MessageBlock>
    )
  };
}

useClipboard.propTypes = {
  label: PropTypes.string
};
export default useClipboard;
