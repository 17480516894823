import React from 'react';
import { PageHeader, ShowWith, MessageBlock, ShowIf } from '../fragments';
import {
  useParams,
  useHistory,
  generatePath,
  Redirect
} from 'react-router-dom';
import { accounts } from '../../redux/services';
import { getErrors } from '../../utils';
import { paths } from '../../constants';

export default function FinishMergeAccounts() {
  const params = useParams();
  const history = useHistory();
  const [merge, mergeMutation] = accounts.useMergeAccountsMutation();
  const { data: active } = accounts.useGetAccountQuery(params.accountId);
  const { data: archived } = accounts.useGetAccountQuery(params.archiveId);

  const handle = {
    onMerge: e => e?.preventDefault() ?? merge(params)
  };

  if (mergeMutation.isSuccess) {
    const pathname = generatePath(paths.accounts.show, {
      accountId: params.accountId
    });
    return (
      <Redirect
        to={{
          pathname,
          state: {
            success: `${archived.account_name}(${archived.account_to_crm_id}) was successfully merged into this account!`
          }
        }}
      />
    );
  }
  return (
    <div className="merge-accounts">
      <PageHeader title="Merge Accounts" />
      <ol className="breadcrumbs">
        <li className="breadcrumb disabled">Select Accounts</li>
        <li className="breadcrumb disabled">How to Merge</li>
        <li className="breadcrumb active">Review & Finish</li>
      </ol>
      <h3 className="title">Review the account changes</h3>
      <MessageBlock error messages={getErrors(mergeMutation.error)} />
      <ShowIf value={active && archived}>
        <ShowWith value={active}>
          {data => (
            <div className="account-details">
              <h3 className="section-header">Account Details</h3>
              <div className="account-info">
                <p>{data.account_name}</p>
                <p>{data.account_to_crm_id}</p>
              </div>
            </div>
          )}
        </ShowWith>
        <h3 className="section-header">Account Information That Will Merge</h3>
        <ShowWith value={archived}>
          {data => (
            <p>
              The following account information from{' '}
              <span>
                {data.account_name} ({data.account_to_crm_id})
              </span>{' '}
              will be merged and the account will be archived.
            </p>
          )}
        </ShowWith>
        <ul className="merge-details-list">
          {[
            'Authenticators',
            'Entitlements',
            'Child Connections',
            'EULAs',
            'Batches'
          ].map((item, i) => (
            <li key={i}>
              <i className="fa fa-check-circle" />
              {item}
            </li>
          ))}
        </ul>
      </ShowIf>

      <div className="mt">
        <button
          type="button"
          className="btn btn-muted btn-outline"
          onClick={() => history.goBack()}
        >
          Previous
        </button>
        <button type="button" className="btn" onClick={handle.onMerge}>
          Finish Merge
        </button>
      </div>

      <div>
        <h4></h4>
      </div>
    </div>
  );
}
