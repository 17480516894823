import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  PasswordAuthForm,
  GoogleAuthForm,
  LTIAuthForm,
  IPAuthForm,
  OAuthForm
} from '.';

import { AUTH, NON_EXCLUSIVE_ROLES, ROLE } from '../../constants';
import { MessageBlock, Dialog, ShowIf } from '../fragments';
import { authenticators } from '../../redux/services';
import { setForm } from '../../redux/slices/forms';
import { useHistory, useParams } from 'react-router-dom';
import { getErrors, isValidIPRange, toIPAddress } from '../../utils';

const formComponents = {
  [AUTH.google.id]: GoogleAuthForm,
  [AUTH.ip.id]: IPAuthForm,
  [AUTH.lti.id]: LTIAuthForm,
  [AUTH.password.id]: PasswordAuthForm,
  [AUTH.oauth.id]: OAuthForm
};

/**
 * A map of authenticator types to validation functions returning a state update
 * with `errors` and `warnings` properties; if `errors` has items, the update
 * mutation will be aborted. if `warnings` has errors, the mutation will be
 * aborted unless the force flag is set to `true`.
 */

const messages = {
  ip_start_invalid: 'Starting IP address is invalid.',
  ip_end_invalid: 'Ending IP address is invalid.',
  ip_type_mismatch: 'IP address types (v4, v6) are mismatched.',
  ip_warn_range: 'IP range exceeds recommended bounds. Do you wish to proceed?',
  ip_private_overlap: 'The IP Range falls within a private network.',
  required_role: 'You must select at least one role.',
  multi_site_perms:
    'Multi-Site Admin role requires a Content Management or Reporting permission.',
  content_manager:
    'Content Management and Reporting permissions require the Multi-Site Admin role.',
  double_exclusive_roles:
    'More than one top level role is selected. Please choose just one in order to update this authenticator.'
};

const validators = {
  [AUTH.ip.id]: form => {
    const [start, end] = [form.starting_ip, form.ending_ip].map(toIPAddress);
    const isValid = isValidIPRange(form.starting_ip, form.ending_ip);
    const response = { errors: [], warnings: [] };

    if (!start) {
      response.errors.push(messages.ip_start_invalid);
    }

    if (!end) {
      response.errors.push(messages.ip_end_invalid);
    }

    if (start && end) {
      if (start.v4 !== end.v4) {
        response.errors.push(messages.ip_type_mismatch);
      } else if (isValid === null) {
        response.errors.push(messages.ip_private_overlap);
      }
    }

    if (isValid === false) {
      response.warnings.push(messages.ip_warn_range);
    }

    // Validate roles after IP specific validations
    return validators.roles(form, response);
  },
  roles: (form, response = { errors: [], warnings: [] }) => {
    // Check that at least one role is selected
    if (!form.roles.length) {
      response.errors.unshift(messages.required_role);
    }
    // Check that if Multi-Admin role is selected, Content Management or Reporting permissions are also selected
    if (
      form.roles.includes(ROLE.DISTRICT.roleId) &&
      !form.roles.includes(ROLE.CONTENT_MANAGER.roleId) &&
      !form.roles.includes(ROLE.REPORTING.roleId)
    ) {
      response.errors.unshift(messages.multi_site_perms);
    }
    return response;
  }
};

function EditAuthenticatorForm(props) {
  const [params, history] = [useParams(), useHistory()];
  const isCreate = !(params.authenticator_id ?? null);

  const [state, setState] = useState({ errors: [], warnings: [] });
  // authType could be borked and undefined, so we'll create fallbacks to avoid a WSOD
  const Form = formComponents[props.form.auth_type] ?? (() => null);
  const validate = validators[props.form.auth_type] ?? validators.roles;

  // PUT vs. POST doesn't matter in this context
  const [mutate, mutation] = isCreate
    ? authenticators.useCreateAuthenticatorMutation()
    : authenticators.useUpdateAuthenticatorMutation();

  const handle = {
    pageLoadValidation: () => {
      const { roles } = props.form;
      // Returns true if the form roles have Content Management without Multi-Site Admin role
      const dangling =
        roles.includes(ROLE.CONTENT_MANAGER.roleId) &&
        !roles.includes(ROLE.DISTRICT.roleId);
      // Filters out non exclusive roles and returns true if there is more than one exclusive role set
      const multiExclusive =
        roles.filter(role => !NON_EXCLUSIVE_ROLES.includes(role)).length > 1;

      // Return a message if the form is invalid, otherwise return null
      switch (true) {
        case dangling:
          return { disableSubmit: true, message: messages.content_manager };
        case multiExclusive:
          return {
            disableSubmit: true,
            message: messages.double_exclusive_roles
          };
        default:
          return { disableSubmit: false, message: null };
      }
    },
    onPrev: props.onPrev ?? (() => history.goBack()),
    onCancel: () => setState({ warnings: [], errors: [] }),
    /**
     * @todo: I'd like to clean this up. The biggest issue here might be that
     * everything's tied up into the container component. Separate routes
     * might've been the better call...
     */
    onSubmit: (event = null, force = false) => {
      // prevent form submission
      event?.preventDefault();

      // validate the form
      const { errors = [], warnings = [] } = validate(props.form);

      if (errors.length) {
        // unambiguous failure: update errors and bail immediately
        return setState({ errors, warnings: [] });
      }

      // if we have no errors or warnings or force is true, kick off the request
      if (!warnings.length || force) {
        const { password, ...data } = props.form;
        // we can update an authenticator without having to change their password
        mutate(isCreate || password ? { password, ...data } : data);
      }

      // add warnings if we aren't forcing (i.e., confirming) the submission
      return setState(
        force ? { errors: [], warnings: [] } : { errors, warnings }
      );
    }
  };

  useEffect(() => {
    // Set state errors if the Content Management role is selected without the Multi-Site Admin role,
    // Otherwise, clear the errors
    const validation = handle.pageLoadValidation();
    setState(
      validation.disableSubmit
        ? { errors: [validation.message], warnings: [] }
        : { errors: [], warnings: [] }
    );

    if (mutation.isSuccess) {
      // Finish and Review wasn't getting the authenticator ID in the form where it was expecting it
      // for me on dev/dev so this is what worked for me
      const authenticator_id =
        props.form.authenticator_id ??
        mutation.data.authentication?.authenticator_id;
      props.setForm({
        ...props.form,
        authenticator_id,
        isUpdated: mutation.isSuccess
      });
      props.onNext?.();
    }
  }, [mutation.isSuccess, props.form, props.form.roles]);

  return (
    <>
      {/* confirm dubious form input */}
      <Dialog
        title="Warning"
        isOpen={state.warnings.length > 0}
        onConfirm={() => handle.onSubmit(null, true)}
        onClose={() => handle.onCancel()}
      >
        {state.warnings.map((msg, i) => (
          <div key={i}>{msg}</div>
        ))}
      </Dialog>
      {/* errors */}
      <MessageBlock
        error
        messages={getErrors(mutation.error).concat(state.errors)}
      />
      {/* success message */}
      <ShowIf value={mutation.isSuccess}>
        <MessageBlock className="msg-success">
          Authenticator {isCreate ? 'created' : 'updated'} successfully.
        </MessageBlock>
      </ShowIf>
      {/* form */}
      <form id="auth_details" onSubmit={handle.onSubmit}>
        {/* credential type is not editable after creation */}
        <ShowIf value={isCreate}>
          <label>Type</label>
          <div className="user-credentials my">
            {Object.values(AUTH).map(({ id, button }) => (
              <button
                key={id}
                id={id}
                type="button"
                className={
                  id === props.form.auth_type ? 'btn' : 'btn btn-outline'
                }
                onClick={() => props.setForm({ ...props.form, auth_type: id })}
              >
                {button}
              </button>
            ))}
          </div>
        </ShowIf>
        {/* an AuthFrom from '../forms */}
        <Form />
        <div className="mt">
          <button
            className="btn"
            type="submit"
            disabled={
              mutation.isLoading || handle.pageLoadValidation().disableSubmit
            }
          >
            {isCreate ? 'Create' : 'Update'}
          </button>
          <button
            type="button"
            className="btn btn-outline"
            onClick={handle.onPrev}
          >
            Back
          </button>
        </div>
      </form>
    </>
  );
}

EditAuthenticatorForm.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  onPrev: PropTypes.func,
  onNext: PropTypes.func
};

const mapStateToProps = state => ({ form: state.forms.auth });

const mapDispatchToProps = {
  setForm: data => setForm({ data, type: 'auth' })
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAuthenticatorForm);
