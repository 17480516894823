import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { AUTH } from '../../constants';

/**
 * Precisely which fields will be displayed is determined later, but here are
 * the options. Fields with a null `type` are displayed regardless of the status
 * of the form's type field and are displayed first. Fields with a type are
 * displayed conditionally after the type select.
 */
const fields = [
  { id: 'account_name', name: 'Account', type: null },
  { id: 'username', name: 'Username', type: 'password' },
  { id: 'starting_ip', name: 'Starting IP', type: 'ip' },
  { id: 'classroom', name: 'Classroom ID', type: 'google' },
  { id: 'issuer', name: 'Issuer', type: 'oauth' },
  { id: 'application', name: 'Tool ID', type: 'lti' }
  // @todo: pending backend implementation
  // { id: 'ending_ip', name: 'Ending IP', type: 'ip_address' }
];

/**
 * Depending on the context, we may or may not want to display some fields;
 * on the account authenticators page, for example, we don't want to allow
 * the user to pick a different account.
 *
 * This returns a array of inputs. It's lengthy, so it'll only be invoked when
 * the route's account_id or the value of the type field changes.
 */
const getFieldInputs = ({ accountId, value, onChange }) => {
  const inputs = fields
    .filter(
      ({ id, type }) =>
        // filter out non-account fields as needed
        (!accountId || !id.startsWith('account')) &&
        // filter out non-field types as needed
        (!type || type === value.auth_type)
    )
    // note that we're returning an object with a "type" as well as the input, not just the input
    .map(field => ({
      type: field.type,
      input: (
        <div key={field.id} className="form-group">
          <label htmlFor={field.id}>{field.name}</label>
          <input
            id={field.id}
            type="text"
            name={field.id}
            onChange={onChange}
            value={value[field.id] ?? ''}
          />
        </div>
      )
    }));

  // ...and then return our enormous array of inputs
  return [
    // first the type select
    <div className="form-group" key="type">
      <label htmlFor="auth_type">Type</label>
      <select
        id="auth_type"
        name="auth_type"
        value={value.auth_type ?? ''}
        onChange={onChange}
      >
        <option value="">All</option>
        {Object.values(AUTH).map(type => (
          <option key={type.id} value={type.id}>
            {type.name}
          </option>
        ))}
      </select>
    </div>,
    // then the fields that are dependent from the current type
    ...inputs.filter(field => !!field.type).map(field => field.input),
    // and then the fields indepenent on the type
    ...inputs.filter(field => !field.type).map(field => field.input)
  ];
};

function AuthenticatorsFilter({ onChange, onReset, value }) {
  // pull accountId from the route params, if possible
  const { accountId = null } = useParams();
  // return JSX
  return (
    <div className="my">
      <h4>Filters</h4>
      <form className="flex">
        {/* bah humbug, this can't be memoized because of the value */}
        {getFieldInputs({ accountId, onChange, value })}
        <button
          type="button"
          onClick={onReset}
          className="btn btn-outline btn-muted"
        >
          Clear Filter
        </button>
      </form>
    </div>
  );
}

AuthenticatorsFilter.propTypes = {
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  value: PropTypes.object
};

export default AuthenticatorsFilter;
