import {
  AUTH,
  PAGER_STEP as STEP,
  PAGER_THRESHOLD as THRESHOLD
} from './constants';

/**
 * Determine if a date is defined and earlier than the current time. Doesn't
 * actually care about the token, but "isTokenExpired" is probably more helpful
 * inline.
 */
export function isTokenExpired(date) {
  return date && new Date(date) < new Date();
}

/**
 * Clamp a given value between `min` and `max` (inclusive).
 */
export function clamp(value, min, max) {
  return Math.max(min, Math.min(max, value));
}

/**
 * Returns an ascending array of numbers from `min` to `max` (inclusive).
 * @example
 * range(1, 3); // [ 1, 2, 3 ]
 * range(-1, 1); // [ -1, 0, 1 ]
 */
export function range(min, max) {
  const res = [];
  for (let i = min; i <= max; i += 1) {
    res.push(i);
  }
  return res;
}

/**
 * Populates the paginator drop down.
 */
export function populate(page, maxPage, total) {
  const res = [];

  const min = Math.max(page - STEP, 1);
  const max = Math.min(page === 1 ? page + STEP - 1 : page + STEP, maxPage - 1);

  // Always add the first page
  res.push(1);

  // previous steps
  if (total < THRESHOLD) {
    for (let i = STEP; i <= min; i += STEP) {
      res.push(i);
    }
  }

  //  current steps - incrementing by 1
  for (let i = min + 1; i <= max; i += 1) {
    res.push(i);
  }

  // next steps
  if (total < THRESHOLD) {
    // Since we are using the page as a foundation, we need to subtract the ones place
    // number from page calculation to correctly increment by STEP
    // Example (page = 37, last increment is 47)
    // (page + 2 * STEP) ===  57 - (page % 10) === 50

    for (let i = page + 2 * STEP - (page % 10); i < maxPage; i += STEP) {
      res.push(i);
    }
  }

  // Always add the last page
  if (maxPage > 1) {
    res.push(maxPage);
  }

  return res;
}

export function toDateString(value, includeTime = false) {
  // create a date object
  const date = new Date(value);

  // if it's invalid return '-', otherwise the locale-formatted date
  if (!value || isNaN(date.valueOf())) {
    return '-';
  }

  // Reverse out timezone offset so the dates don't shift on display
  const timeDiff = date.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(date.valueOf() + timeDiff);

  return includeTime
    ? adjustedDate.toLocaleString()
    : adjustedDate.toLocaleDateString();
}

/**
 * Given an authentication record, return the key of the authenticator in the AUTH dictionary.
 */
export function getAuthType(data) {
  switch (true) {
    case !!data.GoogleClassroomAuthentication:
      return AUTH.google.id;
    case !!data.IpAuthentication:
      return AUTH.ip.id;
    case !!data.LtiAuthentication:
      return AUTH.lti.id;
    case !!data.OAuthAuthentication:
      return AUTH.oauth.id;
    case !!data.PasswordAuthentication:
      return AUTH.password.id;
    default:
      return null;
  }
}

/**
 * Given data from the DB, return data in the columns used within
 * the application and expected by the backend for posts/puts
 */
export function getAuthForm(data) {
  const { authentication: auth, Roles: roles = [] } = data;
  return {
    /**
     * since the backend expects auth_id in the PUT payload, it'd be nice if it
     * returned auth_id in the GET
     */
    auth_type: data.authenticator_type,
    auth_id:
      auth.password_id ??
      auth.ip_id ??
      auth.lti_id ??
      auth.google_classroom_auth_id ??
      auth.oauth_authentication_id,
    authenticator_id: data.authenticator_id,
    blocked: data.blocked,
    // password
    username: auth.username,
    // lti
    application: auth.lti_application_id,
    // oauth
    issuer: auth.issuer,
    external_id: auth.external_id,
    // google classroom
    classroom: auth.classroom_id,
    // ip address
    starting_ip: auth.starting_ip,
    ending_ip: auth.ending_ip,
    // misc
    roles: roles.map(role => role.role_id) ?? [],
    password: null
  };
}

/**
 * Given an authenticator record, return a human-readable name.
 */
export function getAuthName(authenticator) {
  const {
    PasswordAuthentication: password,
    LtiAuthentication: lti,
    OAuthAuthentication: oauth,
    GoogleClassroomAuthentication: classroom,
    IpAuthentication: ip
  } = authenticator;

  if (password) {
    return password.username;
  } else if (lti) {
    return lti.lti_application_id;
  } else if (oauth) {
    return `${oauth.issuer} - ${oauth.external_id}`;
  } else if (classroom) {
    return classroom.classroom_id;
  } else if (ip) {
    return `${ip.starting_ip} - ${ip.ending_ip}`;
  }
  return `Unnamed (${authenticator.authenticator_id})`;
}

/**
 * Returns the value within a nested object based on values within an array
 * @param {object} object - The object to burrow
 * @param {array} array - An array of the object paths to reach desired value  ie ['Account', 'account_name']
 * @returns string
 */
function getNestedValue(object, array) {
  return array.reduce(
    (obj, key) => (obj && obj[key] ? obj[key] : null),
    object
  );
}

/**
 * A custom csv transformer that removes elements from an object based on an array
 * @param {*} exclude
 * @returns object
 */
export function removeColumn(exclude) {
  return item => {
    if (exclude.length) {
      for (const column of exclude) {
        delete item[column];
      }
    }
    return item;
  };
}
/**
 * Construct a filename by extracting nested values based on an array
 * @param {object} object
 * @param {array | string} array ['Account.account_id', 'EntitlementBatch.title']
 * @returns string
 */
export function constructFilename(object, filePath) {
  let filename = '';
  let pathArray = [];
  switch (true) {
    case typeof filePath === 'string':
      pathArray = filePath.split('.');
      return getNestedValue(object, pathArray);
    case Array.isArray(filePath):
      for (const item of filePath) {
        pathArray = item.split('.');
        filename += `${getNestedValue(object, pathArray)}_`;
      }
      return filename.slice(0, -1);
    default:
      return 'ParsedJSONfile';
  }
}

export function downloadBlob(filename, blob) {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * Trim off whitespace from a string,
 * returns null if all whitespaces
 * @param {string | null} value
 * @returns null or string
 */
export function trim(value) {
  switch (true) {
    case value === null:
      return value;
    case value.trim().length === 0:
      return null;
    default:
      return value.trim();
  }
}
export * from './utils/generate-password';
export * from './utils/validate';
export * from './utils/rtk-query';
