import React from 'react';

import SelectRolesForm from './SelectRolesForm';
import { AuthBlockedForm, AuthTypeForm } from '../fragments';

const fields = [
  { id: 'starting_ip', name: 'Starting IP' },
  { id: 'ending_ip', name: 'Ending IP' }
];

export default function IPAuthForm() {
  return (
    <div id="ip-auth-form" className="user-details">
      <AuthTypeForm fields={fields}>
        <AuthBlockedForm />
        <SelectRolesForm />
      </AuthTypeForm>
    </div>
  );
}
