import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getEntitlementErrors } from '../../utils';
import { LICENSE } from '../../constants';

export const DEFAULT_VALUE = {
  perpetual: null,
  start_date: null,
  end_date: null
};

export default function EntitlementForm({ children: Form, ...props }) {
  // Prefill if values are available
  const [value, setValue] = useState({
    ...DEFAULT_VALUE,
    ...props.prefill
  });

  const errors = getEntitlementErrors(value, {
    isPublished: props.alternate,
    isRenewal: props.renewal
  });

  const handle = {
    onChange: ({ target }) => {
      const changed = target.value === '' ? null : target.value;
      const data = { ...value, [target.name]: changed };
      if (target.name === 'perpetual') {
        data.perpetual = changed === null ? null : changed === 'true';
        // if the user has gone perpetual, clear the end date
        if (data.perpetual === LICENSE.PERPETUAL.id) {
          data.end_date = null;
        }
      }
      setValue(data);
    },
    onReset: () => setValue(DEFAULT_VALUE),
    onSubmit: data => {
      setValue(DEFAULT_VALUE);
      props.onSubmit({ ...data, isPublished: props.alternate });
    }
  };

  return (
    <Form
      // these invoke props
      onRemove={props.onRemove}
      onSubmit={handle.onSubmit}
      // these use state
      onChange={handle.onChange}
      onReset={handle.onReset}
      value={value}
      errors={errors}
      valid={errors.length === 0}
    />
  );
}

EntitlementForm.propTypes = {
  value: PropTypes.object,
  children: PropTypes.elementType.isRequired,
  onSubmit: PropTypes.func,
  onRemove: PropTypes.func,
  alternate: PropTypes.bool,
  renewal: PropTypes.bool,
  prefill: PropTypes.object
};
