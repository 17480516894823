import PropTypes from 'prop-types';
import React from 'react';
import AccountsFilter from './AccountsFilter';

function ConnectedFilter(props) {
  return (
    <div className="my">
      <h4>Filters</h4>
      <form className="flex">
        <div className="connections-form-group">
          <div className="my form-group">
            <label htmlFor="relationship">Relationship</label>
            <select
              id="relationship"
              name="relationship"
              onChange={props.onChange}
              value={props.value.relationship ?? ''}
            >
              <option value="">All</option>
              <option value="src">Parent Account</option>
              <option value="dst">Sub Account</option>
            </select>
          </div>
          <AccountsFilter {...props} header={false} clearButton={false} />
          <button
            type="button"
            onClick={props.onReset}
            className="btn btn-outline btn-muted"
          >
            Clear Filter
          </button>
        </div>
      </form>
    </div>
  );
}

ConnectedFilter.propTypes = {
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  value: PropTypes.object,
  children: PropTypes.node
};

export default ConnectedFilter;
