/**
 * Return an array of errors suitable for passing into the MessageBlock component. The errors we encounter throughout the application are wildly inconsistent in structure, so we need to do a lot of checking to make sure we're not doing null accesses by accident.
 */
export function getErrors(response) {
  const errors = [];

  // response could be undefined
  if (response) {
    // RTK, native JS errors
    const value = response.message ?? response.error;
    if (typeof value === 'string') {
      errors.push(value);
    }

    // various types of server errors
    if (response.data) {
      // "standard" response
      const msg =
        response.data.body?.message ??
        response.data.body ??
        response.data.message;

      if (msg) {
        errors.push(...(typeof msg === 'string' ? [msg] : Object.values(msg)));
      }

      if (Array.isArray(response.data.body?.errors)) {
        errors.push(...response.data.body.errors);
      }
    }
  }

  return errors;
}

export * from './validation/entitlements';
export * from './validation/ip-range';
export * from './validation/isbn';
