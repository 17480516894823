import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, Redirect } from 'react-router-dom';

import { paths } from '../../constants';
import { batches, entitlements } from '../../redux/services';
import { getErrors } from '../../utils';
import { EntitlementsTable } from '../tables';
import { MessageBlock, Search } from '../fragments';

function FinishEntitlements({ batchId: batch_id, ...props }) {
  const [finalize, mutation] = batches.useFinalizeBatchMutation();

  if (mutation.isSuccess) {
    return <Redirect to={generatePath(paths.batches.show, { batch_id })} />;
  }

  return (
    <div id="finish_entitlement">
      <MessageBlock error messages={getErrors(mutation.error)} />
      <Search
        params={{ batch_id }}
        table={EntitlementsTable}
        query={entitlements.useGetEntitlementsQuery}
      />
      <div className="mt">
        <button
          className="btn"
          onClick={() => finalize(batch_id)}
          disabled={mutation.isLoading}
        >
          Finish
        </button>
        <button
          className="btn btn-outline"
          onClick={props.onPrev}
          disabled={mutation.isLoading}
        >
          Back
        </button>
      </div>
    </div>
  );
}

FinishEntitlements.propTypes = {
  batchId: PropTypes.number,
  accountId: PropTypes.number,
  onPrev: PropTypes.func
};

export default FinishEntitlements;
