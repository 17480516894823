import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetAccountQuery } from '../../redux/services/accounts';

import { EditAccountForm } from '../forms';
import { PageHeader, ShowWith } from '../fragments';

/**
 * EditAccount
 * Returns a form for editing an existing account or creating a new one.
 */
export default function EditAccount() {
  const { accountId = null } = useParams();
  // If the account exists, pass it to the form. Otherwise, just pass empty data.
  const query = accountId ? useGetAccountQuery(accountId) : { data: {} };
  const title = accountId ? 'Edit Account' : 'Add Account';

  return (
    <ShowWith value={query.data}>
      {data => (
        <div id="add_account">
          {/* PageHeader gets us consistent styling for free */}
          <PageHeader title={title} />
          <EditAccountForm account={data} />
        </div>
      )}
    </ShowWith>
  );
}
