import { createSlice } from '@reduxjs/toolkit';

const getInitialState = () => {
  return {
    fetches: 0,
    isLoading: false
  };
};

/**
 * A slice for global UI state that shouldn't be handled within specific components (toasts, fetch progress, etc.)
 */
const slice = createSlice({
  name: 'ui',
  initialState: getInitialState(),
  reducers: {
    setIsLoading: (state, action) => {
      const count =
        action.payload === true
          ? state.fetches + 1
          : Math.max(0, state.fetches - 1);

      state.fetches = count;
      state.isLoading = count > 0;
    }
  }
});

export default slice.reducer;

export const { setIsLoading } = slice.actions;
