import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';

import { EntitlementsTable } from '../tables';
import { EntitlementsFilter } from '../filters';
import { Search, MessageBlock } from '../fragments';

import { paths } from '../../constants';
import { entitlements } from '../../redux/services';
import ExportCSV from '../fragments/ExportCSV';

export default function AccountEntitlements({ accountName, accountToCrm }) {
  const { accountId } = useParams();
  const [status, setStatus] = useState('');

  return (
    <Search
      params={{ account_id: accountId, active: true }}
      query={entitlements.useGetEntitlementsQuery}
      filter={filterProps => (
        <>
          <EntitlementsFilter {...filterProps} />
          <MessageBlock className="msg mb msg-error msg-outline mt">
            {status.text}
          </MessageBlock>
          <div className="py">
            {/* instead of POSTing, redirect to the "create batch" page with the account pre-selected */}
            <Link
              className="btn"
              to={{
                pathname: paths.batches.create,
                state: {
                  accountId,
                  accountName,
                  accountToCrm
                }
              }}
            >
              Add Entitlements
            </Link>
            <ExportCSV
              params={filterProps.value}
              lazyQuery={entitlements.useLazyGetEntitlementsQuery}
              query={entitlements.useGetEntitlementsQuery}
              filenamePath={['Account.account_name', 'Account.account_id']}
              label="Entitlements"
              style="btn btn-outline"
              setStatus={setStatus}
            />
          </div>
        </>
      )}
      table={EntitlementsTable}
    />
  );
}
AccountEntitlements.propTypes = {
  accountName: PropTypes.string,
  accountToCrm: PropTypes.string
};
