import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';

/**
 * A basic component for showing and hiding content in a React portal. Pretty
 * bare-bones—no animation configuration, etc.
 *
 * @todo: get esc working
 *
 * @example
 * ```jsx
 * <Overlay isOpen>
 *   <h1>Hello, world!</h1>
 * </Overlay>
 * ```
 */
export default function Overlay(props) {
  /**
   * on mount, add the class to prevent x/y overflow in the document body.
   * on unmount, remove it to restore normal behavior.
   */
  useEffect(() => {
    document.body.classList.toggle('has-overlay', props.isOpen);
    return () => document.body.classList.remove('has-overlay');
  });

  return createPortal(
    <div className="content-overlay">
      <Transition
        // without this prop, the overlay can interact strangely with the router
        appear
        show={props.isOpen ?? false}
        enter="slide"
        leave="slide"
        entered="slide in"
        enterTo="in"
        leaveTo="out"
      >
        <button
          className="btn btn-icon btn-muted"
          onClick={props.onClose}
          title="Close"
        >
          <i className="fas fa-times"></i>
        </button>
        <>{props.children}</>
      </Transition>
      <div className="bg" onClick={props.onClose} />
    </div>,
    document.body
  );
}

Overlay.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};
