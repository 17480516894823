import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setForm } from '../../redux/slices/forms';

function AuthBlockedForm(props) {
  return (
    <div className="form-group checkbox">
      <label htmlFor="input-blocked">
        Blocked
        <input
          type="checkbox"
          id="input-blocked"
          name="blocked"
          onChange={e =>
            props.setForm({ ...props.form, blocked: e.target.checked })
          }
          checked={props.form.blocked ?? false}
        ></input>
      </label>
    </div>
  );
}

AuthBlockedForm.propTypes = {
  setForm: PropTypes.func,
  form: PropTypes.object
};

const mapStateToProps = state => ({ form: state.forms.auth });
const mapDispatchToProps = {
  setForm: data => setForm({ data, type: 'auth' })
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthBlockedForm);
