import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { isTokenExpired } from '../utils';
import { setIsLoading } from '../redux/slices/ui';

/**
 * Returns a base config for RTK Query API slices.
 */
export function getBaseQuery() {
  const base = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    // if available, automatically set the Authorization header
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().login;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  });

  return async (args, api, extra) => {
    // @hack: If the query has parameters but perPage is not set, return an empty response instead of sending request

    if (
      args.requiresPage &&
      args.params &&
      !args.params.page &&
      !args.params.batch_id
    ) {
      return {
        data: {
          body: {
            [args.key]: []
          }
        },
        meta: {
          response: {
            headers: new Map()
          }
        }
      };
    }

    // @todo: temporary patch for an import ordering issue
    const { logOut } = await import('../redux/slices/login');
    const { expires } = api.getState().login;

    api.dispatch(setIsLoading(true));
    const result = await base(args, api, extra);
    api.dispatch(setIsLoading(false));

    // user's token has expired or they're unauthenticated
    if (isTokenExpired(expires) || result.error?.status === 401) {
      // @todo: does an expired token return 401?
      api.dispatch(logOut());
    }
    // return RTK Query's default response
    return result;
  };
}

/**
 * Returns a response transformer that returns a structured response with result count pulled from the response headers (or response body).
 */
export function getTransformResponse(key, id) {
  return (res, { response }) => {
    const count = +(response.headers.get('x-total-count') ?? NaN);
    return {
      count: isNaN(count) ? res.body[key].length : count,
      results: res.body[key].map(item =>
        id ? { ...item, id: item[id] } : item
      )
    };
  };
}

/**
 * Returns a function that can be passed to `providesTags` or `invalidatesTags`
 * to inform the cache of additions and removals to a list of records.
 * https://redux-toolkit.js.org/rtk-query/usage/automated-refetching
 */
export function getListTagger(type) {
  return res => {
    const tags = [{ type, id: 'index' }];
    if (res?.results) {
      // if multiple results are returned, update the list
      tags.push(...res.results.map(item => ({ type, id: item.id })));
    } else if (res?.id) {
      // if one result is returned, update the list and that one item
      tags.push({ type, id: res.id });
    }
    return tags;
  };
}

/**
 * Returns a function that can be passed to `providesTags` or `invalidatesTags`
 * to inform the cache of a _single_ added/updated/deleted record.
 * https://redux-toolkit.js.org/rtk-query/usage/automated-refetching
 */
export function getItemTagger(type) {
  // if the item doesn't have an "id" property, it'll fall back to returning
  // the param (presumably the same id).
  return (res, error, param) => (res ? [{ type, id: res.id ?? param }] : []);
}

/**
 * Given a key-value pairing of search params, filter out empty strings.
 */
export function filterQuery(params) {
  return Object.entries(params).reduce(
    (prev, [key, value]) => (value === '' ? prev : { ...prev, [key]: value }),
    {}
  );
}
