import addConnectionsOverlay from './addConnectionsOverlay.png';
import viewConnectionDetail from './viewConnectionDetail.png';
import directSubAccount from './directSubAccount.jpg';
import indirectSubAccount from './indirectSubAccount.jpg';
import indirectParent from './indirectParent.jpg';
import directParent from './directParent.jpg';
import connectAccountBtn from './connectAccountBtn.png';
import relationshipDropdown from './relationshipDropdown.png';
import addConnectionsBtn from './addConnectionsBtn.jpg';
import addAnotherAccount from './addAnotherAccount.jpg';

export default {
  viewConnectionDetail,
  addConnectionsBtn,
  directSubAccount,
  indirectSubAccount,
  directParent,
  indirectParent,
  addConnectionsOverlay,
  connectAccountBtn,
  addAnotherAccount,
  relationshipDropdown
};
