import React, { useState } from 'react';

import { generatePath, Redirect, useLocation, useParams } from 'react-router';
import { paths } from '../../constants';
import { ShowIf, PageHeader, ShowWith } from '../fragments';
import { batches } from '../../redux/services';

import { EditBatchContent, FinishBatch } from './';

function EditBatch() {
  const { batch_id } = useParams();
  const { state: locationState = {} } = useLocation();
  const batch = batches.useGetBatchQuery({ batch_id });

  // are we editing a newly-created batch?
  const isCreate = locationState.isCreate ?? false;
  // when we're done, we're always sent to the batch's 'show' page
  const next = generatePath(paths.batches.show, { batch_id });
  // if we're creating, "back" sends us to create instead of show
  const back = isCreate ? paths.batches.create : next;

  const [{ redirect, step }, setState] = useState({ redirect: null, step: 0 });

  const handle = {
    onNext: () =>
      // if we're on the last step, we're leaving—otherwise, next step
      setState({ step: step + 1, redirect: step ? next : null }),
    onPrev: () =>
      // if we're on the first step, we're leaving, otherwise, prev step
      setState({ step: step - 1, redirect: step ? null : back })
  };

  // redirect if it's already been executed
  if (redirect !== null || batch.data?.executed) {
    return <Redirect to={{ pathname: redirect, state: locationState }} />;
  }

  // return the appropriate class per breadcrumb status
  const getClass = id => (step === id ? 'breadcrumb active' : 'breadcrumb');
  const Step = [EditBatchContent, FinishBatch][step];

  return (
    <div id="add_entitlements">
      <PageHeader title={isCreate ? 'Create Batch' : 'Edit Batch'} />
      <ShowWith value={batch.data}>
        {data => (
          <div className="batch-info">
            <h2 title="Batch Title" id="batch_title">
              {data.title ?? <span className="text-muted">Untitled</span>}
            </h2>
            <p title="External ID" id="entitlement_batch_to_crm_id">
              {data.entitlement_batch_to_crm_id ?? (
                <span className="text-muted">No External ID</span>
              )}
            </p>
            <p
              title="Purchasing Account"
              id="purchasing_account"
              className="text-muted"
            >
              {batch.data.Account.account_name}
            </p>
          </div>
        )}
      </ShowWith>
      <ol className="breadcrumbs">
        <ShowIf value={isCreate}>
          {/* only show this if this is a newly-created batch */}
          <li className="breadcrumb disabled">Select Account</li>
        </ShowIf>
        {/* but these are always relevant */}
        <li className={getClass(0)}>Add Entitlements</li>
        <li className={getClass(1)}>Review & Finish</li>
      </ol>
      <Step {...handle} batchId={+batch_id} />
    </div>
  );
}

export default EditBatch;
