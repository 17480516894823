import PropTypes from 'prop-types';
import React from 'react';

/**
 * Displays page title, along with arbitrary children in the top right corner.
 * These typically take the form of `<NavLink />`s. Optionally, a "tag" prop
 * can be passed with the name of the HTML element to be used for the header
 * (by default, `h1`).
 * @example
 * ```jsx
 * <PageHeader tag="h1" title="My Page Title">
 *   <NavLink className="btn btn-outline" to="/">Do Thing</NavLink>
 *   <NavLink className="btn" to="/">Do Other Thing</NavLink>
 * </PageHeader>
 * ```
 */
export default function PageHeader(props) {
  return (
    <header className="page-header">
      <div>{React.createElement(props.tag ?? 'h1', {}, props.title)}</div>
      <div>{props.children}</div>
    </header>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string,
  tag: PropTypes.string,
  children: PropTypes.node
};
