import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { Search } from '../fragments';
import { AuthenticatorsTable } from '../tables';
import { AuthenticatorsFilter } from '../filters';
import { authenticators } from '../../redux/services';
import { paths } from '../../constants';

function Authenticators() {
  const account_id = +useParams().accountId;
  return (
    <div className="authenticators">
      <Search
        params={{ account_id }}
        query={authenticators.useGetAuthenticatorsQuery}
        table={AuthenticatorsTable}
        filter={filterProps => (
          <>
            <AuthenticatorsFilter {...filterProps} />
            <div className="py">
              <Link
                className="btn"
                to={{
                  pathname: paths.authenticators.create,
                  // different from the search params
                  state: { account_id }
                }}
              >
                Add Authenticator
              </Link>
            </div>
          </>
        )}
      />
    </div>
  );
}

export default Authenticators;
