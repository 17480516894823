import React, { useState } from 'react';
import { Link, Redirect, generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  useCreateAccountMutation,
  useUpdateAccountMutation
} from '../../redux/services/accounts';

import { paths, SOURCE } from '../../constants';
import { MessageBlock, ShowIf } from '../fragments';
import { connect } from 'react-redux';
import { resetForm } from '../../redux/slices/forms';
import { getErrors } from '../../utils';

function AddAccountForm({ account, ...props }) {
  const isNewAccount = !account.account_id;

  // this will kick off our PUT/POST and give us information about the request as it comes in
  const [mutateAccount, mutation] = isNewAccount
    ? useCreateAccountMutation()
    : useUpdateAccountMutation();

  // prefill our form data, including the ID so we don't have to pass it to mutateAccount() by hand
  const [formData, setFormData] = useState({
    accountId: account.account_id ?? null,
    account_name: account.account_name ?? '',
    crm_source: account.crm_source ?? '',
    crm_id: account.account_to_crm_id ?? '',
    salesforce_id: account.salesforce_id ?? '',
    ax_number: account.ax_number ?? '',
    country: account.country ?? '',
    city: account.city ?? '',
    state: account.state ?? '',
    zip_code: account.zip_code ?? '',
    clever_id: account.clever_id ?? '',
    nces_id: account.nces_id ?? '',
    mdr_id: account.mdr_id ?? '',
    classlink_id: account.classlink_id ?? ''
  });

  // This field is used alot so it's desturctured for a cleaner interface
  const { crm_source } = formData;

  // Checks for cases when the crm_source is salesforce but the salesforce_id is empty
  // This helps facilitate special case visibility  of the External ID field and hiding of the 'External ID' label
  const sfSpecialCase = () => {
    return (
      !isNewAccount &&
      crm_source === SOURCE.salesforce.toLowerCase() &&
      !account.salesforce_id
    );
  };

  const generateClassName = field => {
    let prefix = 'crm-form-group';

    // Do not bold ax and sf input  fields
    if (sfSpecialCase()) {
      return prefix;
    }
    return crm_source === field.toLowerCase() ? (prefix += ' bold') : prefix;
  };

  const displayLabel = field => {
    let prefix = 'help-text ml';

    // Hide the 'External ID' label
    if (sfSpecialCase()) {
      return (prefix += ' hidden');
    }
    return crm_source !== field.toLowerCase() ? (prefix += ' hidden') : prefix;
  };

  const showExternalIdField = () => {
    return (
      (crm_source !== SOURCE.salesforce.toLowerCase() &&
        crm_source !== SOURCE.ax.toLowerCase() &&
        crm_source !== '') ||
      sfSpecialCase()
    );
  };

  // Select the appropriate default option
  const generateOptionTags = () => {
    // add default option
    const options = [
      <option key="default" value="" disabled>
        - Select Source -
      </option>
    ];
    // add option tags based on enum
    for (const [key, value] of Object.entries(SOURCE)) {
      options.push(
        <option key={key} value={key}>
          {value}
        </option>
      );
    }
    return options;
  };
  const handle = {
    onSubmit: e =>
      // If our accountId is defined in the params, we'll pass it along. If not
      // it'll be null and the mutator can conclude we're attempting a POST
      e.preventDefault() ?? mutateAccount(formData),
    // we can write a generic change handler using the `name` prop
    onChange: e => setFormData({ ...formData, [e.target.name]: e.target.value })
  };
  // if our request has finished successfully...
  if (mutation.isSuccess) {
    props.resetForm();
    // ...redirect to the new or updated account page
    return (
      <Redirect
        to={generatePath(paths.accounts.show, {
          accountId: mutation.data?.account_id
        })}
      />
    );
  }

  return (
    <form id="add_account_form" onSubmit={handle.onSubmit}>
      <MessageBlock error className="my" messages={getErrors(mutation.error)} />
      <div className="account-details">
        <div className="form-group">
          <label htmlFor="account-name">Account Name</label>
          <input
            id="account-name"
            name="account_name"
            type="text"
            onChange={handle.onChange}
            value={formData.account_name}
            required
          />
        </div>
        <div className="flex">
          <div className="form-group mt">
            <label htmlFor="crm_source">CRM Source</label>
            <select
              name="crm_source"
              id="crm_source"
              onChange={handle.onChange}
              required
              value={crm_source}
            >
              {generateOptionTags()}
            </select>
          </div>
        </div>
        <ShowIf value={showExternalIdField()}>
          <div className="flex">
            <div className="form-group mt">
              <label htmlFor="crm_id">External ID</label>
              <div className="crm-form-group bold">
                <input
                  name="crm_id"
                  id="crm_id"
                  type="text"
                  onChange={handle.onChange}
                  value={formData.crm_id}
                  required
                />
              </div>
            </div>
          </div>
        </ShowIf>
        <div className="flex">
          <div className="form-group mt">
            <label htmlFor="salesforce_id">Salesforce Number</label>
            <div className={generateClassName(SOURCE.salesforce)}>
              <input
                name="salesforce_id"
                id="salesforce_id"
                type="text"
                onChange={handle.onChange}
                value={formData.salesforce_id}
                required={crm_source === SOURCE.salesforce.toLowerCase()}
              />
              <p className={displayLabel(SOURCE.salesforce)}>External ID</p>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="form-group mt">
            <label htmlFor="ax_number">AX Number</label>
            <div className={generateClassName(SOURCE.ax)}>
              <input
                name="ax_number"
                id="ax_number"
                type="text"
                onChange={handle.onChange}
                value={formData.ax_number}
                required={crm_source === SOURCE.ax.toLowerCase()}
              />
              <p className={displayLabel(SOURCE.ax)}>External ID</p>
            </div>
          </div>
        </div>
        <div className="form-group mt">
          <label htmlFor="clever_id">Clever ID</label>
          <div className="crm-form-group">
            <input
              name="clever_id"
              id="clever_id"
              type="text"
              onChange={handle.onChange}
              value={formData.clever_id}
            />
          </div>
        </div>
        <div className="form-group mt">
          <label htmlFor="nces_id">NCES ID</label>
          <div className="crm-form-group">
            <input
              name="nces_id"
              id="nces_id"
              type="text"
              onChange={handle.onChange}
              value={formData.nces_id}
            />
          </div>
        </div>
        <div className="form-group mt">
          <label htmlFor="mdr_id">MDR ID</label>
          <div className="crm-form-group">
            <input
              name="mdr_id"
              id="mdr_id"
              type="text"
              onChange={handle.onChange}
              value={formData.mdr_id}
            />
          </div>
        </div>
        <div className="form-group mt">
          <label htmlFor="classlink_id">ClassLink ID</label>
          <div className="crm-form-group">
            <input
              name="classlink_id"
              id="classlink_id"
              type="text"
              onChange={handle.onChange}
              value={formData.classlink_id}
            />
          </div>
        </div>
      </div>

      {/* Address Data */}
      <div className="account-details address-data">
        <div className="form-group">
          <label htmlFor="country">Country</label>
          <input
            id="country"
            name="country"
            type="text"
            onChange={handle.onChange}
            value={formData.country}
          />
        </div>
        <div className="flex">
          <div className="form-group mt">
            <label htmlFor="city">City</label>
            <input
              name="city"
              id="city"
              onChange={handle.onChange}
              value={formData.city}
            />
          </div>
        </div>
        <div className="flex">
          <div className="form-group mt">
            <label htmlFor="state">State</label>
            <input
              name="state"
              id="state"
              type="text"
              onChange={handle.onChange}
              value={formData.state}
            />
          </div>
        </div>
        <div className="flex">
          <div className="form-group mt">
            <label htmlFor="zipcode">Zip Code</label>
            <input
              name="zip_code"
              id="zipcode"
              type="text"
              onChange={handle.onChange}
              value={formData.zip_code}
            />
          </div>
        </div>
      </div>
      <div className="actions">
        {/* we can use the mutation's "isLoading" property to disable the button */}
        <button className="btn" type="submit" disabled={mutation.isLoading}>
          Save Account
        </button>
        <Link
          className="btn btn-muted btn-outline"
          to={generatePath(
            isNewAccount ? paths.accounts.index : paths.accounts.show,
            { accountId: account.account_id }
          )}
        >
          Cancel
        </Link>
      </div>
    </form>
  );
}

AddAccountForm.propTypes = {
  account: PropTypes.object,
  resetForm: PropTypes.func
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  resetForm: data => resetForm({ data, type: 'auth' })
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAccountForm);
