import { createApi } from '@reduxjs/toolkit/query/react';

import {
  getBaseQuery,
  getItemTagger,
  getListTagger,
  getTransformResponse
} from '../../utils';

const tagItem = getItemTagger('Batch');
const tagList = getListTagger('Batch');

const api = createApi({
  reducerPath: 'batchesAPI',
  baseQuery: getBaseQuery(),
  tagTypes: ['Batch'],
  endpoints: builder => ({
    getBatches: builder.query({
      query: params => ({
        url: '/batches',
        params,
        requiresPage: true,
        key: 'batches'
      }),
      providesTags: tagList,
      transformResponse: getTransformResponse('batches', 'entitlement_batch_id')
    }),
    getBatch: builder.query({
      query: ({ batch_id }) => `/batches/${batch_id}`,
      providesTags: tagItem,
      transformResponse: ({ body: { active_batch: batch } }) => ({
        ...batch,
        account_id: batch.Account?.account_id,
        id: batch.entitlement_batch_id
      })
    }),
    createBatch: builder.mutation({
      query: ({ ...body }) => ({ url: '/batches', method: 'POST', body }),
      invalidatesTags: tagItem,
      transformResponse: response => ({
        id: response.body.entitlement_batch_id
      })
    }),
    renewBatch: builder.mutation({
      query: ({ batch_id, ...body }) => ({
        url: `/batches/${batch_id}/renew`,
        method: 'POST',
        body
      }),
      transformResponse: response => ({
        id: response.body.entitlement_batch_id
      })
    }),
    finalizeBatch: builder.mutation({
      query: batch_id => ({
        url: `/batches/${batch_id}/finalize`,
        method: 'POST'
      }),
      transformResponse: response => response.body,
      invalidatesTags: tagItem,
      // we need to invalidate entitlements, now that new ones are visible
      onQueryStarted: (_, { dispatch }) =>
        dispatch(
          api.util.invalidateTags([{ type: 'Entitlements', id: 'index' }])
        )
    }),
    deleteBatch: builder.mutation({
      query: batch_id => ({
        url: `/batches/${batch_id}`,
        method: 'DELETE'
      }),
      invalidatesTags: tagItem
    }),
    updateBatch: builder.mutation({
      query: ({ batch_id, ...body }) => ({
        url: `/batches/${batch_id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Batch'],
      transformResponse: response => ({
        ...response.body,
        id: response.body.entitlement_batch_id
      })
    })
  })
});

export const {
  useGetBatchesQuery,
  useGetBatchQuery,
  useCreateBatchMutation,
  useRenewBatchMutation,
  useFinalizeBatchMutation,
  useDeleteBatchMutation,
  useUpdateBatchMutation
} = api;

export default api;
