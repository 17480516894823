import React from 'react';
import PropTypes from 'prop-types';

import { ShowIf } from '../../fragments';

export default function Table({
  selected = [],
  items = [],
  onSelect,
  onSelectAll,
  ...props
}) {
  return (
    <ShowIf value={items.length}>
      <table className="table table-select">
        <thead>
          <tr>
            <td className="checkbox">
              <input
                type="checkbox"
                onChange={onSelectAll}
                checked={props.allSelected}
              />
            </td>
            <th>Title</th>
            <th>Collection</th>
            <th>Item Number</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.id} onClick={() => onSelect(item.id)}>
              <td className="checkbox">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item.id)}
                  checked={selected.includes(item.id)}
                />
              </td>
              <td>{item.complete_title}</td>
              <td className="entitlement-collections">
                {item.collections?.join(', ')}
              </td>
              <td>{item.isbn13}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ShowIf>
  );
}

Table.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.array,
  allSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func
};
