import React from 'react';
import PropTypes from 'prop-types';

import { InteractiveTable } from '../../fragments';

import Table from './Table';

export default function SelectProductsTable(props) {
  return <InteractiveTable {...props} table={Table} />;
}

SelectProductsTable.propTypes = {
  items: PropTypes.array,
  isLoading: PropTypes.bool
};
