import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { trim } from '../../utils';

export default function EditableText({
  id = null,
  nullable = false,
  ...props
}) {
  const [value, setValue] = useState(props.value);

  const handle = {
    onInput: e => setValue(e.target.value),
    onEnter: e => {
      if (e.key === 'Enter') {
        handle.onBlur(e);
      }
    },
    onBlur: () => {
      const input = trim(value);
      if (!input && !nullable) {
        setValue(props.value);
        props.setStatus({
          text: `${props.name ?? 'This field'} cannot be empty.`,
          style: 'error'
        });
        return;
      }

      if (props.value !== input) {
        props.onChange?.(input);
      }
    }
  };

  return (
    <input
      title={props.title ?? ''}
      id={id}
      type="text"
      onChange={handle.onInput}
      onBlur={handle.onBlur}
      onKeyDown={handle.onEnter}
      disabled={props.isLoading}
      className={`editable ${props.className ?? ''}`}
      placeholder={props.placeholder ?? 'Untitled'}
      value={value ?? ''}
    />
  );
}

EditableText.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  setStatus: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  isLoading: PropTypes.bool,
  nullable: PropTypes.bool
};
