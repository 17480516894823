import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  generatePath,
  NavLink,
  Route,
  Redirect,
  Switch,
  useParams,
  useLocation
} from 'react-router-dom';

import {
  Dialog,
  MessageBlock,
  PageHeader,
  ShowIf,
  ShowWith
} from '../fragments';
import Entitlements from './AccountEntitlements';
import Connections from './AccountConnections';
import Authenticators from './AccountAuthenticators';
import Batches from './AccountBatches';

import { accounts } from '../../redux/services';
import { paths as p } from '../../constants';
import { useUpdateAccountMutation } from '../../redux/services/accounts';
import { getErrors } from '../../utils';

const paths = p.accounts;

export default function Account() {
  const { accountId } = useParams();
  const { data: account, ...query } = accounts.useGetAccountQuery(accountId);
  const [updateAccount, update] = useUpdateAccountMutation();
  const isEnabled = !account?.archived;
  const { state: locationState } = useLocation();
  const onUpdateStatus = () =>
    updateAccount({ accountId, archived: isEnabled });
  const [successMessage] = useState(locationState);
  const [isOpen, setIsOpen] = useState(false);
  /**
   * We're working with two types of paths here, resolved and unresolved.
   * Resolved paths have interpolated IDs, while unresoled paths do not. We need
   * to pass unresolved paths to NavLink to make "active" work, and we need
   * to pass resolved IDs to `to={}` to actually navigate.
   */
  const to = {
    entitlements: generatePath(paths.entitlements, { accountId }),
    connections: generatePath(paths.connections, { accountId }),
    authenticators: generatePath(paths.authenticators, { accountId }),
    batches: generatePath(paths.batches, { accountId }),
    edit: generatePath(paths.edit, { accountId }),
    merge: generatePath(p.merge.select, { accountId })
  };

  const handle = {
    onSubmit: () => {
      onUpdateStatus();
      setIsOpen(false);
    },
    onCancel: () => {
      setIsOpen(false);
    },
    onClick: () => {
      if (isEnabled) {
        setIsOpen(true);
      } else {
        onUpdateStatus();
      }
    }
  };

  return (
    <div className={isEnabled ? 'account' : 'account disabled'}>
      <Dialog
        title="Warning"
        isOpen={isOpen}
        onConfirm={() => handle.onSubmit()}
        onClose={() => handle.onCancel()}
      >
        <p>
          The entitlements and authenticators for this account will be
          deactivated.
        </p>
      </Dialog>
      <PageHeader title="Account Detail">
        <ShowWith value={account}>
          {data => (
            <ShowIf value={!data.archived}>
              <NavLink
                className="btn btn-lg btn-outline btn-muted"
                to={to.merge}
              >
                Merge Account
              </NavLink>
            </ShowIf>
          )}
        </ShowWith>

        <button
          disabled={query.isLoading || update.isLoading}
          className="btn btn-lg btn-outline btn-muted"
          onClick={handle.onClick}
        >
          {isEnabled ? 'Archive Account' : 'Restore Account'}
        </button>
        <NavLink className="btn btn-lg" to={to.edit}>
          Edit Account
        </NavLink>
      </PageHeader>
      <MessageBlock error messages={getErrors(update.error ?? query.error)} />

      <ShowWith value={account}>
        {data => (
          <div className="account-info">
            <h2 id="account_name">
              {data.account_name}
              <ShowIf value={data.archived}>
                <small className="text-muted text-uppercase"> (Archived)</small>
              </ShowIf>
            </h2>
            <p id="account_crm_id">{data.account_to_crm_id}</p>
          </div>
        )}
      </ShowWith>
      <ShowWith value={successMessage}>
        {data => (
          <MessageBlock className="msg mb msg-success msg-outline merge-status">
            {data.success}
          </MessageBlock>
        )}
      </ShowWith>
      <nav className="tabs">
        <NavLink className="tab" to={to.entitlements}>
          Entitlements
        </NavLink>
        <NavLink className="tab" to={to.authenticators}>
          Authenticators
        </NavLink>
        <NavLink className="tab" to={to.batches}>
          Batches
        </NavLink>
        <NavLink className="tab" to={to.connections}>
          Connections
        </NavLink>
      </nav>
      <Switch>
        <Route
          path={paths.entitlements}
          render={() => (
            <Entitlements
              accountName={account?.account_name ?? null}
              accountToCrm={account?.account_to_crm_id ?? null}
            />
          )}
          exact
        />
        <Route path={paths.authenticators} component={Authenticators} exact />
        <Route path={paths.batches} component={Batches} exact />
        <Route path={paths.connections} component={Connections} exact />
        <Redirect from={paths.show} to={paths.entitlements} />
      </Switch>
    </div>
  );
}

Account.propTypes = {
  fetchError: PropTypes.string
};
