import React, { useState } from 'react';
import { generatePath, useHistory, useLocation, useParams } from 'react-router';
import { paths } from '../../constants';
import {
  Search,
  PageHeader,
  MessageBlock,
  InteractiveTable,
  ShowWith
} from '../fragments';
import { accounts } from '../../redux/services';

import { AccountsFilter } from '../filters';
import { SelectAccountTable } from '../tables';
import { getErrors } from '../../utils';

function SelectMergeAccounts() {
  const history = useHistory();
  const { accountId } = useParams();
  const { state: locationState } = useLocation();
  const { data: account, ...query } = accounts.useGetAccountQuery(accountId);

  const [state, setState] = useState({
    archive_id: locationState?.accountId ? +locationState.accountId : null,
    account_id: +accountId,
    entitlement_batch_to_crm_id: null,
    title: null
  });

  const handle = {
    onSelect: () => {
      history.push(
        generatePath(paths.merge.direction, {
          accountId,
          archiveId: state.archive_id
        })
      );
    },
    onChange: e => setState({ ...state, [e.target.name]: e.target.value })
  };

  return (
    <div className="merge-accounts">
      <PageHeader title="Merge Accounts" />
      <ol className="breadcrumbs">
        <li className="breadcrumb active">Select Accounts</li>
        <li className="breadcrumb disabled">How to Merge</li>
        <li className="breadcrumb disabled">Review & Finish</li>
      </ol>
      <h3 className=" title">Select accounts to merge</h3>

      <div className="account-details">
        <h3 className="section-header">Account 1</h3>
        <ShowWith value={account}>
          {data => (
            <div className="account-info primary">
              <p>{data.account_name}</p>
              <p>{data.account_to_crm_id}</p>
            </div>
          )}
        </ShowWith>
      </div>

      <h3 className="section-header">Account 2</h3>
      <div>
        <MessageBlock error messages={getErrors(query.error)} />

        <Search
          id="accounts"
          query={accounts.useGetAccountsQuery}
          filter={AccountsFilter}
          params={{
            excludeOne: 'true',
            archived: 'false',
            account_id: account?.account_id
          }}
          table={tableProps => (
            <InteractiveTable
              {...tableProps}
              multiple={false}
              table={SelectAccountTable}
              selected={[state.archive_id]}
              onSelect={([archive_id]) => setState({ ...state, archive_id })}
            />
          )}
        />
        <div className="mt">
          <button
            type="button"
            className="btn"
            onClick={handle.onSelect}
            disabled={!state.archive_id}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default SelectMergeAccounts;
