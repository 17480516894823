import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useLoginMutation } from '../../redux/services/login';
import { MessageBlock, ShowIf } from '../fragments';
import { getErrors, isTokenExpired } from '../../utils';
import { paths } from '../../constants';

import svg from '../../logo.svg';

const DEFAULT_LOGIN = {
  username: process.env.REACT_APP_PREFILL_USERNAME,
  password: process.env.REACT_APP_PREFILL_PASSWORD
};

function Login(props) {
  if (props.isLoggedIn) {
    return <Redirect to={paths.accounts.index} />;
  }

  const [{ username, password }, setLogin] = useState(DEFAULT_LOGIN);
  // we have access to an `isLoading` value we can use to disable the button
  const [login, { isLoading, error }] = useLoginMutation();

  return (
    <div id="login">
      <div className="text-center">
        <img alt="PebbleGo by Capstone" src={svg} />
        <h1>Uber Admin</h1>
      </div>
      <form
        className="flex column"
        onSubmit={e => e.preventDefault() ?? login({ username, password })}
      >
        <ShowIf value={props.isTokenExpired}>
          <MessageBlock className="mb">
            You&apos;ve been automatically logged out due to an expired session.
          </MessageBlock>
        </ShowIf>
        <MessageBlock error className="my" messages={getErrors(error)} />
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            id="username"
            onChange={e => setLogin({ password, username: e.target.value })}
            type="text"
            value={username ?? ''}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            onChange={e => setLogin({ password: e.target.value, username })}
            type="password"
            value={password ?? ''}
            required
          />
        </div>

        <button className="btn" type="submit" disabled={isLoading}>
          Login
        </button>
      </form>
    </div>
  );
}

Login.propTypes = {
  login: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  isTokenExpired: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoggedIn: !!state.login.token,
  isTokenExpired: isTokenExpired(state.login.expires)
});

export default connect(mapStateToProps)(Login);
