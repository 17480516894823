import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { paths } from '../../constants';
import { logOut } from '../../redux/slices/login';
import { ShowIf } from './Show';
import { Transition } from '@headlessui/react';

function Menu(props) {
  const [isExpanded, setExpanded] = useState(false);
  const chevron = isExpanded
    ? 'fas fa-angle-double-left'
    : 'fas fa-angle-double-right';

  const handle = {
    onClose: () => setExpanded(false),
    onToggle: () => setExpanded(!isExpanded)
  };

  return (
    // only render menu when not on the login page
    <ShowIf value={props.isLoggedIn}>
      <Transition
        show={isExpanded}
        as={Fragment}
        entered="expanded"
        enterTo="in"
        leaveTo="out"
      >
        <div onClick={handle.onClose} className="menu-overlay-bg" />
      </Transition>

      <nav id="menu" className={isExpanded ? 'expanded' : ''}>
        <ul>
          <li>
            <button
              type="button"
              className="btn btn-icon"
              onClick={handle.onToggle}
            >
              <i className={chevron} />
              <span className="menu-text">Collapse</span>
            </button>
          </li>
          <li>
            <NavLink onClick={handle.onClose} to={paths.accounts.index}>
              <i className="fas fa-folder" />
              <span className="menu-text">Accounts</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={handle.onClose} to={paths.entitlements.index}>
              <i className="fas fa-book" />
              <span className="menu-text">Entitlements</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={handle.onClose} to={paths.batches.index}>
              <i className="fas fa-layer-group" />
              <span className="menu-text">Batches</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={handle.onClose} to={paths.authenticators.index}>
              <i className="fas fa-user-alt" />
              <span className="menu-text">Authenticators</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={handle.onClose} to={paths.manual}>
              <i className="fas fa-question-circle" />
              <span className="menu-text">Manual</span>
            </NavLink>
          </li>
          <li>
            <button
              type="button"
              className="btn btn-icon"
              onClick={() => props.logOut()}
            >
              <i className="fas fa-sign-out-alt" />
              <span className="menu-text">Log Out</span>
            </button>
          </li>
        </ul>
      </nav>
    </ShowIf>
  );
}

Menu.propTypes = {
  isLoggedIn: PropTypes.bool,
  logOut: PropTypes.func
};

const mapStateToProps = state => ({ isLoggedIn: !!state.login.token });
const mapDispatchToProps = { logOut };

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
