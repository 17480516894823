import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { accounts } from '../../redux/services';

import { Search } from '../fragments';
import { AccountsFilter } from '../filters';
import { SelectAccountTable } from '../tables';
import { connect } from 'react-redux';
import { setForm } from '../../redux/slices/forms';

/**
 * A wrapper for the SelectAccount component for use in the add entitlements /
 * authenticators workflow. It uses a `type` prop to update the correct form and
 * expects onNext/onPrev handlers to handle navigation.
 */
function SelectAccountForm(props) {
  const history = useHistory();
  return (
    <div>
      <Search
        id="accounts"
        query={accounts.useGetAccountsQuery}
        filter={AccountsFilter}
        params={props.params}
        table={table => (
          <SelectAccountTable
            {...table}
            selected={props.selected}
            onSelect={props.onChange}
          />
        )}
      />
      <div className="mt">
        <button
          className="btn"
          disabled={!props.selected}
          onClick={() => props.onNext()}
        >
          Next
        </button>
        <button
          className="btn btn-muted btn-outline"
          onClick={() => (props.onPrev ? props.onPrev() : history.goBack())}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

SelectAccountForm.propTypes = {
  selected: PropTypes.number,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  params: PropTypes.object
};

/**
 * A Redux-connected version of the component for use in the auth process.
 */
export const SelectAuthenticatorAccountForm = connect(
  // mapStateToProps
  state => ({ selected: state.forms.auth.accountId }),
  // mapDispatchToProps
  { onChange: accountId => setForm({ type: 'auth', data: { accountId } }) }
)(SelectAccountForm);

export default SelectAccountForm;
