import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setForm } from '../../redux/slices/forms';

/**
 * After writing twelve almost-identical `onChange` handlers, I whipped this up
 * to make the auth forms a little more straightforward. Give it an array of
 * `{ id: string; name: string }` and it'll do all the input making/form
 * changing. You can pass it children and it'll put them below the other inputs.
 * Not intended for use for anything other than text inputs anywhere other than
 * the auth form. If we end up needing something more robust we can talk about
 * that later.
 */
function AuthTypeForm(props) {
  const onChange = e => props.setForm({ [e.target.name]: e.target.value });

  return (
    <>
      {props.fields.map(field => (
        <div key={field.id} className="form-group my">
          <label htmlFor={`input-${field.id}`}>{field.name}</label>
          <input
            id={`input-${field.id}`}
            name={field.id}
            onChange={onChange}
            value={props.form[field.id] ?? ''}
            type="text"
            required
          />
        </div>
      ))}
      {props.children}
    </>
  );
}

AuthTypeForm.propTypes = {
  setForm: PropTypes.func,
  form: PropTypes.object,
  fields: PropTypes.array,
  children: PropTypes.node
};

const mapStateToProps = state => ({ form: state.forms.auth });
const mapDispatchToProps = {
  setForm: data => setForm({ data, type: 'auth' })
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthTypeForm);
