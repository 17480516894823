import accounts from './accounts';
import authenticators from './authenticators';
import batches from './batches';
import connections from './connections';
import products from './products';
import entitlements from './entitlements';
import login from './login';

/**
 * Another core concept with RTK is an API, which is a self-contained collection
 * of endpoints dedicated to interacting with a single piece of functionality—
 * usually a specific type of data.
 *
 * RTK Query automatically generates hooks that can invoked to fetch new items
 * from the server or return cached results as appropriate, while abstracting
 * all the query param/filter/auth stuff involved in manual fetching.
 *
 * An API consists of one or more endpoints, each corresponding to a single
 * server route. Solo results should be returned in the transformResponse
 * function, while multi-item result sets can use getTransformResponse() to
 * extract the `x-total-count` headers, &c, automatically.
 *
 * General pointers:
 *
 * - For normal resources, prefix endpoints with "get" for queries, and "create"
 *   (POST), "delete" (DELETE) and "update" (PUT) for mutations. For ad-hoc
 *   mutations, use the name to indicate what's happening, rather than what HTTP
 *   method is being used.
 * - List query endpoints before mutation endpoints.
 * - In order to get the most value out of tags, make sure you can't use an
 *   existing resource with a query param before adding a nested resource.
 */

export const reducer = {
  [accounts.reducerPath]: accounts.reducer,
  [authenticators.reducerPath]: authenticators.reducer,
  [batches.reducerPath]: batches.reducer,
  [connections.reducerPath]: connections.reducer,
  [entitlements.reducerPath]: entitlements.reducer,
  [login.reducerPath]: login.reducer,
  [products.reducerPath]: products.reducer
};

/**
 * We also need to export middleware to be used by `configureStore()`.
 */
export const middleware = [
  accounts.middleware,
  batches.middleware,
  authenticators.middleware,
  connections.middleware,
  entitlements.middleware,
  login.middleware,
  products.middleware
];

export * as accounts from './accounts';
export * as products from './products';
export * as authenticators from './authenticators';
export * as batches from './batches';
export * as connections from './connections';
export * as entitlements from './entitlements';
export * as login from './login';

export default reducer;
