import React from 'react';
import PropTypes from 'prop-types';

import { paths, AUTH } from '../../constants';
import { generatePath, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ShowIf } from '../fragments';
import { getAuthName } from '../../utils';

function AuthTable({ items }) {
  const params = useParams();
  const hasImplicitAccount = !!params.accountId;

  return (
    <table className="table authenticators">
      <thead>
        <tr>
          <th>Identifier</th>
          <ShowIf value={!hasImplicitAccount}>
            <th>Account</th>
          </ShowIf>
          <th>Type</th>
          <th>Role(s)</th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody>
        {items.map(auth => {
          const { Account = {}, Roles = [], blocked } = auth;
          return (
            <tr key={auth.authenticator_id}>
              <td>
                <Link
                  to={generatePath(paths.authenticators.edit, {
                    authenticator_id: auth.authenticator_id
                  })}
                >
                  {getAuthName(auth)}
                </Link>
              </td>
              <ShowIf value={!hasImplicitAccount}>
                <td>
                  <Link
                    to={generatePath(paths.accounts.show, {
                      accountId: Account?.account_id
                    })}
                  >
                    {Account?.account_name}
                  </Link>
                </td>
              </ShowIf>
              <td>{AUTH[auth.authenticator_type]?.name ?? '-'}</td>
              <td>
                <ul>
                  {Roles.map((role, index) => (
                    <li key={index}>{role.name}</li>
                  ))}
                </ul>
              </td>
              <td>
                <i
                  title={blocked ? 'blocked' : 'active'}
                  className={`far fa-${blocked ? 'times' : 'check'}-circle`}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

AuthTable.propTypes = {
  items: PropTypes.array
};

export default AuthTable;
