import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Parser } from '@json2csv/plainjs';
import { flatten } from '@json2csv/transforms';

import { downloadBlob, constructFilename, removeColumn } from '../../utils';

export default function ExportCSV({
  lazyQuery,
  params,
  style,
  label,
  filenamePath,
  setStatus,
  query
}) {
  const [trigger] = lazyQuery();
  const [loading, setLoading] = useState(false);
  const BATCH_SIZE = 1000;
  const { data: firstBatch } = query({
    ...params,
    page: 1,
    perPage: BATCH_SIZE
  });

  const handle = {
    onClick: async () => {
      const { count, results } = firstBatch;

      const opts = {
        transforms: [
          flatten({ object: true, array: true, separator: '_' }),
          removeColumn(['id'])
        ]
      };

      const filename = constructFilename(results[0], filenamePath);
      // parse with headers
      const firstParser = new Parser({ ...opts, header: true });
      let csv = `${firstParser.parse(results)}\n`;

      // check if we need to pull more batches
      if (count > BATCH_SIZE) {
        setLoading(true);
        const queries = [];
        const totalBatches = Math.ceil(count / BATCH_SIZE);

        // parse without headers
        const batchParser = new Parser({ ...opts, header: false });

        // Due to closure, we cannot update variables declared outside of the for-loop inside the .then() statement ,
        // so we push the batches to a Promise.all(), then concat a comma seperated string from the results.
        for (let index = 2; index <= totalBatches; index += 1) {
          queries.push(
            trigger({ ...params, page: index, perPage: BATCH_SIZE })
              .unwrap()
              .then(data => {
                return batchParser.parse(data.results);
              })
              .catch(error => {
                console.log(error);
                setStatus({
                  text: 'There was a problem exporting the data.',
                  style: 'error'
                });
              })
          );
        }
        // pull all of the batches at once.
        const batches = await Promise.all(queries);
        setLoading(false);
        // append the batches into a single comma seperated string
        batches.forEach(result => (csv += `${result}\n`));
      }
      // make a blob and download it
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8,' });
      downloadBlob(`${filename}_${new Date()}.csv`, blob);
    }
  };

  return (
    <button
      disabled={loading || !firstBatch?.count}
      id="download"
      className={style}
      onClick={handle.onClick}
    >
      Export {label}
    </button>
  );
}

ExportCSV.propTypes = {
  filenamePath: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  label: PropTypes.string,
  lazyQuery: PropTypes.func,
  query: PropTypes.func,
  setStatus: PropTypes.func,
  params: PropTypes.object,
  style: PropTypes.string
};
