import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router';

const fields = [
  {
    id: 'account',
    name: 'Account',
    placeholder: 'Account name or external id'
  },
  { id: 'title', name: 'Title' },
  /* @todo { id: 'collection', name: 'Collection' },*/
  { id: 'isbn', name: 'Item Number' }
];

function AccountEntitlementsFilter({ onChange, onReset, value }) {
  const { accountId } = useParams();
  return (
    <div className="my">
      <h4>Filters</h4>
      <form className="flex">
        {fields
          // we don't want to show the account input on the account page
          .filter(field => field.id !== 'account' || !accountId)
          .map(({ id, name, placeholder }) => (
            <div className="form-group" key={id}>
              <label htmlFor={id}>{name}</label>
              <input
                id={id}
                name={id}
                type="text"
                placeholder={placeholder ?? ''}
                onChange={onChange}
                value={value[id] ?? ''}
              />
            </div>
          ))}
        <button
          type="button"
          onClick={onReset}
          className="btn btn-outline btn-muted"
        >
          Clear Filter
        </button>
      </form>
    </div>
  );
}

AccountEntitlementsFilter.propTypes = {
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  value: PropTypes.object
};

export default AccountEntitlementsFilter;
