import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { password } from '../../utils';

/**
 * A controlled password input with visibility toggling and value generation.
 */
export default function PasswordInput(props) {
  const [isVisible, setVisible] = useState(false);
  const name = props.label ?? 'Password';
  const placeholder = '•'.repeat(props.options.length ?? 16);
  const id = name.toLowerCase();

  return (
    <div className="form-group mt">
      <label htmlFor={`input-${id}`}>{name}</label>
      <div className="flex">
        <input
          id={`input-${id}`}
          name={name}
          style={{ flexGrow: 1 }}
          type={isVisible ? 'text' : 'password'}
          onChange={e => props.onChange(e.target.value)}
          value={props.value ?? ''}
          // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
          autoComplete="new-password"
          placeholder={placeholder}
          required={props.required}
        />
        <button
          type="button"
          className="btn btn-icon btn-muted btn-input"
          onClick={() => setVisible(!isVisible)}
        >
          <i className={isVisible ? 'fa fa-eye' : 'fa fa-eye-slash'} />
          <span className="sr-only">Toggle Visibility</span>
        </button>
        <button
          type="button"
          className="btn"
          onClick={() => props.onChange(password(props.options))}
        >
          Generate {name}
        </button>
      </div>
    </div>
  );
}

PasswordInput.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object
};
