import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MessageBlock } from '.';
import { getErrors } from '../../utils';

/**
 * In very much the same manner as the Search, Filter and EntitlementForm
 * components, InteractiveTable abstracts away a variety of repetitive tasks
 * involving the bulk selection/update/removals of table items, along with the
 * integration of bulk edit forms and error display.
 */
export default function InteractiveTable({ multiple = true, ...props }) {
  // if the user's provided an onSelect handler, we'll treat the selection as controlled—otherwise, we'll manage that bit of state here.
  const [selected, setSelected] = props.onSelect
    ? [props.selected ?? [], props.onSelect]
    : useState([]);

  const allSelected =
    selected.length > 0 &&
    props.items.every(item => selected.includes(item.id));

  const [update, updateMutation] = props.updateMutation?.() ?? [null, null];
  const [remove, removeMutation] = props.removeMutation?.() ?? [null, null];

  const handle = {
    onSelect: id =>
      setSelected(
        selected.includes(id)
          ? selected.filter(item => item !== id)
          : selected.concat(id)
      ),
    // if IDs have been passed, select those—otherwise, toggle all.
    onSelectAll: ids => {
      // this lets us ignore event params so the fns don't need to be wrapped inline
      // will filter out the items on a page from a selection set,
      // will append previously selected items to the select page set
      return ids && Array.isArray(ids)
        ? setSelected(ids)
        : setSelected(
            allSelected
              ? selected.filter(
                  id => !props.items.map(item => item.id).includes(id)
                )
              : Array.from(
                  new Set([...props.items.map(item => item.id), ...selected])
                )
          );
    },
    // for single-select mode
    onSelectOne: id => setSelected([id]),
    // Generic handlers for passing new or updated batch data to the server. To
    // support single-item updates/deletes, a second param will accept an array
    // of IDs. This will default to the list of currently-selected items.
    onRemove: (ids = selected) => {
      // filter out items we're removing from the selected array
      return (
        setSelected(selected.filter(id => !ids.includes(id))) ??
        remove?.({ ids })
          .unwrap()
          .then(() => {
            props.setStatus?.({
              message: `${ids.length} entitlements were deleted.`,
              state: 'success'
            });
          })
          .catch(error => {
            console.log(error);
            props.setStatus?.({
              message: `There was a problem deleting the entitlements.`,
              state: 'error'
            });
          })
      );
    },
    onUpdate: (value, ids) => {
      // coerce into an array
      const all = ids ?? value.id ?? selected;
      const arr = (Array.isArray(all) ? all : [all]).filter(id => !!id);
      // reset selections and form
      return update?.({ ids: arr, ...value })
        .unwrap()
        .then(() => {
          props.setStatus?.({
            message: `${selected.length} entitlements were updated.`,
            state: 'success'
          });
          setSelected();
        })
        .catch(error => {
          console.log(error);
          props.setStatus?.({
            message:
              getErrors(error) ??
              `There was a problem updating the entitlements.`,
            state: 'error'
          });
        });
    }
  };

  const Table = props.table ?? (() => null);
  const Form = props.form ?? (() => null);
  const SelectAllItems = props.selectAllItems ?? (() => null);

  const isLoading =
    props.isLoading || updateMutation?.isLoading || removeMutation?.isLoading;

  return (
    <>
      <MessageBlock
        error
        messages={[
          ...getErrors(updateMutation?.error),
          ...getErrors(removeMutation?.error)
        ]}
      />
      <SelectAllItems
        items={props.items}
        total={props.total}
        disabled={isLoading}
        allSelected={allSelected}
        selected={multiple ? selected : selected[0]}
        onSelectAll={handle.onSelectAll}
      />
      <Form
        onSubmit={value => handle.onUpdate(value, selected)}
        onRemove={() => handle.onRemove(selected)}
        selected={multiple ? selected : selected[0]}
        alternate={props.alternate}
      />

      <Table
        total={props.total}
        disabled={isLoading}
        allSelected={allSelected}
        selected={multiple ? selected : selected[0]}
        onSelect={multiple ? handle.onSelect : handle.onSelectOne}
        onSelectAll={handle.onSelectAll}
        onUpdate={value => handle.onUpdate(value)}
        onRemove={id => handle.onRemove([id])}
        items={props.items}
        sort={props.sort}
        setSort={props.setSort}
      />
    </>
  );
}

InteractiveTable.propTypes = {
  // optionally controlled
  onSelect: PropTypes.func,
  selected: PropTypes.array,
  setStatus: PropTypes.func,
  // from parent
  items: PropTypes.array,
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  setSort: PropTypes.func,
  sort: PropTypes.array,
  alternate: PropTypes.bool,

  // child components
  form: PropTypes.elementType,
  table: PropTypes.elementType,
  selectAllItems: PropTypes.elementType,
  // RTK Query hooks
  updateMutation: PropTypes.func,
  removeMutation: PropTypes.func,
  // options
  multiple: PropTypes.bool
};
