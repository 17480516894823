import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from '../../utils';

const type = 'Connection';

function invalidatesTags(res, errors, { accountId, targetId }) {
  // when we PUT/DELETE a connection, we need to invalidate both's accounts' connection indices, along with the connections themselves
  return [
    { type, id: `${accountId}:index` },
    { type, id: `${targetId}:index` },
    { type, id: `${accountId}:${targetId}` },
    { type, id: `${targetId}:${accountId}` }
  ];
}

// if we're fetching the account's connections as a whole instead of a specific pair, make sure to limit tagging accordingly
function providesTags(res, errors, { accountId, targetId = null }) {
  return targetId
    ? [
        { type, id: `${accountId}:index` },
        { type, id: `${targetId}:index` },
        { type, id: `${accountId}:${targetId}` },
        { type, id: `${targetId}:${accountId}` }
      ]
    : [{ type, id: `${accountId}:index` }];
}

const api = createApi({
  reducerPath: 'connectionsAPI',
  baseQuery: getBaseQuery(),
  tagTypes: [type],
  endpoints: builder => ({
    getConnections: builder.query({
      query: ({ accountId, ...params }) => ({
        url: `/accounts/${accountId}/connections`,
        params,
        requiresPage: true,
        key: 'accounts'
      }),
      providesTags,
      transformResponse: (res, { response }) => {
        // we need to manually add "source" and "direct" based on depth
        const accounts = res.body.accounts.map(account => ({
          ...account,
          source: account.depth < 0,
          direct: account.depth === 1 || account.depth === -1
        }));

        const count = +(response.headers.get('x-total-count') ?? NaN);

        return {
          results: accounts,
          count
        };
      }
    }),
    addConnection: builder.mutation({
      query: ({ accountId, targetId }) => ({
        url: `/accounts/${accountId}/connections`,
        method: 'PUT',
        body: { account_id: targetId }
      }),
      providesTags,
      invalidatesTags,
      transformResponse: response => ({ ...response.body })
    }),
    removeConnection: builder.mutation({
      query: ({ accountId, targetId }) => ({
        url: `/accounts/${accountId}/connections/${targetId}`,
        method: 'DELETE'
      }),
      invalidatesTags
    })
  })
});

export const {
  useGetConnectionsQuery,
  useRemoveConnectionMutation,
  useAddConnectionMutation
} = api;

export default api;
