import React, { useEffect, useState } from 'react';

import {
  DEFAULT_PAGINATION,
  MAX_ENTITLEMENT_SORT_RESULTS
} from '../../constants';
import { filterQuery, getErrors } from '../../utils';
import {
  Spinner,
  MessageBlock,
  Paginator,
  ShowIf,
  ShowWith
} from '../fragments';
import PropTypes from 'prop-types';
import { useThrottle } from '@react-hook/throttle';

/**
 * Given a RTK Query `query` and components `filter` and `table`, return a
 * search interface that handles all of the pagination and filtering state
 * stuff, error handling and other boring/mundane tasks that would otherwise end
 * up being copy-pasted anyway. Apart from query/filter/table, all props are
 * forwarded to the wrapper div.
 *
 * The `filter` prop can be omitted, in which case it will not be rendered.
 */
export default function Search({ query, table: Table = () => null, ...props }) {
  // For cases where the table is holding onto a selection (ex. SelectAccountTable) give the parent the ability to clear the selection when filters are cleared.
  const clearSelection = props.clearSelection ?? (() => null);

  // Allow filter management from parent
  // Set filter with prefill values on component mount.
  // props.prefill needs to be separate from props.params because as it works now, props.params is a static query that overwrites the user query params if there is a match.
  const [filter, setFilter] = props.setFilters
    ? [{ ...props.prefill, ...props.params } ?? {}, props.setFilters]
    : useState({ ...props.prefill, ...props.params } ?? {});

  const [sort, setSort] = useState([]);

  // ...and pagination
  const [pagination, setPagination] = useState(
    props.pagination ?? DEFAULT_PAGINATION
  );
  // we need to store params and the filter state separately so we can throttle fetches without UI lag
  // this library uses FPS for some reason, but we're throttling requests to once per 333ms.
  const [params, setParams] = useThrottle(props.params ?? {}, 1000 / 333, true);
  // this will run whenever `params` changes
  const { data, error, status } = query({ ...params, sort });
  // apparently query.isLoading is only for the initial load
  const isLoading = status === 'pending';
  // these fields must be cleared out of the filters state when the user switches the authenticator type
  // otherwise the server will try to match an single authenticator on all listed authenticator type fields
  // @todo: this should definitely be moved elsewhere
  const authFilters = [
    'username',
    'issuer',
    'application',
    'starting_ip',
    'classroom'
  ];

  // handlers for our integrated filter form
  const handle = {
    // clear state - this includes a page (but not necessarily perPage) reset
    onReset: e =>
      e?.preventDefault() ??
      setFilter(props.retainPrefill ? props.prefill : {}) ??
      setPagination({ ...pagination, page: 1 }) ??
      setSort([]) ??
      clearSelection(),
    // update the local search state and reset page to 1 on change
    onChange: ({ target: { name, type, value, checked } }) => {
      //remove conditional authenticator fields from filters
      for (const item of authFilters) {
        if (filter[item]) {
          delete filter[item];
        }
      }
      // we need to handle checkboxes as their own special, stupid case
      return (
        setFilter({
          ...filter,
          [name]: type !== 'checkbox' ? value : checked
        }) ?? setPagination({ ...pagination, page: 1 })
      );
    }
  };

  useEffect(() => {
    if (!isLoading && data.count > MAX_ENTITLEMENT_SORT_RESULTS) {
      setSort([]);
    }
    // invoking `setParams` to execute the query
    setParams(filterQuery({ ...filter, ...pagination, ...props.params }));
  }, [filter, pagination, props.params, data]);

  return (
    <div id={props.id} className={props.className}>
      <ShowWith value={props.filter}>
        {Filter => (
          <Filter
            value={filter}
            onChange={handle.onChange}
            onReset={handle.onReset}
          />
        )}
      </ShowWith>
      {/* only bother displaying results if the query's resolved */}
      <ShowIf value={!(isLoading || error)}>
        <ShowWith value={data}>
          {({ count = 0, results = [] }) => (
            <Paginator
              onPageChange={page => setPagination({ ...pagination, page })}
              page={pagination.page}
              perPage={pagination.perPage}
              total={count}
            >
              <Table
                data={data}
                isLoading={isLoading}
                items={results}
                filter={filter}
                pagination={pagination}
                total={count}
                setSort={setSort}
                sort={sort}
              />
            </Paginator>
          )}
        </ShowWith>
      </ShowIf>
      <Spinner active={isLoading} />
      <ShowIf value={!isLoading}>
        {/* error block at the bottom to avoid jumpy page content */}
        <MessageBlock error className="my" messages={getErrors(error)} />
      </ShowIf>
    </div>
  );
}

Search.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  query: PropTypes.func,
  filter: PropTypes.elementType,
  table: PropTypes.elementType,
  params: PropTypes.object,
  prefill: PropTypes.object,
  retainPrefill: PropTypes.bool,
  pagination: PropTypes.object,
  setFilters: PropTypes.func,
  clearSelection: PropTypes.func
};
