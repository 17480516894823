import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, generatePath, useHistory } from 'react-router-dom';
import { AUTH, paths, SQS_TEMPLATE_URL as SQS_URL } from '../../constants';
import { MessageBlock, ShowWith } from '../fragments';
import { authenticators } from '../../redux/services';
import { getErrors } from '../../utils';
import useClipboard from '../hooks/useClipboard';

function FinishAuthenticator(props) {
  const { authenticator_id, auth_type } = props.form;
  const query = authenticators.useGetAuthenticatorQuery({ authenticator_id });
  const history = useHistory();

  const { copy: Copy, message: CopyMessage } = useClipboard();
  // we've actually created the new authenticator—go to the edit page
  const prev = generatePath(paths.authenticators.edit, { authenticator_id });

  return (
    <div id="finish_auth">
      <MessageBlock error messages={getErrors(query.error)} />

      <ShowWith value={query.data}>
        {({ authentication, Account, Roles = [] }) => {
          return (
            <>
              <div className="account-details">
                <table id="auth_details">
                  <tbody>
                    <ShowWith value={Account}>
                      {account => (
                        <tr>
                          <th>Account</th>
                          <td>
                            {account.account_name} - {account.account_to_crm_id}
                          </td>
                        </tr>
                      )}
                    </ShowWith>
                    <tr>
                      <th>Type</th>
                      <td>{AUTH[auth_type].name}</td>
                    </tr>
                    {Object.entries(authentication)
                      .filter(([key]) => AUTH[auth_type].labels[key])
                      .map(([key, value]) => (
                        <tr key={key}>
                          <th>{AUTH[auth_type].labels[key]}</th>
                          <td>{value}</td>
                        </tr>
                      ))}
                    <ShowWith value={authentication.sqs}>
                      {sqs => {
                        return (
                          <tr>
                            <th>{AUTH[auth_type].labels.link}</th>
                            <td>
                              <Copy
                                data="Login Link"
                                text={SQS_URL.replace(':sqs', sqs)}
                              />
                            </td>
                          </tr>
                        );
                      }}
                    </ShowWith>
                    <tr>
                      <th>Roles</th>
                      <td>
                        <ul>
                          {Roles.map((role, i) => (
                            <li key={i}>{role.name}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <CopyMessage />
              </div>
              <div className="my">
                <button className="btn" onClick={() => history.goBack()}>
                  Finish
                </button>
                {/* since this link replaces this route, "goBack" behavior will be carried over (e.g., to account pages) */}
                <Link className="btn btn-outline" to={prev} replace>
                  Edit
                </Link>
              </div>
            </>
          );
        }}
      </ShowWith>
    </div>
  );
}

FinishAuthenticator.propTypes = {
  form: PropTypes.object
};

const mapStateToProps = state => ({ form: state.forms.auth });

export default connect(mapStateToProps)(FinishAuthenticator);
