import PropTypes from 'prop-types';
import React from 'react';

function ProductsFilter({ onChange, value }) {
  return (
    <>
      <div className="filters products flex my">
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            id="title"
            name="title"
            type="text"
            onChange={onChange}
            value={value.title ?? ''}
          />
        </div>
        {/* @todo: Enable this when collection search is optimized
        <div className="form-group">
          <label htmlFor="collection">Collection</label>
          <input
            id="collection"
            name="collectiontitle"
            type="text"
            onChange={onChange}
            value={value.collection ?? ''}
          />
        </div>
        */}
        <div className="form-group">
          <label htmlFor="isbn">Item Number</label>
          <input
            id="isbn"
            name="isbn"
            type="text"
            onChange={onChange}
            value={value.isbn ?? ''}
          />
        </div>
      </div>
    </>
  );
}

ProductsFilter.propTypes = {
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  value: PropTypes.object
};

export default ProductsFilter;
