import React from 'react';
import { ShowIf } from '.';
import PropTypes from 'prop-types';

/**
 *
 * @param {number[]} ids all entitlements for current filter
 * @param {entitlement[]} items  records shown on the page
 * @param {number[]} selected  list of selected entitlement ids
 * @param {function} onSelectAll sets the selected state
 * @returns
 */
export default function SelectAllItems({
  ids = [],
  items = [],
  selected = [],
  onSelectAll
}) {
  // Determines whether all entitlements are selected
  const allSelected = ids?.every(item => selected.includes(item));

  const handle = {
    onSelectAll: () => {
      onSelectAll(Array.from(new Set(ids.concat(selected))));
    },
    onDeselectAll: () => {
      onSelectAll(selected.filter(item => !ids.includes(item)));
    }
  };
  const toggle = {
    all: allSelected ? handle.onDeselectAll : handle.onSelectAll
  };

  const { selectAll, deselectAll } = {
    selectAll: `Select all ${ids.length} items?`,
    deselectAll: `Deselect all ${ids.length} items?`
  };

  const text = {
    count: ' total items selected. ',
    deselectAll,
    selectAll
  };
  return (
    <div colSpan={3} className="select-all-items">
      <span className="text-small">
        <span className="bold">{selected.length}</span>
        {text.count}
        <ShowIf value={ids.length > items.length}>
          <a role="button" href="#" onClick={toggle.all}>
            {allSelected ? text.deselectAll : text.selectAll}
          </a>
        </ShowIf>
      </span>
    </div>
  );
}

SelectAllItems.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.array,
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  allSelected: PropTypes.bool,
  ids: PropTypes.array
};
