import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InteractiveTable, MessageBlock, Search, ShowIf } from '../fragments';
import { accounts, connections } from '../../redux/services';
import { AccountsFilter } from '../filters';
import { SelectAccountTable } from '../tables';
import { useParams } from 'react-router';
import { getErrors } from '../../utils';

// initial form state
const DEFAULT_FORM = { selected: null, type: null, isOpen: false, errors: [] };

export default function AddConnectionsForm(props) {
  const { accountId } = useParams();
  const [addConnection, mutation] = connections.useAddConnectionMutation();
  const [form, setForm] = useState({ ...DEFAULT_FORM });
  const isSubmitDisabled = !form.selected || !form.type || mutation.isLoading;

  // submit handler, executed once we click "add connection"
  const onSubmit = async event => {
    // stop the form from submitting.
    event.preventDefault();
    // we put PUTs and DELETEs in `/accounts/:parent/connections/:child` order,
    // so the behavior here changes per the current value of the dropdown.
    const [parent, child] =
      form.type === 'dst'
        ? [accountId, form.selected]
        : [form.selected, accountId];

    if (!(child && parent)) {
      // both must be defined, but I can't imagine a situation where it's > 1.
      setForm({ ...form, errors: ['You must select an account to connect.'] });
      return;
    }

    try {
      // add the connection...
      await addConnection({ accountId: parent, targetId: child }).unwrap();
      // reset the form...
      setForm({ ...DEFAULT_FORM });
      // close if the user wanted to.
      if (!form.isOpen) {
        props.onClose();
      }
    } catch (e) {
      // no-op
    }
  };

  // since these all need to be different anyway, figure it made more sense
  // to stick them in a block rather than have a bunch of inline handlers
  const handle = {
    onSelect: selected => setForm({ ...form, selected }),
    onToggleOpen: e => setForm({ ...form, isOpen: !!e.target.checked }),
    onChangeType: e => setForm({ ...form, type: e.target.value })
  };

  const messages = form.errors.concat(
    // an existing mutation message is "stale" if a client-side error has been added in the meantime
    form.errors.length ? [] : getErrors(mutation.error)
  );

  return (
    <>
      <h1 className="h2 mb">Add Connections</h1>
      <MessageBlock error messages={messages} />
      {/* completed successfully */}
      <ShowIf value={mutation.isSuccess}>
        <MessageBlock className="msg-success">
          Connection added successfully.
        </MessageBlock>
      </ShowIf>
      {/* account search/selector */}
      <Search
        query={accounts.useGetAccountsQuery}
        params={{ archived: false }}
        filter={AccountsFilter}
        table={tableProps => (
          <InteractiveTable
            {...tableProps}
            multiple={false}
            table={SelectAccountTable}
            selected={form.selected}
            onSelect={handle.onSelect}
          />
        )}
      />
      {/* interactive form bits */}
      <form className="mt" onSubmit={onSubmit}>
        <div className="form-group">
          <label>Relationship</label>
          <select
            name="type"
            value={form.type || ''}
            onChange={handle.onChangeType}
            required
          >
            <option value="" disabled selected>
              – Select Relationship –
            </option>
            <option value="src">Parent Account</option>
            <option value="dst">Sub Account</option>
          </select>
        </div>
        <div className="form-group checkbox">
          <label>
            Add another account
            <input
              type="checkbox"
              checked={form.isOpen}
              onChange={handle.onToggleOpen}
            />
          </label>
        </div>
        <div className="mt">
          <button className="btn" type="submit" disabled={isSubmitDisabled}>
            Connect Account
          </button>
          <button
            className="btn btn-muted btn-outline"
            onClick={props.onClose}
            disabled={mutation.isLoading}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
}

AddConnectionsForm.propTypes = {
  onClose: PropTypes.func
};
