import addEntitlements from './addEntitlements.png';
import addEntitlementsBtn from './addEntitlementsBtn.jpg';
import addISBNsBtn from './addISBNsBtn.jpg';
import addItemsBtn from './addItemsBtn.png';
import addProductsOverlayISBN from './addProductsOverlayISBN.png';
import addProductsOverlaySearch from './addProductsOverlaySearch.png';
import bulkRemoveBtn from './bulkRemoveBtn.png';
import doneEditingBtn from './doneEditingBtn.png';
import editEntitlements from './editEntitlements.png';
import editEntitlementsBtn from './editEntitlementsBtn.png';
import entitlements from './entitlements.png';
import exportEntitlementsBtn from './exportEntitlementsBtn.png';
import greenCheckSubmitBtn from './greenCheckSubmitBtn.png';
import isbnTab from './isbnTab.png';
import purpleResetBtn from './purpleResetBtn.png';
import relatedAccountsBox from './relatedAccountsBox.png';
import reviewAndFinish from './reviewAndFinish.png';
import searchTab from './searchTab.png';
import selectAllAccountsBox from './selectAllAccountsBox.png';
import selectAllEntitlementsBox from './selectAllEntitlementsBox.png';
import selectOneEntitlementBox from './selectOneEntitlementBox.png';
import addProductsBtn from './addProductsBtn.png';
import totalItemsSelected from './totalItemsSelected.png';
import selectProduct from './selectProduct.png';
import sortableColumn from './sortableColumn.png';
import selectAllProducts from './selectAllProducts.png';
import selectAllAccountsAllPages from './selectAllAccountsAllPages.png';

export default {
  addEntitlementsBtn,
  addEntitlements,
  addISBNsBtn,
  addItemsBtn,
  addProductsOverlayISBN,
  addProductsOverlaySearch,
  bulkRemoveBtn,
  doneEditingBtn,
  editEntitlements,
  editEntitlementsBtn,
  entitlements,
  exportEntitlementsBtn,
  greenCheckSubmitBtn,
  isbnTab,
  purpleResetBtn,
  relatedAccountsBox,
  reviewAndFinish,
  searchTab,
  selectAllAccountsBox,
  selectAllEntitlementsBox,
  selectOneEntitlementBox,
  addProductsBtn,
  totalItemsSelected,
  selectProduct,
  sortableColumn,
  selectAllProducts,
  selectAllAccountsAllPages
};
