import React from 'react';
import PropTypes from 'prop-types';

import { ShowIf } from '.';

export default function Spinner(props) {
  return (
    <ShowIf value={props.active ?? true}>
      <div className="spinner-container">
        <div className="spinner">
          <label htmlFor="loading-progress" className="sr-only">
            Loading&hellip;
          </label>
          <progress id="loading-progress" />
        </div>
      </div>
    </ShowIf>
  );
}

Spinner.propTypes = {
  active: PropTypes.bool
};
