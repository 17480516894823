import accountLink from './accountLink.png';
import activeIcon from './activeIcon.jpg';
import backBtn from './backBtn.png';
import cancelBtn from './cancelBtn.jpg';
import clearFilterBtn from './clearFilterBtn.jpg';
import createBtn from './createBtn.png';
import editBtn from './editBtn.png';
import finishBtn from './finishBtn.png';
import highlightedAccount from './highlightedAccount.png';
import highlightedAccountFamous from './highlightedAccountFamous.jpg';
import inactiveIcon from './inactiveIcon.jpg';
import paginationControls from './paginationControls.jpg';
import nextBtn from './nextBtn.png';
import removeBtn from './removeBtn.jpg';
import updateBtn from './updateBtn.png';

export default {
  accountLink,
  activeIcon,
  backBtn,
  cancelBtn,
  clearFilterBtn,
  createBtn,
  editBtn,
  finishBtn,
  highlightedAccount,
  highlightedAccountFamous,
  inactiveIcon,
  paginationControls,
  nextBtn,
  removeBtn,
  updateBtn
};
