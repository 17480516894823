import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { EntitlementForm, MessageBlock, ShowWith } from '../fragments';
import { batches } from '../../redux/services';
import { getErrors } from '../../utils';

function RenewBatchForm(props) {
  const batch = batches.useGetBatchQuery({ batch_id: props.batchId });
  const [renew, renewal] = batches.useRenewBatchMutation();
  const isLoading = renewal.isLoading || batch.isLoading;

  useEffect(() => {
    // if we've renewed, inform the parent component so it can redirect us.
    if (renewal.data?.id) {
      props.onRenew(renewal.data.id);
    }
  }, [renewal.data?.id]);

  return (
    <div>
      <h2>Renew Batch</h2>
      <MessageBlock error messages={getErrors(renewal.error)} />
      <ShowWith value={batch.data}>
        {data => (
          <div className="batch-info">
            <h4 id="batch_title">{data.title}</h4>
            <p id="entitlement_batch_to_crm_id">
              {data.entitlement_batch_to_crm_id}
            </p>
          </div>
        )}
      </ShowWith>
      <EntitlementForm
        renewal
        onSubmit={value => renew({ ...value, batch_id: props.batchId })}
      >
        {({ valid, onChange, onSubmit, value }) => (
          <form onSubmit={e => e.preventDefault() ?? onSubmit(value)}>
            {/* renewals are always subscriptions! */}
            <div className="my">
              <div className="form-group">
                <label htmlFor="title">New Title</label>
                <input
                  id="title"
                  type="text"
                  name="title"
                  value={value.title ?? ''}
                  onChange={onChange}
                  required
                />
              </div>
              <div className="flex my">
                <div className="form-group">
                  <label htmlFor="start_date">New Start Date</label>
                  <input
                    id="start_date"
                    type="date"
                    name="start_date"
                    max={value.end_date ?? ''}
                    value={value.start_date ?? ''}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="end_date">New End Date</label>
                  <input
                    id="end_date"
                    type="date"
                    name="end_date"
                    min={value.start_date ?? ''}
                    value={value.end_date ?? ''}
                    onChange={onChange}
                    required
                  />
                </div>
              </div>
            </div>
            <button
              className="btn"
              disabled={!valid || !value.title || isLoading}
              type="submit"
            >
              Create Renewal Batch
            </button>
          </form>
        )}
      </EntitlementForm>
    </div>
  );
}

RenewBatchForm.propTypes = {
  onRenew: PropTypes.func,
  onClose: PropTypes.func,
  batchId: PropTypes.number
};

export default RenewBatchForm;
