import addAuthenticatorBtn from './addAuthenticatorBtn.png';
import addAuthenticatorDetails from './addAuthenticatorDetails.png';
import addAuthenticatorFinish from './addAuthenticatorFinish.png';
import addAuthenticatorSelect from './addAuthenticatorSelect.png';
import authenticators from './authenticators.png';
import editAuthenticator from './editAuthenticator.png';
import editAuthenticatorLink from './editAuthenticatorLink.png';
import generatePasswordBtn from './generatePasswordBtn.png';
import googleTab from './googleTab.png';
import ipTab from './ipTab.png';
import loginLink from './loginLink.png';
import ltiTab from './ltiTab.png';
import oauthTab from './oauthTab.png';
import passwordTab from './passwordTab.png';
import regenerateSQSBox from './regenerateSQSBox.png';
import roleBox from './roleBox.png';
import viewPasswordBtn from './viewPasswordBtn.png';

export default {
  addAuthenticatorBtn,
  editAuthenticatorLink,
  addAuthenticatorDetails,
  addAuthenticatorFinish,
  addAuthenticatorSelect,
  authenticators,
  editAuthenticator,
  generatePasswordBtn,
  googleTab,
  ipTab,
  loginLink,
  ltiTab,
  oauthTab,
  passwordTab,
  regenerateSQSBox,
  roleBox,
  viewPasswordBtn
};
