import React from 'react';
import { BatchesFilter } from '../filters';
import { BatchesTable } from '../tables';
import { Search } from '../fragments';
import { useParams } from 'react-router';
import { batches } from '../../redux/services';

export default function AccountBatches() {
  const { accountId: account_id } = useParams();
  return (
    <>
      <Search
        params={{ account_id }}
        query={batches.useGetBatchesQuery}
        table={BatchesTable}
        filter={BatchesFilter}
      />
    </>
  );
}
