import React, { useState } from 'react';

import { batches, entitlements } from '../../redux/services';
import BulkEditEntitlements from '../tables/BulkEntitlementsTable/BulkEditEntitlements';
import { EntitlementsTable, BulkEntitlementsTable } from '../tables';
import {
  Overlay,
  PageHeader,
  Search,
  ShowIf,
  ShowWith,
  MessageBlock,
  EditableText
} from '../fragments';
import { generatePath, Redirect, useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { paths } from '../../constants';
import { RenewBatchForm } from '../forms';
import { EntitlementsFilter } from '../filters';
import ExportCSV from '../fragments/ExportCSV';
import { getErrors } from '../../utils';

function Batch() {
  const { batch_id } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ id: batch_id, isOpen: false });
  const batch = batches.useGetBatchQuery({ batch_id });
  const [filters, setFilters] = useState({ batch_id });
  const [status, setStatus] = useState({ text: '', style: 'error' });
  const [update, updateMutation] = batches.useUpdateBatchMutation();
  const [editable, setEditable] = useState(false);
  const Table = editable ? BulkEntitlementsTable : EntitlementsTable;

  const LABEL = {
    title: 'Title',
    entitlement_batch_to_crm_id: 'External Id'
  };
  const handle = {
    onRenew: id => setState({ id, isOpen: false }),
    onToggle: () => setState({ ...state, isOpen: !state.isOpen }),
    onUpdate: (key, value) => {
      update({ batch_id, [key]: value })
        .unwrap()
        .then(() => {
          setStatus({
            text: `${LABEL[key]} has updated successfully.`,
            style: 'success'
          });
        })
        .catch(error => {
          setStatus({ text: getErrors(error)[0], style: 'error' });
        });
    }
  };

  if (state.id.toString() !== batch_id) {
    // if we've renewed, redirect to the new batch
    return (
      <Redirect to={generatePath(paths.batches.edit, { batch_id: state.id })} />
    );
  }

  return (
    <div>
      <Overlay isOpen={state.isOpen} onClose={handle.onToggle}>
        <RenewBatchForm
          onRenew={handle.onRenew}
          onClose={handle.onToggle}
          batchId={batch.data?.entitlement_batch_id}
        />
      </Overlay>
      <PageHeader title="Batch Detail">
        <ShowIf value={batch.data && batch.data.executed}>
          <button
            type="button"
            className="btn btn-lg"
            onClick={handle.onToggle}
          >
            Renew Batch
          </button>
        </ShowIf>
        <ShowIf value={batch.data && !batch.data.executed}>
          <Link
            className="btn btn-lg"
            to={generatePath(paths.batches.edit, { batch_id })}
          >
            Edit Batch
          </Link>
        </ShowIf>

        <ExportCSV
          params={{ ...filters, batch_id }}
          lazyQuery={entitlements.useLazyGetEntitlementsQuery}
          query={entitlements.useGetEntitlementsQuery}
          filenamePath={'EntitlementBatch.title'}
          label="Batch"
          style="btn btn-outline btn-lg"
          setStatus={setStatus}
        />
      </PageHeader>
      <ShowWith value={batch.data}>
        {data => (
          <div className="batch-info">
            <EditableText
              title="Batch Title"
              isLoading={updateMutation.isLoading}
              className={batch.title ? 'h2' : 'h2 text-italic'}
              placeholder="Untitled"
              name={LABEL.title}
              nullable
              value={data.title}
              onChange={value => handle.onUpdate('title', value)}
              setStatus={setStatus}
            />
            <EditableText
              title="External ID"
              isLoading={updateMutation.isLoading}
              placeholder="No External ID"
              className={batch.title ? 'p' : 'p text-muted'}
              id="entitlement_batch_to_crm_id"
              value={data.entitlement_batch_to_crm_id}
              nullable
              name={LABEL.entitlement_batch_to_crm_id}
              onChange={value =>
                handle.onUpdate('entitlement_batch_to_crm_id', value, true)
              }
              setStatus={setStatus}
            />
            <p
              title="Purchasing Account"
              id="purchasing_account"
              className="text-muted"
            >
              {batch.data.Account.account_name}
            </p>
          </div>
        )}
      </ShowWith>
      <MessageBlock className={`msg mb msg-outline mt msg-${status.style}`}>
        {status.text}
      </MessageBlock>
      <ShowIf value={batch.data?.executed}>
        <BulkEditEntitlements>
          {stateProps => (
            <Search
              params={{ ...filters, batch_id }}
              setFilters={setFilters}
              query={entitlements.useGetEntitlementsQuery}
              filter={EntitlementsFilter}
              table={tableProps => (
                <div>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      stateProps.onSelect([]);
                      setEditable(!editable);
                    }}
                  >
                    {editable ? 'Done Editing' : 'Edit Entitlements'}
                  </button>
                  <Table {...tableProps} {...stateProps} alternate />
                </div>
              )}
            />
          )}
        </BulkEditEntitlements>
      </ShowIf>
      <ShowIf value={!batch.data?.executed}>
        <Search
          params={{ ...filters, batch_id }}
          setFilters={setFilters}
          filter={EntitlementsFilter}
          table={EntitlementsTable}
          query={entitlements.useGetEntitlementsQuery}
        />
      </ShowIf>
      <div className="mt">
        <button
          className="btn btn-muted btn-outline"
          onClick={() => history.goBack()}
        >
          Back
        </button>
      </div>
    </div>
  );
}

Batch.propTypes = {};

export default Batch;
