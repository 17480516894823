import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery, getListTagger, getTransformResponse } from '../../utils';

const type = 'Product';

const tagList = getListTagger(type);

const api = createApi({
  reducerPath: 'productsAPI',
  baseQuery: getBaseQuery(),
  tagTypes: [type],
  endpoints: builder => ({
    getProducts: builder.query({
      query: params => ({
        url: `/products`,
        params,
        requiresPage: true,
        key: 'products'
      }),
      providesTags: tagList,
      transformResponse: getTransformResponse('products')
    })
  })
});

export const { useGetProductsQuery } = api;

export default api;
