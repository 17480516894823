import { createApi } from '@reduxjs/toolkit/query/react';
import { MAX_SELECT_ALL_RESULTS } from '../../constants';
import { getBaseQuery, getListTagger, getTransformResponse } from '../../utils';

const type = 'Entitlement';

const transformResponse = getTransformResponse(
  'entitlements',
  'entitlement_id'
);

const providesTags = getListTagger(type);

const invalidatesTags = (res, error, arg) =>
  (arg.ids ?? []).reduce(
    (tags, id) => tags.concat({ type, id }),
    [{ type, id: 'index' }]
  );

const api = createApi({
  reducerPath: 'entitlementsAPI',
  baseQuery: getBaseQuery(),
  tagTypes: [type],
  endpoints: builder => ({
    getEntitlements: builder.query({
      query: params => ({
        url: `/entitlements`,
        params,
        requiresPage: true,
        key: 'entitlements'
      }),
      providesTags,
      transformResponse
    }),
    getEntitlementIDs: builder.query({
      query: params => ({
        url: `/entitlements`,
        params: {
          ...params,
          include: 'entitlement_id',
          perPage: MAX_SELECT_ALL_RESULTS
        },
        key: 'entitlements'
      }),
      providesTags,
      transformResponse: response => response.body.entitlements
    }),
    getAccountEntitlements: builder.query({
      query: ({ account_id, ...params }) => ({
        url: `/accounts/${account_id}/entitlements`,
        params
      }),
      providesTags,
      transformResponse
    }),
    createEntitlements: builder.mutation({
      query: ({ batch_id, ...body }) => ({
        url: `/batches/${batch_id}`,
        method: 'POST',
        body
      }),
      transformResponse: res => res.body,
      invalidatesTags
    }),
    updateEntitlements: builder.mutation({
      query: ({ ids, ...body }) => ({
        url: '/entitlements',
        method: 'PUT',
        body: { entitlements: ids, ...body }
      }),
      invalidatesTags
    }),
    removeEntitlements: builder.mutation({
      query: ({ ids }) => ({
        url: '/entitlements',
        method: 'DELETE',
        body: { entitlements: ids }
      }),
      invalidatesTags
    })
  })
});

export const {
  useGetEntitlementsQuery,
  useLazyGetEntitlementsQuery,
  useGetEntitlementIDsQuery,
  useGetAccountEntitlementsQuery,
  useCreateEntitlementsMutation,
  useUpdateEntitlementsMutation,
  useRemoveEntitlementsMutation
} = api;

export default api;
