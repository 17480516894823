import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useGetRolesQuery } from '../../redux/services/authenticators';
import { ShowWith } from '../fragments';
import { setForm } from '../../redux/slices/forms';
import { NON_EXCLUSIVE_ROLES, ROLE } from '../../constants';

function SelectRolesForm(props) {
  const { data: rolesList } = useGetRolesQuery();
  // Note: District Admin has been renamed to Multi-Site Admin

  const isExclusive = role_id => !NON_EXCLUSIVE_ROLES.includes(role_id);
  const filterOutExclusiveRoles = concat => {
    return props.form.roles
      .filter(
        role =>
          role === ROLE.REPORTING.roleId || role === ROLE.CONTENT_MANAGER.roleId
      )
      .concat(concat);
  };

  const onChange = event => {
    const id = +event.target.id;
    let roles = [id];
    switch (true) {
      case !isExclusive(id):
        roles = Array.from(
          new Set(
            // For non-exclusive roles
            // Filter out exclusive roles, add Multi-Site Admin id along with selected role_id
            event.target.checked
              ? filterOutExclusiveRoles([ROLE.DISTRICT.roleId, id])
              : props.form.roles.filter(role => role !== id)
          )
        );
        break;
      case id === ROLE.DISTRICT.roleId:
        // For Multi-Site Admin role
        // Filter out exclusive roles, leaving non-exclusive roles add Multi-Site Admin id
        roles = filterOutExclusiveRoles([id]);
        break;
      default:
        break;
    }
    // Set the form roles
    props.setForm({
      isUpdated: false,
      roles
    });
  };

  return (
    <div className="user-roles">
      <label>Select Role/Permissions</label>
      <ShowWith value={rolesList}>
        {data =>
          data
            .filter(role => props.privileged || !role.privileged)
            .map(({ role_id, name }) => (
              <div key={role_id} className="form-group checkbox">
                <label htmlFor={role_id}>
                  {name}
                  <input
                    // Exclusive roles are radio buttons, non-exclusive roles are checkboxes, generate and handle input types accordingly
                    className={isExclusive(role_id) ? '' : 'multi-site'}
                    type={isExclusive(role_id) ? 'radio' : 'checkbox'}
                    name={name}
                    id={role_id}
                    value={role_id}
                    onChange={onChange}
                    checked={props.form.roles.includes(role_id)}
                  />
                </label>
              </div>
            ))
        }
      </ShowWith>
    </div>
  );
}

SelectRolesForm.propTypes = {
  setForm: PropTypes.func,
  form: PropTypes.object,
  privileged: PropTypes.bool
};

const mapStateToProps = state => ({ form: state.forms.auth });
const mapDispatchToProps = {
  setForm: data => setForm({ data, type: 'auth' })
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectRolesForm);
