import React from 'react';
import { Route, Switch, Redirect, NavLink } from 'react-router-dom';
import ManualPage from './ManualPage';

import GettingStarted from './GettingStarted.mdx';
import Entitlements from './Entitlements.mdx';
import Accounts from './Accounts.mdx';
import Batches from './Batches.mdx';
import Authenticators from './Authenticators.mdx';
import ChangePassword from './ChangePassword.mdx';
import CreateAccount from './CreateAccount.mdx';
import FulfillCIOrder from './FulfillCIOrder.mdx';
import RenewPebbleGoOrder from './RenewPebbleGoOrder.mdx';
import TroubleshootProductAccess from './TroubleshootProductAccess.mdx';

const manualPaths = {
  root: '/manual',
  getstarted: '/manual/getstarted',
  reference: {
    root: '/manual/reference',
    authenticators: '/manual/reference/authenticators',
    entitlements: '/manual/reference/entitlements',
    accounts: '/manual/reference/accounts',
    batches: '/manual/reference/batches'
  },
  guides: {
    root: '/manual/guides',
    orders: {
      root: '/manual/guides/orders',
      createAccount: '/manual/guides/orders/create-account',
      fulfillPGOOrder: '/manual/guides/orders/fulfill-pebblego-order',
      renewPGOOrder: '/manual/guides/orders/renew-pebblego-order',
      fullfillCIOrder: '/manual/guides/orders/fulfill-ci-order'
    },
    support: {
      root: '/manual/guides/support',
      changePassword: '/manual/guides/support/change-password',
      troubleshootProductAccess:
        '/manual/guides/support/troubleshoot-product-access'
    }
  }
};

export default function Manual() {
  return (
    <div className="flex">
      <nav id="manual-toc">
        <ul className="root">
          <ul>
            <li>
              <NavLink to={manualPaths.getstarted}>Getting Started</NavLink>
            </li>
          </ul>
          <li>
            <h5>Reference</h5>
            <ul>
              <li>
                <NavLink to={manualPaths.reference.authenticators}>
                  Authenticators
                </NavLink>
              </li>
              <li>
                <NavLink to={manualPaths.reference.accounts}>Accounts</NavLink>
              </li>
              <li>
                <NavLink to={manualPaths.reference.entitlements}>
                  Entitlements
                </NavLink>
              </li>
              <li>
                <NavLink to={manualPaths.reference.batches}>Batches</NavLink>
              </li>
            </ul>
          </li>
          {/*
          <li>
            <h5>Order Fulfillment</h5>
            <ul>
              <li>
                <NavLink to={manualPaths.guides.orders.createAccount}>
                  Create an Account
                </NavLink>
              </li>
              <li>
                <NavLink to={manualPaths.guides.orders.fulfillPGOOrder}>
                  Fulfill a PebbleGo Order
                </NavLink>
              </li>
              <li>
                <NavLink to={manualPaths.guides.orders.renewPGOOrder}>
                  Renew a PebbleGo Order (including Expansions)
                </NavLink>
              </li>
              <li>
                <NavLink to={manualPaths.guides.orders.fullfillCIOrder}>
                  Fullfill a Capstone Interactive Order
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <h5>Support</h5>
            <ul>
              <li>
                <NavLink to={manualPaths.guides.support.changePassword}>
                  Change a Password
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={manualPaths.guides.support.troubleshootProductAccess}
                >
                  Troubleshoot Product Access
                </NavLink>
              </li>
            </ul>
          </li>
          */}
        </ul>
      </nav>
      <Switch>
        <Route path={manualPaths.getstarted} exact>
          <ManualPage component={GettingStarted} />
        </Route>
        <Route path={manualPaths.reference.entitlements} exact>
          <ManualPage component={Entitlements} />
        </Route>
        <Route path={manualPaths.reference.authenticators} exact>
          <ManualPage component={Authenticators} />
        </Route>
        <Route path={manualPaths.reference.accounts} exact>
          <ManualPage component={Accounts} />
        </Route>
        <Route path={manualPaths.reference.batches} exact>
          <ManualPage component={Batches} />
        </Route>
        <Route path={manualPaths.guides.orders.createAccount} exact>
          <CreateAccount />
        </Route>
        <Route path={manualPaths.guides.orders.fulfillPGOOrder} exact>
          <FulfillCIOrder />
        </Route>
        <Route path={manualPaths.guides.orders.renewPGOOrder} exact>
          <RenewPebbleGoOrder />
        </Route>
        <Route path={manualPaths.guides.orders.fullfillCIOrder} exact>
          <FulfillCIOrder />
        </Route>
        <Route path={manualPaths.guides.support.changePassword} exact>
          <ChangePassword />
        </Route>
        <Route
          path={manualPaths.guides.support.troubleshootProductAccess}
          exact
        >
          <TroubleshootProductAccess />
        </Route>
        <Redirect
          from={manualPaths.guides.orders.root}
          to={manualPaths.guides.orders.createAccount}
        />
        <Redirect
          from={manualPaths.guides.support.root}
          to={manualPaths.guides.support.changePassword}
        />
        <Redirect
          from={manualPaths.reference.root}
          to={manualPaths.reference.authenticators}
        />
        <Redirect
          from={manualPaths.guides.root}
          to={manualPaths.guides.orders.root}
        />
        <Redirect from={manualPaths.root} to={manualPaths.getstarted} />
      </Switch>
    </div>
  );
}
