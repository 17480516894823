import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, Link } from 'react-router-dom';
import { paths } from '../../constants';

function AccountsTable({ items = [] }) {
  return (
    <table className="table accounts">
      <thead>
        <tr>
          <th></th>
          <th>Account Name</th>
          <th>External ID</th>
        </tr>
      </thead>
      <tbody>
        {items.map(account => (
          <tr key={account.account_id}>
            <td className="archived-column">
              <i
                title="Archived"
                className={account.archived ? 'fas fa-archive' : ''}
              ></i>
            </td>
            <td>
              <Link
                to={generatePath(paths.accounts.show, {
                  accountId: account.account_id
                })}
              >
                {account.account_name}
              </Link>
            </td>
            <td>{account.account_to_crm_id}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

AccountsTable.propTypes = {
  items: PropTypes.array
};

export default AccountsTable;
