import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import { ShowIf, ShowWith } from '.';

/**
 * A modification of the Overlay component for displaying yes/no actions.
 * ```
 */
export default function Dialog(props) {
  /**
   * on mount, add the class to prevent x/y overflow in the document body.
   * on unmount, remove it to restore normal behavior.
   */
  useEffect(() => {
    document.body.classList.toggle('has-overlay', props.isOpen);
    return () => document.body.classList.remove('has-overlay');
  });

  return createPortal(
    <div className="dialog-overlay">
      <Transition
        // without this prop, the overlay can interact strangely with the router
        appear
        show={props.isOpen ?? false}
        enter="fade"
        leave="fade"
        entered="fade in"
        enterTo="in"
        leaveTo="out"
      >
        <div className="dialog-container">
          {/* click the overlay to dismiss */}
          <div className="dialog-bg" onClick={props.onClose} />
          <div className="dialog">
            {/* close button */}
            <button
              className="btn btn-icon btn-muted"
              onClick={props.onClose}
              title="Close"
            >
              <i className="fas fa-times" />
            </button>
            {/* dialog body */}
            <ShowWith value={props.title}>
              {title => <h4 className="dialog-title">{title}</h4>}
            </ShowWith>
            <div className="dialog-content">{props.children}</div>
            <ShowIf value={!props.noAction}>
              <>
                <div className="dialog-actions flex">
                  <button
                    type="button"
                    className="btn"
                    onClick={props.onConfirm}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn btn-muted btn-outline"
                    onClick={props.onClose}
                  >
                    Cancel
                  </button>
                </div>
              </>
            </ShowIf>
          </div>
        </div>
      </Transition>
    </div>,
    document.body
  );
}

Dialog.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
};
