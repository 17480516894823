import React from 'react';

import SelectRolesForm from './SelectRolesForm';
import { AuthBlockedForm, AuthTypeForm } from '../fragments';

const fields = [{ id: 'classroom', name: 'Classroom ID' }];

export default function GoogleAuthForm() {
  return (
    <div id="google-auth-form" className="user-details">
      <AuthTypeForm fields={fields}>
        <AuthBlockedForm />
        <SelectRolesForm />
      </AuthTypeForm>
    </div>
  );
}
