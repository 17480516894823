import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FinishAuth from './FinishAuthenticator';

import { EditAuthenticatorForm } from '../forms';
import { useHistory, useLocation } from 'react-router';
import { FORM_STEP } from '../../constants';
import { clamp, password } from '../../utils';
import { resetForm } from '../../redux/slices/forms';
import { ShowIf } from '../fragments';
import { SelectAuthenticatorAccountForm } from '../forms/SelectAccountForm';

const components = {
  [FORM_STEP.SELECT_ACCOUNT]: SelectAuthenticatorAccountForm,
  [FORM_STEP.EDIT_DETAILS]: EditAuthenticatorForm,
  [FORM_STEP.REVIEW]: FinishAuth
};

function AddAuthenticator(props) {
  const history = useHistory();
  const { account_id = null } = useLocation().state ?? {};

  const start = account_id ? FORM_STEP.EDIT_DETAILS : FORM_STEP.SELECT_ACCOUNT;
  const [step, setStep] = useState(start);
  const params = step === 1 ? { archived: false } : {};
  const Step = components[step];
  const onNext = () =>
    setStep(curr => clamp(curr + 1, start, FORM_STEP.REVIEW));

  // if "back" put us before the first step, go back in the history
  const onPrev = () =>
    step - 1 < start
      ? history.goBack()
      : setStep(curr => clamp(curr - 1, start, FORM_STEP.REVIEW));

  // return the appropriate class per breadcrumb status
  const getClass = id =>
    step === id ? 'breadcrumb active' : 'breadcrumb disabled';

  useEffect(
    () =>
      // only populate the password/token if we're creating the authenticator
      props.resetForm({
        accountId: account_id,
        password: password({ strict: true, length: 16 }),
        token: password({ strict: true, length: 32 })
      }),
    []
  );

  return (
    <div id="add_authenticator">
      <h1>Add Authenticator</h1>
      <ol className="breadcrumbs">
        {/* we don't want to show this if the user has navigated here from an account page */}
        <ShowIf value={!account_id}>
          <li className={getClass(FORM_STEP.SELECT_ACCOUNT)}>Select Account</li>
        </ShowIf>
        {/* but these are always relevant */}
        <li className={getClass(FORM_STEP.EDIT_DETAILS)}>Edit Details</li>
        <li className={getClass(FORM_STEP.REVIEW)}>Review & Finish</li>
      </ol>
      <Step type="auth" onNext={onNext} onPrev={onPrev} params={params} />
    </div>
  );
}

AddAuthenticator.propTypes = {
  form: PropTypes.object,
  resetForm: PropTypes.func
};

const mapStateToProps = state => ({ form: state.forms.auth });

const mapDispatchToProps = {
  resetForm: data => resetForm({ data, type: 'auth' })
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAuthenticator);
