import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { authenticators } from '../../redux/services';

import { EditAuthenticatorForm } from '../forms';
import { PageHeader, ShowIf, Spinner } from '../fragments';
import { resetForm, setForm } from '../../redux/slices/forms';
import { getAuthForm } from '../../utils';

function EditAuthenticator(props) {
  const { authenticator_id = null } = useParams();
  const history = useHistory();

  const query = authenticators.useGetAuthenticatorQuery({ authenticator_id });

  useEffect(
    () =>
      query.data ? props.setForm(getAuthForm(query.data)) : props.resetForm(),
    [query.data]
  );

  return (
    <>
      <PageHeader title="Edit Authenticator" />
      <ShowIf value={!query.isLoading}>
        <EditAuthenticatorForm onPrev={() => history.goBack()} />
      </ShowIf>
      <ShowIf value={query.isLoading}>
        <div className="mt" style={{ width: 640 }}>
          <Spinner />
        </div>
      </ShowIf>
    </>
  );
}

EditAuthenticator.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  resetForm: PropTypes.func
};

const mapStateToProps = state => ({
  form: state.forms.auth
});

const mapDispatchToProps = {
  setForm: data => setForm({ data, type: 'auth' }),
  resetForm: () => resetForm({ type: 'auth' })
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAuthenticator);
