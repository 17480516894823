import React, { useState } from 'react';
import { PageHeader, ShowIf, ShowWith } from '../fragments';
import { generatePath, useParams, useHistory } from 'react-router-dom';
import { accounts } from '../../redux/services';
import { paths } from '../../constants';

export default function MergeAccountsDirection() {
  const { accountId, archiveId } = useParams();
  const history = useHistory();
  const [activeAccount, setActiveAccount] = useState(null);
  const [archiveAccount, setArchiveAccount] = useState(null);
  const { data: active } = accounts.useGetAccountQuery(accountId);
  const { data: archived } = accounts.useGetAccountQuery(archiveId);
  const mergeAccounts = active && archived ? [active, archived] : [];

  const getArchivedAccount = value => {
    const archiveToggle = {
      [active.account_id]: archived,
      [archived.account_id]: active
    };
    return archiveToggle[value];
  };

  const setActiveColor = account_id => {
    return activeAccount === account_id ? 'active' : '';
  };

  const handle = {
    onChange: account => {
      if (activeAccount === null || account.account_id !== activeAccount) {
        setActiveAccount(+account.account_id);
        setArchiveAccount(getArchivedAccount(+account.account_id));
      } else {
        setActiveAccount(null);
        setArchiveAccount(null);
      }
    },
    onNext: () => {
      history.push(
        generatePath(paths.merge.review, {
          accountId: activeAccount,
          archiveId: getArchivedAccount(activeAccount).account_id
        })
      );
    }
  };

  return (
    <div className="merge-accounts">
      <PageHeader title="Merge Accounts" />
      <ol className="breadcrumbs">
        <li className="breadcrumb disabled">Select Accounts</li>
        <li className="breadcrumb active">How to Merge</li>
        <li className="breadcrumb disabled">Review & Finish</li>
      </ol>
      <h3 className="title">
        Select the account the information will merge into
      </h3>

      <div className="card-group">
        <ShowIf value={archived && active}>
          <ShowWith value={mergeAccounts}>
            {data =>
              Object.values(data).map(value => (
                <div
                  onClick={() => handle.onChange(value)}
                  key={value.account_id}
                  className={`card default-cursor ${setActiveColor(
                    value.account_id
                  )}`}
                >
                  <div className="account-info">
                    <p>{value.account_name}</p>
                    <p>{value.account_to_crm_id}</p>
                    <span>
                      <input
                        className="checkbox"
                        type="checkbox"
                        value={value.account_id}
                        onChange={() => handle.onChange(value)}
                        checked={activeAccount === value.account_id}
                      />
                    </span>
                  </div>
                </div>
              ))
            }
          </ShowWith>
        </ShowIf>
      </div>
      <div className="message">
        <ShowWith value={archiveAccount}>
          {data => (
            <p>
              <span>
                {data.account_name} ({data.account_to_crm_id})
              </span>{' '}
              will be archived.
            </p>
          )}
        </ShowWith>
      </div>
      <div className="mt">
        <button
          type="button"
          className="btn btn-muted btn-outline"
          disabled={!mergeAccounts}
          onClick={() => history.goBack()}
        >
          Previous
        </button>
        <button
          type="button"
          className="btn"
          onClick={handle.onNext}
          disabled={!mergeAccounts || !activeAccount}
        >
          Next
        </button>
      </div>

      <div>
        <h4></h4>
      </div>
    </div>
  );
}
