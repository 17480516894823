import React from 'react';
import PropTypes from 'prop-types';
import { MAX_ENTITLEMENT_SORT_RESULTS, SORT_ORDER } from '../../constants';
import { ShowIf } from './Show';

export default function SortableHeader({ value, text, props }) {
  const { sort, onSort, total } = props;
  const isActive = sort[0] === value;
  const direction = sort[1] === SORT_ORDER.asc ? 'up' : 'down';
  const disabled = total > MAX_ENTITLEMENT_SORT_RESULTS;

  return (
    <>
      <ShowIf value={!disabled}>
        <th className={`sortable ${isActive ? 'active-column' : ''}`}>
          <div value={value} onClick={onSort}>
            <span>{text}</span>
            <i className={isActive ? `fas fa-caret-${direction}` : ''}></i>
          </div>
        </th>
      </ShowIf>
      <ShowIf value={disabled}>
        <th
          className="sortable disabled"
          title="There are too many results to sort on this column."
        >
          {text}
        </th>
      </ShowIf>
    </>
  );
}

SortableHeader.propTypes = {
  data: PropTypes.object,
  value: PropTypes.string,
  text: PropTypes.string,
  onSort: PropTypes.func,
  sort: PropTypes.array,
  props: PropTypes.object,
  total: PropTypes.number
};
