export const messages = {
  date_order: 'End date must come after the start date.',
  date_invalid: 'Start and end dates must be valid.',
  missing_accounts: 'Please select at least one account.',
  missing_entitlements: 'Please select at least one entitlement.',
  missing_dates: 'Start and end dates are required.',
  missing_start: 'A start date is required.',
  missing_end: 'An end date is required.',
  missing_type: 'An entitlement must be perpetual or a subscription.',
  perpetual_end: 'A perpetual entitlement cannot have an end date.'
};

export function getEntitlementErrors(
  { start_date, end_date, perpetual = null } = {},
  { isPublished = false, isRenewal = false } = {}
) {
  const errors = [];
  if (perpetual) {
    // if it's perpetual, cannot have an end date
    if (!start_date && !isPublished) {
      errors.push(messages.missing_start);
    }
    if (end_date) {
      errors.push(messages.perpetual_end);
    }
  } else {
    if (perpetual === null && !isRenewal) {
      // no type; we need one if we're creating
      errors.push(messages.missing_type);
    }
    if (start_date || end_date) {
      if (!start_date && !isPublished) {
        errors.push(messages.missing_start);
      }
      if (!end_date) {
        errors.push(messages.missing_end);
      }
    } else {
      // we have neither
      errors.push(messages.missing_dates);
    }
  }

  // if we are passing dates
  if (start_date && end_date) {
    const [start, end] = [start_date, end_date].map(Date.parse);
    // make sure they're valid
    if (isNaN(start) || isNaN(end)) {
      errors.push(messages.date_invalid);
    } else if (start > end) {
      // and in the right order
      errors.push(messages.date_order);
    }
  }

  return errors;
}

export function getCreateBatchErrors(value) {
  const errors = [];
  // we don't care which format these are coming in, only that they're there
  const selected = [...value.products, ...value.isbns];

  if (!value.accounts?.length) {
    errors.push(messages.missing_accounts);
  }

  // 1+ items
  if (!selected.length) {
    errors.push(messages.missing_entitlements);
  }

  // all of these are relevant here
  errors.push(...getEntitlementErrors(value));

  return errors.length
    ? [
        'One or more validation errors were encountered and must be addressed before continuing.',
        ...errors
      ]
    : errors;
}
