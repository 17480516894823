import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Transition } from '@headlessui/react';

/**
 * # Progress
 * A component for displaying an indeterminate progress bar.
 *
 * @example
 * ```jsx
 * <Progress active />
 * ```
 */
export default function Progress(props) {
  return (
    <div className="progress-container">
      <Transition
        show={props.active}
        enter="fade"
        leave="fade"
        entered="fade in"
        enterTo="in"
        leaveTo="out"
      >
        <progress className="progress-indicator" />
      </Transition>
    </div>
  );
}

Progress.propTypes = {
  active: PropTypes.bool
};

/**
 * ## FetchProgress
 * A special Progress component that changes based on whether or not the application has fetch requests pending.
 */
const mapStateToProps = state => ({ active: state.ui.isLoading });
export const FetchProgress = connect(mapStateToProps)(Progress);
