import { createSlice } from '@reduxjs/toolkit';
import { AUTH } from '../../constants';

const getInitialState = () => {
  return {
    auth: {
      roles: [],
      auth_type: AUTH.password.id,
      blocked: false,
      reset_sqs: false
    }
  };
};

/**
 * A generic slice to handle editable forms. The most important thing to note
 * here is the presence of a "type" field that declares the key of the form
 * we're editing. This needs to be passed in any call to resetForm() or
 * setForm()—either as part of an inline call, or with a wrapper function
 * defined in `mapDispatchToProps`.
 */
const slice = createSlice({
  name: 'forms',
  initialState: getInitialState(),
  reducers: {
    /**
     * Given a form type and `data` field, reset the form to its initial state,
     * merging `data` on top of it.
     */
    resetForm: (state, action) => {
      const { type, data } = action.payload;
      state[type] = { ...getInitialState()[type], ...data };
    },
    /**
     * Given a form type and data, merge updates onto a form. This doesn't
     * overwrite the form entirely, so you can safely pass a subset of the form.
     */
    setForm: (state, action) => {
      const { type, data = {} } = action.payload;
      for (const [key, value] of Object.entries(data)) {
        state[type][key] = value;
      }
    }
  }
});

export default slice.reducer;

export const { setForm, resetForm } = slice.actions;
