import { Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  // batches
  Batch,
  Batches,
  CreateBatch,
  EditBatch,
  // misc.
  Login,
  // entitlements
  Entitlements,
  // accounts
  Account,
  Accounts,
  EditAccount,
  // authenticators
  Authenticators,
  AddAuthenticator,
  EditAuthenticator,
  Manual,
  SelectMergeAccounts,
  MergeAccountsDirection,
  FinishMergeAccounts
} from './components/views';

import { Menu, FetchProgress, ShowIf } from './components/fragments';
import { paths } from './constants';

import './App.scss';

function App(props) {
  return (
    <>
      <FetchProgress />
      <Menu />
      <main className="page-content">
        {/* public routes */}
        <ShowIf value={!props.isLoggedIn}>
          <Switch>
            <Route path={paths.login} component={Login} exact />
            <Redirect to={paths.login} />
          </Switch>
        </ShowIf>
        {/* privileged routes */}
        <ShowIf value={props.isLoggedIn}>
          <Switch>
            <Route path={paths.accounts.index} exact component={Accounts} />
            <Route path={paths.accounts.create} exact component={EditAccount} />
            <Route path={paths.accounts.edit} exact component={EditAccount} />
            <Route
              path={paths.merge.select}
              exact
              component={SelectMergeAccounts}
            />
            <Route
              path={paths.merge.direction}
              exact
              component={MergeAccountsDirection}
            />
            <Route
              path={paths.merge.review}
              exact
              component={FinishMergeAccounts}
            />
            <Route
              path={paths.authenticators.create}
              component={AddAuthenticator}
              exact
            />
            <Route path={paths.accounts.show} component={Account} />
            <Route
              path={paths.authenticators.edit}
              component={EditAuthenticator}
              exact
            />
            <Route
              path={paths.authenticators.index}
              component={Authenticators}
              exact
            />
            <Route path={paths.batches.create} component={CreateBatch} exact />
            <Route path={paths.batches.index} component={Batches} exact />
            <Route path={paths.batches.edit} component={EditBatch} exact />
            <Route path={paths.batches.show} component={Batch} exact />
            <Route
              path={paths.entitlements.index}
              component={Entitlements}
              exact
            />
            <Route path={paths.manual} component={Manual} />
            <Redirect to={paths.accounts.index} />
          </Switch>
        </ShowIf>
      </main>
    </>
  );
}

App.propTypes = {
  isLoggedIn: PropTypes.bool
};

const mapStateToProps = state => {
  return { isLoggedIn: !!state.login.token };
};

export default connect(mapStateToProps)(App);
