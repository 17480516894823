import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, useHistory, Redirect } from 'react-router-dom';

import {
  batches,
  entitlements as entitlementsService
} from '../../redux/services';
import { MessageBlock, Overlay, Search, ShowIf } from '../fragments';
import { BulkEntitlementsTable } from '../tables';
import { paths } from '../../constants';
import { AddContentForm } from '../forms';
import BulkEditEntitlements from '../tables/BulkEntitlementsTable/BulkEditEntitlements';
import { DEFAULT_VALUE } from '../fragments/EntitlementForm';

/**
 * The structure here is a little convoluted, but here's it is in a nutshell.
 * Things are technically a little more nested than this, but from a practical
 * "who answers to whom" perspective, this is how it all shakes out:
 *
 * ```jsx
 * <EditBatchContent>         // opens/closes overlay
 *   <AddContentForm>         // wrapped by `Overlay`, manages new content, client-side error validation, invokes create mutations
 *     <ContentBySearch />    // wrapped by `Tabs` component, uses `value`, `onChange` from AddContentForm
 *     <ContentByISBN />      // " "
 *     <LicenseForm />        // uses `value`, `onChange` from AddContentForm
 *   </AddContentForm>
 *   <BulkEntitlementsTable>  // wrapped by `Search` component, invokes remove/update mutations, displays items from `useGetEntitlementsQuery`
 *     <BatchEditForm />      // stores form state internally, invokes
 *     <BulkEntitlementRow />
 *   </BulkEntitlementsTable>
 * </EditBatchContent>
 * ```
 */
function EditBatchContent(props) {
  const { batchId: batch_id } = props;
  const { location } = useHistory();

  // control overlay open/closed state and toggle visibility
  const [state, setState] = useState({
    isOpen: location.state?.isOpen ?? false,
    feedback: [],
    accounts: [],
    entitlementPrefill: DEFAULT_VALUE
  });

  const [text = null, ...messages] = state.feedback;

  const handle = {
    onToggle: () => setState({ ...state, isOpen: !state.isOpen }),
    onCloseForm: (
      feedback = [],
      accounts = [],
      entitlementPrefill = DEFAULT_VALUE
    ) => {
      setState({ feedback, accounts, entitlementPrefill, isOpen: false });
    }
  };

  const batch = batches.useGetBatchQuery({ batch_id });
  const entitlements = entitlementsService.useGetEntitlementsQuery({
    batch_id
  });

  if (batch.data?.executed) {
    return <Redirect to={generatePath(paths.batches.show, { batch_id })} />;
  }

  return (
    <div>
      {/* adding new content to the batch via ISBN/search */}
      <Overlay isOpen={state.isOpen} onClose={handle.onToggle}>
        <AddContentForm
          accountId={batch.data?.account_id}
          batchId={batch_id}
          accounts={state.accounts}
          entitlementPrefill={state.entitlementPrefill}
          onClose={handle.onCloseForm}
        />
      </Overlay>
      <button className="btn my" onClick={handle.onToggle}>
        Add Products
      </button>
      <form>
        <MessageBlock
          messages={messages}
          copy={messages.length > 0}
          outline
          className={`mb msg-${messages.length ? '' : 'success'}`}
        >
          {text}
        </MessageBlock>
        <ShowIf value={batch_id}>
          <BulkEditEntitlements>
            {stateProps => (
              <Search
                params={{ batch_id }}
                query={entitlementsService.useGetEntitlementsQuery}
                table={tableProps => (
                  <BulkEntitlementsTable {...tableProps} {...stateProps} />
                )}
              />
            )}
          </BulkEditEntitlements>
        </ShowIf>
        <div className="my">
          <button
            type="button"
            className="btn"
            onClick={props.onNext}
            disabled={!entitlements.data?.count}
          >
            Next
          </button>
          <button
            type="button"
            className="btn btn-outline"
            onClick={props.onPrev}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
}

EditBatchContent.propTypes = {
  batchId: PropTypes.number,
  onNext: PropTypes.func,
  onPrev: PropTypes.func
};

export default EditBatchContent;
