import { createApi } from '@reduxjs/toolkit/query/react';
import {
  getBaseQuery,
  getItemTagger,
  getListTagger,
  getTransformResponse
} from '../../utils';

const type = 'Account';
const tagList = getListTagger(type);
const tagItem = getItemTagger(type);

/**
 * Simple handler to return a consistent response for tagging purposes.
 */
function transformAccount(response) {
  const body = response.body?.account ?? response.body;
  return { ...body, id: body.account_id };
}

const api = createApi({
  reducerPath: 'accountsAPI',
  baseQuery: getBaseQuery(),
  tagTypes: [type],
  endpoints: builder => ({
    // accounts, filterable
    getAccounts: builder.query({
      query: (params = {}) => ({
        url: '/accounts',
        params,
        requiresPage: true,
        key: 'accounts'
      }),
      transformResponse: getTransformResponse('accounts', 'account_id'),
      providesTags: tagList
    }),

    getAccountIDs: builder.query({
      query: (params = {}) => ({ url: '/accounts/ids', params }),
      transformResponse: response => response.body.accounts
    }),
    getAccount: builder.query({
      query: accountId => `/accounts/${accountId}`,
      transformResponse: response => response.body.account,
      providesTags: tagItem
    }),
    getAccountDetails: builder.query({
      query: accountId => `/accounts/${accountId}/details`,
      transformResponse: response => response.body.details
    }),
    getAccountEntitlements: builder.query({
      query: ({ accountId, ...params }) => ({
        url: `/accounts/${accountId}/entitlements`,
        params,
        requiresPage: true,
        key: 'entitlements'
      }),
      transformResponse: getTransformResponse('entitlements')
    }),
    getAccountAuthenticators: builder.query({
      query: ({ accountId, ...params }) => ({
        url: `/accounts/${accountId}/authenticators`,
        params,
        requiresPage: true,
        key: 'authenticators'
      }),
      transformResponse: getTransformResponse('authenticators')
    }),
    createAccount: builder.mutation({
      query: formData => ({
        url: `/accounts`,
        method: 'POST',
        body: formData
      }),
      transformResponse: transformAccount,
      invalidatesTags: () => [{ id: 'index', type }]
    }),
    updateAccount: builder.mutation({
      query: ({ accountId, ...body }) => ({
        url: `/accounts/${accountId}`,
        method: 'PUT',
        body
      }),
      transformResponse: transformAccount,
      invalidatesTags: res => {
        return [
          { id: 'index', type },
          { id: res.id, type }
        ];
      }
    }),
    mergeAccounts: builder.mutation({
      query: body => ({
        url: `/accounts/merge`,
        method: 'POST',
        body
      }),
      transformResponse: transformAccount,
      invalidatesTags: res => {
        return [
          { id: 'index', type },
          { id: res.id, type }
        ];
      }
    })
  })
});

export const {
  useGetAccountsQuery,
  useGetAccountQuery,
  useGetAccountIDsQuery,
  useGetAccountDetailsQuery,
  useGetAccountEntitlementsQuery,
  useCreateAccountMutation,
  useUpdateAccountMutation,
  useMergeAccountsMutation
} = api;

export default api;
