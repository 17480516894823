import React from 'react';
import { NavLink } from 'react-router-dom';

import { accounts } from '../../redux/services';
import { paths } from '../../constants';
import { PageHeader, Search } from '../fragments';
import { AccountsFilter } from '../filters';
import { AccountsTable } from '../tables';

function Accounts() {
  return (
    <>
      <PageHeader title="Accounts">
        <NavLink className="btn btn-lg" to={paths.accounts.create}>
          Add Account
        </NavLink>
      </PageHeader>
      <Search
        id="accounts"
        query={accounts.useGetAccountsQuery}
        prefill={{ archived: false, archiveCheckbox: true }}
        retainPrefill
        filter={AccountsFilter}
        table={AccountsTable}
      />
    </>
  );
}

export default Accounts;
