import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { ShowIf, SortableHeader, SortManager } from '../fragments';
import { toDateString } from '../../utils';
import { generatePath, Link } from 'react-router-dom';
import { paths } from '../../constants';

/**
 * Render a table of entitlements.
 */
function EntitlementsTable({ items = [], sort = [], setSort, total, filter }) {
  const { accountId = null } = useParams();
  return (
    <table className="table mt">
      <SortManager sort={sort} setSort={setSort} total={total}>
        {props => (
          <tr>
            {/* Only display the account if we're showing this in a context where the account is not implicitly defined in the URL. */}
            <ShowIf value={!accountId}>
              <SortableHeader
                value="account_name"
                text="Account"
                props={props}
              />
            </ShowIf>
            <th>Title</th>
            <SortableHeader
              value="item_number"
              text="Item Number"
              props={props}
            />
            <SortableHeader
              value="perpetual"
              text="License Type"
              props={props}
            />
            <SortableHeader
              value="start_date"
              text="Start Date"
              props={props}
            />
            <SortableHeader value="end_date" text="End Date" props={props} />
            <ShowIf value={!filter.batch_id}>
              <th>Batch Title</th>
            </ShowIf>
          </tr>
        )}
      </SortManager>
      <tbody>
        {items.map(({ entitlement_id: id, ...item }) => (
          <tr key={id}>
            <ShowIf value={!accountId}>
              <td>
                <Link
                  to={generatePath(paths.accounts.show, {
                    accountId: item.Account?.account_id
                  })}
                >
                  {item.Account?.account_name}
                </Link>
              </td>
            </ShowIf>
            <td>{item.complete_title}</td>
            <td>{item.isbn13 ?? '-'}</td>
            <td>{item.perpetual ? 'Perpetual' : 'Subscription'}</td>
            <td>{toDateString(item.start_date)}</td>
            <td>{toDateString(item.end_date)}</td>
            <ShowIf value={!filter.batch_id}>
              <td>
                <Link
                  to={generatePath(paths.batches.show, {
                    batch_id: item.EntitlementBatch?.entitlement_batch_id
                  })}
                >
                  {item.EntitlementBatch?.title}
                </Link>
              </td>
            </ShowIf>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

EntitlementsTable.propTypes = {
  items: PropTypes.array,
  select: PropTypes.bool,
  editable: PropTypes.bool,
  setSort: PropTypes.func,
  sort: PropTypes.array,
  total: PropTypes.number,
  filter: PropTypes.object
};

export default EntitlementsTable;
