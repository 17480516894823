import React from 'react';

import SelectRolesForm from './SelectRolesForm';
import { AuthBlockedForm, AuthTypeForm } from '../fragments';

const fields = [
  { id: 'issuer', name: 'Issuer' },
  { id: 'external_id', name: 'External ID' }
];

export default function OAuthForm() {
  return (
    <div id="oauth-form" className="user-details">
      <AuthTypeForm fields={fields}>
        <AuthBlockedForm />
        <SelectRolesForm privileged />
      </AuthTypeForm>
    </div>
  );
}
