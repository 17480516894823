import React, { useState } from 'react';

import { generatePath, Redirect, useHistory, useLocation } from 'react-router';
import { paths } from '../../constants';
import {
  Search,
  PageHeader,
  MessageBlock,
  InteractiveTable
} from '../fragments';
import { accounts, batches } from '../../redux/services';

import { AccountsFilter } from '../filters';
import { SelectAccountTable } from '../tables';
import { getErrors } from '../../utils';

function CreateBatch() {
  const history = useHistory();
  const { state: locationState } = useLocation();
  const [create, createMutation] = batches.useCreateBatchMutation();

  const [state, setState] = useState({
    // @todo: this is inconsistent across backend-frontend—I think `account_id` is preferable
    account_id: locationState?.accountId ? +locationState.accountId : null,
    account_name: locationState?.accountName ?? null,
    account_to_crm_id: locationState?.accountToCrm ?? null,
    entitlement_batch_to_crm_id: null,
    title: null
  });

  const handle = {
    onCreate: e => e?.preventDefault() ?? create(state),
    onChange: e => setState({ ...state, [e.target.name]: e.target.value })
  };

  if (createMutation.isSuccess) {
    // redirect to /batches/{:batch_id}/edit, passing along our state data
    const params = { batch_id: createMutation.data.id };
    const pathname = generatePath(paths.batches.edit, params);
    return <Redirect to={{ pathname, state: { ...state, isCreate: true } }} />;
  }

  return (
    <div id="add_entitlements">
      <PageHeader title="Create Batch" />
      <ol className="breadcrumbs">
        <li className="breadcrumb active">Select Account</li>
        <li className="breadcrumb disabled">Add Entitlements</li>
        <li className="breadcrumb disabled">Review & Finish</li>
      </ol>
      <form onSubmit={handle.onCreate}>
        <MessageBlock error messages={getErrors(createMutation.error)} />
        <div className="form-group my">
          {/* @todo: do we want to indicate visually that this field is required? */}
          <label htmlFor="title">Batch Name</label>
          <input
            id="title"
            name="title"
            type="text"
            required
            onChange={handle.onChange}
            value={state.title ?? ''}
          />
        </div>
        <div className="form-group">
          <label htmlFor="entitlement_batch_to_crm_id">External ID</label>
          <input
            id="entitlement_batch_to_crm_id"
            name="entitlement_batch_to_crm_id"
            type="text"
            onChange={handle.onChange}
            value={state.entitlement_batch_to_crm_id ?? ''}
          />
        </div>

        <Search
          id="accounts"
          query={accounts.useGetAccountsQuery}
          filter={AccountsFilter}
          clearSelection={() => setState({ ...state, account_id: null })}
          prefill={{
            account_name: state.account_name ?? '',
            account_to_crm_id: state.account_to_crm_id ?? ''
          }}
          params={{
            archived: false
          }}
          table={tableProps => (
            <InteractiveTable
              {...tableProps}
              multiple={false}
              table={SelectAccountTable}
              // @todo: update InteractiveTable to avoid the weird array wrapper thing
              selected={[state.account_id]}
              onSelect={([account_id]) => setState({ ...state, account_id })}
            />
          )}
        />

        <div className="mt">
          <button
            type="submit"
            className="btn"
            disabled={
              !(state.account_id && state.title) || createMutation.isLoading
            }
          >
            Next
          </button>
          <button
            type="button"
            className="btn btn-muted btn-outline"
            disabled={createMutation.isLoading}
            onClick={() => history.goBack()}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateBatch;
