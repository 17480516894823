import React from 'react';
import PropTypes from 'prop-types';

export default function SelectAccountTable({
  items = [],
  selected = null,
  onSelect
}) {
  return (
    <table className="table table-select">
      <thead>
        <tr>
          <th>Account Name</th>
          <th>External ID</th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <tr
            key={item.account_id}
            className={item.account_id === selected ? 'active' : ''}
            onClick={() => onSelect(+item.account_id)}
          >
            <td>{item.account_name}</td>
            <td>{item.account_to_crm_id}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

SelectAccountTable.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.number,
  onSelect: PropTypes.func
};
