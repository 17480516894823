import React from 'react';
import PropTypes from 'prop-types';
import { SORT_ORDER } from '../../constants';

export default function SortManager(props) {
  const handle = {
    onSort: e => {
      const toggle = {
        [SORT_ORDER.asc]: SORT_ORDER.desc,
        [SORT_ORDER.desc]: SORT_ORDER.asc
      };
      const current = e.target.getAttribute('value');
      const [prev, prevDirection] = props.sort;
      props.setSort(
        current === prev
          ? [current, toggle[prevDirection]]
          : [current, SORT_ORDER.asc]
      );
    }
  };

  return (
    <thead>
      {props.children({
        onSort: handle.onSort,
        sort: props.sort,
        total: props.total
      })}
    </thead>
  );
}

SortManager.propTypes = {
  children: PropTypes.func,
  onSort: PropTypes.func,
  sort: PropTypes.array,
  setSort: PropTypes.func,
  total: PropTypes.number
};
