import { createApi } from '@reduxjs/toolkit/query/react';
import {
  getBaseQuery,
  getListTagger,
  getItemTagger,
  getTransformResponse
} from '../../utils';

const tagName = 'Authenticator';
const tagList = getListTagger(tagName);
const tagItem = getItemTagger(tagName);

function transformAuthenticator(response) {
  const body = response.body?.authenticator ?? response.body;
  return { ...body, id: body.authenticator_id };
}

const api = createApi({
  reducerPath: 'authenticatorAPI',
  baseQuery: getBaseQuery(),
  tagTypes: [tagName, 'Role'],
  endpoints: builder => ({
    getRoles: builder.query({
      query: () => '/authenticators/roles',
      transformResponse: response => response.body.roles,
      providesTags: [{ id: 'index', type: 'Role' }]
    }),
    getAuthenticators: builder.query({
      query: ({ authenticator_id, ...params } = {}) => ({
        url: '/authenticators',
        params: { authenticator_id, ...params },
        requiresPage: true,
        key: 'authenticators'
      }),
      transformResponse: getTransformResponse(
        'authenticators',
        'authenticator_id'
      ),
      providesTags: tagList
    }),
    /**
     * @todo: given that the backend expects an `auth_id` to be passed on PUT,
     * it should probably return an `auth_id` on GET.
     */
    getAuthenticator: builder.query({
      query: ({ authenticator_id }) => `/authenticators/${authenticator_id}`,
      providesTags: (res, err, arg) =>
        res ? [{ id: arg.authenticator_id, type: tagName }] : [],
      transformResponse: res => res.body.authenticator
    }),
    // create a new authenticator
    createAuthenticator: builder.mutation({
      query: ({ accountId, auth_type, ...formData }) => ({
        url: `/authenticators`,
        method: 'POST',
        body: { ...formData, accountId, auth_type }
      }),
      providesTags: tagItem,
      invalidatesTags: tagList,
      transformResponse: transformAuthenticator
    }),
    // update an existing authenticator
    updateAuthenticator: builder.mutation({
      query: ({ authenticator_id, ...formData }) => ({
        url: `/authenticators/${authenticator_id}`,
        method: 'PUT',
        body: formData
      }),
      invalidatesTags: (res, err, arg) => [
        { type: tagName, id: arg.authenticator_id },
        { type: tagName, id: 'index' }
      ]
    })
  })
});

export const {
  useGetAuthenticatorQuery,
  useGetAuthenticatorsQuery,
  useLazyGetAuthenticatorQuery,
  useCreateAuthenticatorMutation,
  useUpdateAuthenticatorMutation,
  useGetRolesQuery
} = api;

export default api;
