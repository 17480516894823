import React from 'react';
import PropTypes from 'prop-types';

import { InteractiveTable } from '../../fragments';
import { useGetAccountIDsQuery } from '../../../redux/services/accounts';

import Table from './Table';

export default function SelectAccountsTable(props) {
  const query = useGetAccountIDsQuery(props.filter);

  const table = tableProps => <Table {...tableProps} ids={query.data} />;
  return <InteractiveTable {...props} table={table} />;
}

SelectAccountsTable.propTypes = {
  items: PropTypes.array,
  filter: PropTypes.object,
  isLoading: PropTypes.bool
};
