import { Address4, Address6 } from 'ip-address';

// IP address types
const types = [Address4, Address6];

const privateRanges = [
  new Address4('10.0.0.0/8'),
  new Address4('172.16.0.0/12'),
  new Address4('192.168.0.0/16'),
  new Address4('169.254.0.0/16'),
  new Address6('fe80::/10')
];

/**
 * Returns the instance of an IP Address object
 * @param {string} ip  ip address
 * @returns {Address6|Address4|null}
 */
export function toIPAddress(ip) {
  // Loop over the address types and attempt to
  // parse the string as either Address4 and Address6.
  // If the IP cannot be parsed for a type, an error is thrown and
  // the loop moves on to the next type.
  // If neither addresses can be parsed, null is returned
  for (const Address of types) {
    try {
      return new Address(ip);
    } catch {
      // no-op, check the next address type
    }
  }
  return null;
}

/**
 * Determine if two IP addresses are within an acceptable CIDR range (/16 for
 * IPv4, /64 for IPv6), returning null if invalid data is passed.
 * @param {string} start starting IP address
 * @param {string} end ending IP address
 * @returns {boolean|null} null will throw a validation error, false will show a warning dialog, true will allow creation
 */
export function isValidIPRange(...addresses) {
  const [starting, ending] = addresses.map(toIPAddress);

  if (!(starting && ending) || starting.v4 !== ending.v4) {
    return null;
  }

  const mask = starting.v4 ? 16 : 64;

  // Check if starting and ending IP values overlap the private ranges
  // If overlap occurs, return with null immediately to throw validation error
  for (const address of privateRanges) {
    if (starting.isInSubnet(address) || ending.isInSubnet(address)) {
      return null;
    }
  }
  // determine if the CIDR range matches / false throws warning dialog
  return starting.mask(mask) === ending.mask(mask);
}
