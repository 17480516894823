import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { paths } from '../../constants';
import { entitlements } from '../../redux/services';
import { PageHeader, Search } from '../fragments';
import { EntitlementsFilter } from '../filters';
import { BulkEntitlementsTable, EntitlementsTable } from '../tables';
import BulkEditEntitlements from '../tables/BulkEntitlementsTable/BulkEditEntitlements';

export default function Entitlements() {
  const [editable, setEditable] = useState(false);
  const Table = editable ? BulkEntitlementsTable : EntitlementsTable;

  return (
    <div className="entitlements">
      <PageHeader title="Entitlements">
        <Link className="btn btn-lg" to={paths.batches.create}>
          Add Entitlements
        </Link>
      </PageHeader>
      <BulkEditEntitlements>
        {stateProps => (
          <Search
            params={{ active: true }}
            query={entitlements.useGetEntitlementsQuery}
            filter={EntitlementsFilter}
            table={tableProps => (
              <div>
                <button
                  type="button"
                  className="btn"
                  onClick={() => {
                    stateProps.onSelect([]);
                    setEditable(!editable);
                  }}
                >
                  {editable ? 'Done Editing' : 'Edit Entitlements'}
                </button>
                <Table {...tableProps} {...stateProps} alternate />
              </div>
            )}
          />
        )}
      </BulkEditEntitlements>
    </div>
  );
}
