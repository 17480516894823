import React from 'react';
import PropTypes from 'prop-types';
import { ShowIf } from '../../fragments';

export default function Table({
  ids = [],
  items = [],
  selected = [],
  allSelected: allShownSelected,
  onSelect,
  onSelectAll
}) {
  const handle = {
    // literally all of them
    onSelectAll: () => onSelectAll(ids),
    onDeselectAll: () => onSelectAll([]),
    // just the visible ones
    onSelectShown: () => {
      const all = new Set(selected);
      for (const item of items) {
        all.add(item.id);
      }
      onSelectAll(Array.from(all));
    },
    onDeselectShown: () => {
      const all = new Set(selected);
      for (const item of items) {
        all.delete(item.id);
      }
      onSelectAll(Array.from(all));
    }
  };

  // is every single ID selected—'allSelected' has been aliased to 'allShownSelected'
  const allSelected = ids.length === selected.length;

  // I don't like inline ternaries in every onClick handler, so we'll determine which fns need to be called here
  const toggle = {
    shown: allShownSelected ? handle.onDeselectShown : handle.onSelectShown,
    all: allSelected ? handle.onDeselectAll : handle.onSelectAll
  };

  const text = {
    count: `${selected.length} total items selected. `,
    deselectAll: `Deselect all ${selected.length} items?`,
    selectAll: `Select all ${ids.length} items?`
  };

  return (
    <>
      <div className="select-all pl ml text-small">
        {text.count}
        <ShowIf value={ids.length > items.length}>
          <a
            role="button"
            href="#"
            title="Select all results"
            onClick={toggle.all}
          >
            {allSelected ? text.deselectAll : text.selectAll}
          </a>
        </ShowIf>
      </div>

      <table className="table table-select">
        <thead>
          <tr>
            <th className="checkbox">
              <input
                type="checkbox"
                checked={allShownSelected}
                onChange={toggle.shown}
              />
            </th>
            <th>Account Name</th>
            <th>External ID</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.id} onClick={() => onSelect(item.id)}>
              <td className="checkbox">
                <input
                  type="checkbox"
                  checked={selected.includes(item.id)}
                  readOnly
                />
              </td>
              <td>{item.account_name}</td>
              <td>{item.account_to_crm_id}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

Table.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.array,
  onSelect: PropTypes.func,
  allSelected: PropTypes.bool,
  onSelectAll: PropTypes.func,
  ids: PropTypes.array
};
