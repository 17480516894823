import React from 'react';
import { NavLink } from 'react-router-dom';

import { batches } from '../../redux/services';
import { paths } from '../../constants';
import { PageHeader, Search } from '../fragments';
import { BatchesFilter } from '../filters';
import { BatchesTable } from '../tables';

function Batches() {
  return (
    <>
      <PageHeader title="Batches">
        <NavLink className="btn btn-lg" to={paths.batches.create}>
          Add Batch
        </NavLink>
      </PageHeader>
      <Search
        id="batches"
        query={batches.useGetBatchesQuery}
        prefill={{ complete: false }}
        filter={BatchesFilter}
        table={BatchesTable}
      />
    </>
  );
}

export default Batches;
