import { createSlice } from '@reduxjs/toolkit';
import loginAPI from '../services/login';

import { KEY_USER, KEY_TOKEN, KEY_EXPIRES } from '../../constants';

const slice = createSlice({
  name: 'login',
  initialState: {
    // pull values from local storage if available
    token: localStorage.getItem(KEY_TOKEN) ?? null,
    user: localStorage.getItem(KEY_USER) ?? null,
    expires: localStorage.getItem(KEY_EXPIRES) ?? null
  },
  reducers: {
    logOut: state => {
      // update redux state
      state.user = null;
      state.token = null;

      // clear local storage
      localStorage.removeItem(KEY_TOKEN);
      localStorage.removeItem(KEY_USER);
      localStorage.removeItem(KEY_EXPIRES);

      // note that we aren't nullifying the expiration so we can check to see if
      // that's why were logged out. however, since we're removing the
      // expiration from local storage, the expiration will be initialized as
      // null after a reload and the message will disappear
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      // once we've received a response from our login mutation with a token, update the store accordingly
      loginAPI.endpoints.login.matchFulfilled,
      (state, action) => {
        // update the redux state
        state.user = action.meta.arg.originalArgs.username;
        state.token = action.payload.body.token;
        state.expires = action.payload.body.expires;

        // ...and update local storage
        localStorage.setItem(KEY_TOKEN, state.token);
        localStorage.setItem(KEY_USER, state.user);
        localStorage.setItem(KEY_EXPIRES, state.expires);
      }
    );
  }
});

export default slice.reducer;

export const { logOut } = slice.actions;
