import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { connections } from '../../redux/services';
import { AddConnectionsForm } from '../forms';
import { Search, Overlay } from '../fragments';
import { ConnectionsFilter } from '../filters';
import { ConnectionsTable } from '../tables';

export default function AccountConnections() {
  const { accountId } = useParams();
  const { state } = useLocation();
  // router state variable to set an initial open value for the overlay
  // @todo: persistent across reloads...
  const [isOpen, setIsOpen] = useState(state?.create ?? false);
  const onToggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Overlay isOpen={isOpen} onClose={onToggle}>
        <AddConnectionsForm onClose={onToggle} />
      </Overlay>
      <Search
        id="connections"
        query={connections.useGetConnectionsQuery}
        params={{ accountId }}
        prefill={{ archived: false, archiveCheckbox: true }}
        retainPrefill
        filter={filterProps => (
          <>
            <ConnectionsFilter {...filterProps} />
            <div className="py">
              <button type="button" className="btn" onClick={onToggle}>
                Add Connections
              </button>
            </div>
          </>
        )}
        table={ConnectionsTable}
      />
    </div>
  );
}
