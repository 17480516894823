import React from 'react';
import PropTypes from 'prop-types';
import TableRow from './TableRow';
import { SortManager, SortableHeader } from '../../fragments';

export default function Table({ sort = [], ...props }) {
  return (
    <table className="my table">
      <SortManager sort={sort} setSort={props.setSort} total={props.total}>
        {sortProps => (
          <tr>
            <th className="checkbox">
              <input
                type="checkbox"
                onChange={props.onSelectAll}
                checked={props.allSelected}
              />
            </th>

            <SortableHeader
              value="account_name"
              text="Account"
              props={sortProps}
            />

            <th>Title</th>
            <SortableHeader
              value="item_number"
              text="Item Number"
              props={sortProps}
            />
            <SortableHeader
              value="perpetual"
              text="License Type"
              props={sortProps}
            />
            <SortableHeader
              value="start_date"
              text="Start Date"
              props={sortProps}
            />
            <SortableHeader
              value="end_date"
              text="End Date"
              props={sortProps}
            />
          </tr>
        )}
      </SortManager>
      <tbody>
        {props.items.map(({ entitlement_id: id, ...item }, i) => (
          <TableRow
            key={i}
            value={item}
            selectable
            selected={props.selected.includes(id)}
            disabled={props.disabled}
            onSelect={props.onSelect}
            onRemove={props.onRemove}
            onSubmit={props.onUpdate}
          />
        ))}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.array,
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  allSelected: PropTypes.bool,
  ids: PropTypes.array,
  sort: PropTypes.array,
  setSort: PropTypes.func,
  total: PropTypes.number
};
