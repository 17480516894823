import addBatchBtn from './addBatchBtn.png';
import editBatchBtn from './editBatchBtn.png';
import batchDetail from './batchDetail.png';
import batchDetailDraft from './batchDetailDraft.png';
import batchDetailLink from './batchDetailLink.png';
import batchDetailLink2 from './batchDetailLink2.png';
import createRenewalBatchBtn from './createRenewalBatchBtn.png';
import editBatch from './editBatch.png';
import batches from './batches.png';
import createBatchForm from './createBatchForm.png';
import renewBatchBtn from './renewBatchBtn.png';
import renewBatchOverlay from './renewBatchOverlay.png';

export default {
  addBatchBtn,
  batchDetail,
  batchDetailDraft,
  batchDetailLink,
  batchDetailLink2,
  batches,
  createBatchForm,
  createRenewalBatchBtn,
  editBatch,
  editBatchBtn,
  renewBatchBtn,
  renewBatchOverlay
};
