import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ShowWith, ShowIf } from './Show';

/**
 * A component for listing an arbitrary number of string `messages`, along with
 * child content. If more than `max` (optional, default 3) messages are passed,
 * the component truncates the list and renders a link to display the additional
 * messages.
 *
 * - `outline` (boolean?) - displays an outlined message block
 * - `className` (string?) - allows visual presentation to be customized further
 * - `error` (boolean?) - enables appropriate styling and "scroll to message" behavior
 *
 * @example
 * ```jsx
 * const messages = ['A', 'B', 'C', 'D'];
 *
 * <MessageBlock messages={messages} max={4} outline>
 *   <p>Messages A, B, C and D are visible.</p>
 *   <p>This message block is outlined.</p>
 * </MessageBlock>
 *
 * <MessageBlock error messages={messages}>
 *   <p>Only messages A, B and C are visible.</p>
 *   <p>Upon clicking "see more," D will also be displayed.</p>
 *   <p>This message block is solid and error-themed (i.e., red).</p>
 * </MessageBlock>
 * ```
 */
export default function MessageBlock(props) {
  const messages = props.messages ?? [];
  const [count, max] = [messages.length, props.max ?? 3];
  const isTruncated = count > max;
  const [expanded, setExpanded] = useState(!isTruncated);
  const ref = useRef(null);

  const classNames = [
    'msg',
    props.error ? 'msg-error' : '',
    props.className ?? '',
    props.outline ? 'msg-outline' : ''
  ];

  // if the message list is empty, we'll treat it as null
  const truncated = count ? messages.slice(0, expanded ? count : max) : null;

  const handle = {
    onCopy: () => navigator.clipboard.writeText(messages.join('\n')),
    onToggle: () => setExpanded(!expanded)
  };

  useEffect(() => {
    if (ref.current && props.error && (messages.length || props.children)) {
      ref.current.scrollIntoView?.({ behavior: 'smooth' });
    }
  }, [...messages, props.children]);

  return (
    <ShowIf value={props.children || count > 0}>
      {/* giving the ref padding gives us a little breathing room after scrolling */}
      <div ref={ref} className={props.error ? 'py' : ''}>
        <div className={classNames.join(' ')}>
          {/* if defined, show message block content, as passed by the user */}
          <ShowWith value={props.children}>
            {children => <div className="message-content">{children}</div>}
          </ShowWith>
          {/* we only want to show the list element, expand link or button if messages are defined */}
          <ShowWith value={truncated}>
            {msgs => (
              <>
                <ol className="message-list">
                  {msgs.map((text, i) => (
                    <li key={i}>{text}</li>
                  ))}
                </ol>
                <div className="flex">
                  <ShowIf value={isTruncated}>
                    <button
                      type="button"
                      className="btn mt"
                      onClick={handle.onToggle}
                    >
                      Show {expanded ? 'Fewer' : 'More'}&hellip;
                    </button>
                  </ShowIf>
                  {/* only offer the option to copy to clipboard if it's supported */}
                  <ShowIf value={navigator.clipboard && props.copy}>
                    <button
                      type="button"
                      // show a normal button if it's the first child
                      className={isTruncated ? 'btn btn-outline mt' : 'btn mt'}
                      onClick={handle.onCopy}
                    >
                      Copy Errors
                    </button>
                  </ShowIf>
                </div>
              </>
            )}
          </ShowWith>
        </div>
      </div>
    </ShowIf>
  );
}

MessageBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.bool,
  copy: PropTypes.bool,
  max: PropTypes.number,
  messages: PropTypes.arrayOf(PropTypes.string),
  outline: PropTypes.bool
};
