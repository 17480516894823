import accounts from './accounts.png';
import accountAuthenticators from './accountAuthenticators.png';
import accountBatches from './accountBatches.png';
import accountEntitlements from './accountEntitlements.png';
import accountConnections from './accountConnections.png';
import editAccount from './editAccount.png';
import addAccount from './addAccount.png';
import accountRow from './accountRow.jpg';
import addAccountBtn from './addAccountBtn.jpg';
import archiveAccountBtn from './archiveAccountBtn.jpg';
import editAccountBtn from './editAccountBtn.jpg';
import restoreAccountBtn from './restoreAccountBtn.jpg';
import saveAccountBtn from './saveAccountBtn.jpg';
import authenticatorsTab from './authenticatorsTab.jpg';
import batchesTab from './batchesTab.jpg';
import connectionsTab from './connectionsTab.jpg';
import entitlementsTab from './entitlementsTab.jpg';
import mergeAccountBtn from './mergeAccountBtn.png';
import showArchivedCheckbox from './showArchivedCheckbox.png';

export default {
  accounts,
  accountAuthenticators,
  accountBatches,
  accountEntitlements,
  accountConnections,
  addAccount,
  editAccount,
  accountRow,
  addAccountBtn,
  archiveAccountBtn,
  editAccountBtn,
  restoreAccountBtn,
  saveAccountBtn,
  authenticatorsTab,
  batchesTab,
  connectionsTab,
  entitlementsTab,
  mergeAccountBtn,
  showArchivedCheckbox
};
