import React from 'react';
import PropTypes from 'prop-types';

import { toDateString } from '../../../utils';

export default function BulkEntitlementRow(props) {
  return (
    <tr onClick={() => props.onSelect(props.value.id)}>
      <td className="checkbox">
        <input
          type="checkbox"
          name="selected"
          onChange={() => props.onSelect(props.value.id)}
          checked={props.selected}
        />
      </td>
      <td>{props.value.Account?.account_name}</td>
      <td>{props.value.complete_title}</td>
      <td>{props.value.isbn13 ?? '-'}</td>
      <td>{props.value.perpetual ? 'Perpetual' : 'Subscription'}</td>
      <td>{toDateString(props.value.start_date)}</td>
      <td>{toDateString(props.value.end_date)}</td>
    </tr>
  );
}

BulkEntitlementRow.propTypes = {
  value: PropTypes.object,
  selectable: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  onSubmit: PropTypes.func
};
