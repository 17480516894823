import { createSlice } from '@reduxjs/toolkit';
import authenticatorAPI from '../services/authenticators';
const slice = createSlice({
  name: 'authenticator',
  initialState: {
    privileged_roles: null,
    record: null
  },

  extraReducers: builder => {
    builder.addMatcher(
      authenticatorAPI.endpoints.getRoles.matchFulfilled,
      (state, action) => {
        state.privileged_roles = action.payload
          .filter(role => role.privileged)
          .map(role => role.role_id);
      }
    );
    builder.addMatcher(
      authenticatorAPI.endpoints.getAuthenticator.matchFulfilled,
      (state, action) => {
        state.record = action.payload;
      }
    );
  }
});

export default slice.reducer;
