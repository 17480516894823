import PropTypes from 'prop-types';

/**
 * Helper component to render child content (either a function or React node)
 * when a value is not nullish. If a function is passed, the passed parameter is
 * the resolved, non-nullish value. If you attempt to use the value within
 * children instead of a function, it _may not be defined_.
 *
 * @example
 * ```jsx
 * <ShowIf value={myValue}>
 *   {value => <div>{value}</div>}
 * </ShowIf>
 * ```
 */
export function ShowWith(props) {
  if ((props.value ?? null) !== null) {
    return typeof props.children === 'function'
      ? props.children(props.value)
      : props.children;
  }
  return null;
}

/**
 * Helper component to render child content (either a function or React node)
 * when a value is not falsy. If a function is passed, the passed parameter is
 * the resolved, non-falsy value.
 *
 *  * @example
 * ```jsx
 * <ShowIf value={myValue}>
 *   <div>{value}</div>
 * </ShowIf>
 * ```
 */
export function ShowIf(props) {
  if (props.value) {
    return typeof props.children === 'function'
      ? props.children(props.value)
      : props.children;
  }
  return null;
}

ShowWith.propTypes = ShowIf.propTypes = {
  value: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
};
