import React from 'react';
import { Link } from 'react-router-dom';

import { authenticators } from '../../redux/services';
import { paths } from '../../constants';
import { PageHeader, Search } from '../fragments';
import { AuthenticatorsFilter } from '../filters';
import { AuthenticatorsTable } from '../tables';

function Authenticators() {
  return (
    <div className="authenticators">
      <PageHeader title="Authenticators">
        <Link className="btn btn-lg" to={paths.authenticators.create}>
          Add Authenticator
        </Link>
      </PageHeader>
      <Search
        query={authenticators.useGetAuthenticatorsQuery}
        filter={AuthenticatorsFilter}
        prefill={{ auth_type: 'password' }}
        table={AuthenticatorsTable}
      />
    </div>
  );
}

export default Authenticators;
