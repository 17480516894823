import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toDateString, getErrors } from '../../utils';
import { generatePath, useParams } from 'react-router';
import { paths } from '../../constants';
import { Link } from 'react-router-dom';
import { MessageBlock, ShowIf, Dialog } from '../fragments';
import { useDeleteBatchMutation } from '../../redux/services/batches';

const DEFAULT_STATE = {
  isOpen: false,
  batch_id: null,
  batch_title: null,
  batch_external_id: null
};

function BatchesTable(props) {
  const hasImplicitAccount = !!useParams().accountId;
  const [remove, removeMutation] = useDeleteBatchMutation();

  const [state, setState] = useState(DEFAULT_STATE);

  const handle = {
    openDialog: (batch_id, batch_title, batch_external_id) => {
      setState({ batch_id, batch_title, batch_external_id, isOpen: true });
    },
    onConfirm: () => {
      remove(state.batch_id).then(() => setState(DEFAULT_STATE));
    },
    onCancel: () => {
      setState({ ...state, isOpen: false });
    }
  };

  return (
    <>
      <Dialog
        title="Warning"
        isOpen={state.isOpen}
        onConfirm={handle.onConfirm}
        onClose={handle.onCancel}
      >
        <div className="dialog-message">
          <p>You are about to permanently delete this batch:</p>
          <p className="bold">
            {state.batch_title} -{' '}
            {state.batch_external_id ?? '(No External ID)'}
          </p>
          <p> This cannot be undone.</p>
        </div>
      </Dialog>
      <MessageBlock error messages={[...getErrors(removeMutation?.error)]} />
      <table className="table">
        <thead>
          <tr>
            <th>Batch Title</th>
            <ShowIf value={!hasImplicitAccount}>
              <th>Account</th>
            </ShowIf>
            <th>External ID</th>
            <th>Created On</th>
            <th>Published</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {props.items.map(batch => {
            const hasTitle = (batch.title ?? null) !== null;
            return (
              <tr key={batch.id}>
                <td>
                  <Link
                    className={hasTitle ? '' : 'text-italic'}
                    to={generatePath(paths.batches.show, {
                      batch_id: batch.id
                    })}
                  >
                    {batch.title ?? 'Untitled'}
                  </Link>
                </td>
                <ShowIf value={!hasImplicitAccount}>
                  <td>
                    <Link
                      to={generatePath(paths.accounts.show, {
                        accountId: batch.Account?.account_id
                      })}
                    >
                      {batch.Account?.account_name}
                    </Link>
                  </td>
                </ShowIf>
                <td>{batch.entitlement_batch_to_crm_id ?? '-'}</td>
                <td>{toDateString(batch.created_at)}</td>
                <td>
                  <i
                    title={batch.executed ? 'published' : 'draft'}
                    className={`far ${
                      batch.executed ? 'fa-check-circle' : 'fa-times-circle'
                    }`}
                  />
                </td>
                <td>
                  <ShowIf value={!batch.executed}>
                    <button
                      title="Delete"
                      type="button"
                      className="btn btn-icon btn-error"
                      onClick={() =>
                        handle.openDialog(
                          batch.id,
                          batch.title,
                          batch.entitlement_batch_to_crm_id
                        )
                      }
                    >
                      <i className="fa fa-minus-circle" />
                    </button>
                  </ShowIf>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

BatchesTable.propTypes = {
  items: PropTypes.array
};

export default BatchesTable;
