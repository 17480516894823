import React from 'react';
import PropTypes from 'prop-types';
import { ShowIf } from '../fragments';

function AccountsFilter({
  onChange,
  onReset,
  value,
  children,
  header = true,
  clearButton = true
}) {
  return (
    <div className="my">
      {children ? (
        children
      ) : (
        <ShowIf value={header}>
          <h4>Filters</h4>
        </ShowIf>
      )}
      <div className="form">
        <div className="flex">
          <div className="form-group">
            <label htmlFor="account_name">Account Name</label>
            <input
              id="account_name"
              name="account_name"
              type="text"
              onChange={onChange}
              value={value.account_name ?? ''}
            />
          </div>
          <div className="form-group">
            <label htmlFor="account_to_crm_id">External ID</label>
            <input
              id="account_to_crm_id"
              name="account_to_crm_id"
              type="text"
              onChange={onChange}
              value={value.account_to_crm_id ?? ''}
            />
          </div>
          {/* @todo
          <div className="form-group checkbox">
            <label htmlFor="show_sub_accounts">
              Show sub accounts
              <input
                id="show_sub_accounts"
                name="show_sub_accounts"
                type="checkbox"
                onChange={onChange}
                checked={value.showSubAccounts ?? false}
              />
            </label>
          </div>
          */}
          <ShowIf value={clearButton}>
            <button
              type="button"
              onClick={onReset}
              className="btn btn-outline btn-muted"
            >
              Clear Filter
            </button>
          </ShowIf>
        </div>
      </div>
      <ShowIf value={value.archiveCheckbox}>
        <div className="form-group checkbox">
          <label htmlFor="archived">
            Show Archived Accounts
            <input
              id="archived"
              name="archived"
              type="checkbox"
              onChange={onChange}
              checked={value.archived ?? false}
            />
          </label>
        </div>
      </ShowIf>
    </div>
  );
}

AccountsFilter.propTypes = {
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  value: PropTypes.object,
  children: PropTypes.node,
  header: PropTypes.bool,
  clearButton: PropTypes.bool
};

export default AccountsFilter;
