import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setForm } from '../../redux/slices/forms';
import { Dialog, ShowWith } from '.';
import { SQS_TEMPLATE_URL } from '../../constants';
import useClipboard from '../hooks/useClipboard';
function ResetSQSForm({ form, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [locked, setLocked] = useState(false);
  const [regenerated, setRegenerated] = useState(false);

  const { clearCopySuccess, copy: Copy, message: CopyMessage } = useClipboard();

  useEffect(() => {
    if (form.isUpdated) {
      // Determine whether the SQS code has been regenerated based on checkbox state at time of update
      setRegenerated(form.reset_sqs);
    }

    if (props.privileged_roles && form.roles && props.authentication) {
      // Determine whether the user has selected a privileged set of roles
      const privilegedSelection = form.roles.some(role =>
        props.privileged_roles.includes(role)
      );

      // Set status on the form for the server to use when updating the record
      props.setForm({
        privileged: privilegedSelection
      });

      // Automatically set privileged selections to reset_sqs = false
      if (privilegedSelection) {
        props.setForm({ reset_sqs: false });
      } else if (!props.authentication.sqs) {
        // Lock the reset sqs checkbox to checked when there is a non privileged selection and the original record has no stored SQS code (i.e. the users previous role was privileged)
        setLocked(true);
        props.setForm({ reset_sqs: true });
      }

      // Disable the checkbox if the the user selects a privileged role or if no roles are selected
      setDisabled(privilegedSelection || !form.roles.length);

      // Set the dialog to open if the SQS code has been regenerated and exists on the db_record
      setIsOpen(regenerated);
    }
  }, [form, props.privileged_roles, props.authentication]);

  const handle = {
    onCancel: () => {
      setIsOpen(false);
      setLocked(false);
      setRegenerated(false);
      clearCopySuccess();
      props.setForm({ isUpdated: false, reset_sqs: false });
    },
    onChange: e => {
      props.setForm({
        ...form,
        reset_sqs: e.target.checked,
        isUpdated: false
      });
    }
  };

  return (
    <>
      <ShowWith value={props.authentication.sqs}>
        {sqs => (
          <Dialog isOpen={isOpen} onClose={() => handle.onCancel()} noAction>
            <div className="form-group reset-sqs-dialog">
              <CopyMessage />
              <table>
                <tbody>
                  <tr>
                    <td>New SQS Code</td>
                    <td>{sqs}</td>
                  </tr>
                  <tr>
                    <td>Login Link</td>
                    <td>
                      <Copy
                        data="Login Link"
                        text={SQS_TEMPLATE_URL.replace(':sqs', sqs)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Dialog>
        )}
      </ShowWith>

      <div className={`reset-sqs-form ${disabled ? 'text-muted' : ''}`}>
        <label>SQS Code</label>
        <div className="form-group checkbox">
          <label htmlFor="input-reset-sqs">
            Regenerate SQS Code
            <input
              type="checkbox"
              id="input-reset-sqs"
              name="reset_sqs"
              onChange={handle.onChange}
              checked={(locked || form.reset_sqs) && !disabled}
              disabled={disabled}
            ></input>
          </label>
        </div>
      </div>
    </>
  );
}

ResetSQSForm.propTypes = {
  setForm: PropTypes.func,
  form: PropTypes.object,
  privileged_roles: PropTypes.array,
  authentication: PropTypes.object
};

const mapStateToProps = state => ({
  form: state.forms.auth,
  privileged_roles: state.authenticator.privileged_roles,
  authentication: state.authenticator.record.authentication
});
const mapDispatchToProps = {
  setForm: data => setForm({ data, type: 'auth' })
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetSQSForm);
