import React, { Children } from 'react';
import PropTypes from 'prop-types';

export function Tab(props) {
  return props.children ?? React.createElement(props.content);
}

Tab.propTypes = {
  title: PropTypes.string,
  content: PropTypes.elementType,
  children: PropTypes.object
};

/**
 * A simple component to render a tab bar with arbitrary content. Expects one or
 * more Tab children, each with a `content` prop containing a component type
 * (not instance, to avoid unnecessary renders). Any inactive tabs are filtered
 * out, while any non-tab children are rendered in order.
 *
 * @example
 * ```jsx
 * const [tab, changeTab] = useState("1");
 *
 * const tabs = (
 *   <Tabs selectedId={tab} onChange={changeTab}>
 *     <Tab title="One" id="1" content={() => <div>One</div>} />
 *     <p>random content</p>
 *     <Tab title="Two" id="2" content={() => <div>Two</div>} />
 *   </Tabs>
 * );
 *
 * const output = (
 *   <>
 *     <div>One</div>
 *     <p>random content</p>
 *   </>
 * )
 * ```
 */
export default function Tabs(props) {
  // simple helper function to add "active" class when necessary
  const getClassName = id => `tab${id === props.selectedId ? ' active' : ''}`;
  // we're going to need to use JS array methods on these
  const children = Children.toArray(props.children);

  return (
    <>
      {/* render the tab bar, highlighting the active tab */}
      <nav className="tabs">
        {children
          .filter(child => child.type === Tab)
          .map(({ props: tab }) => (
            <button
              key={tab.id}
              className={getClassName(tab.id)}
              onClick={() => props.onChangeTab(tab.id)}
              type="button"
            >
              {tab.title}
            </button>
          ))}
      </nav>
      {/* render children, filtering out any inactive tabs */}
      {children.filter(
        child => child.type !== Tab || child.props.id === props.selectedId
      )}
    </>
  );
}

Tabs.propTypes = {
  selectedId: PropTypes.string,
  onChangeTab: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.element)
};
