import login from './login';
import forms from './forms';
import ui from './ui';
import authenticator from './authenticator';

/**
 * One of RTK's core concepts is the slice, a self-contained chunk of state that
 * generates state, actions and a reducer with much less boilerplate. Each slice
 * corresponds to a discrete piece of application functionality.
 *
 * Here, the `login` slice handles auth state and login/logout actions.
 */

export default { login, forms, ui, authenticator };
