import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SelectRolesForm from './SelectRolesForm';
import { AuthBlockedForm, AuthTypeForm, PasswordInput } from '../fragments';
import { setForm } from '../../redux/slices/forms';

const fields = [{ id: 'application', name: 'Tool ID' }];

function LTIAuthForm(props) {
  return (
    <div id="lti-auth-form" className="user-details">
      <AuthTypeForm fields={fields}>
        <PasswordInput
          label="Tool Password"
          value={props.form.token ?? ''}
          onChange={token => props.setForm({ ...props.form, token })}
          options={{ strict: true, length: 32 }}
        />
        <AuthBlockedForm />
        <SelectRolesForm />
      </AuthTypeForm>
    </div>
  );
}

LTIAuthForm.propTypes = {
  setForm: PropTypes.func,
  errors: PropTypes.array,
  form: PropTypes.object
};

const mapStateToProps = state => ({ form: state.forms.auth });
const mapDispatchToProps = {
  setForm: data => setForm({ data, type: 'auth' })
};

export default connect(mapStateToProps, mapDispatchToProps)(LTIAuthForm);
