import PropTypes from 'prop-types';
import React from 'react';
import MessageBlock from './MessageBlock';
import { clamp, populate } from '../../utils';
import { ShowIf } from './Show';

/**
 * Buttons and handlers for basic table pagination, including first/last,
 * prev/next and a page selector. Defaults to page 1, with 25 items per page and
 * 0 results.
 * @todo discuss proper icons with Tracey
 */
export default function Paginator(props) {
  const page = props.page ?? 1;
  const perPage = props.perPage ?? 50;
  const total = props.total ?? 0;
  const maxPage = Math.ceil(total / perPage);

  // given a value, returns a clamped page change handler
  const onPageChange = value => () =>
    props.onPageChange?.(clamp(value, 1, maxPage));

  // button navigation handlers
  const change = {
    first: onPageChange(1),
    last: onPageChange(maxPage),
    prev: onPageChange(page - 1),
    next: onPageChange(page + 1)
  };

  // disable prev/next buttons depending on the current page
  const disabled = {
    prev: page === 1,
    next: page === maxPage
  };

  return (
    <>
      {/* Nesting our table within the paginator is purely a style choice. */}
      {props.children}
      {/* Only show "no results" if we have no results. */}
      <ShowIf value={total === 0}>
        <MessageBlock className="mt" outline>
          Your search returned no results.
        </MessageBlock>
      </ShowIf>
      {/* Only show the paginator if we have results. */}
      <ShowIf value={total > 0}>
        <nav className="paginator mt">
          {/* prev. buttons */}
          <div className="prev">
            <button
              title="First"
              className="btn btn-icon btn-muted"
              onClick={change.first}
              disabled={disabled.prev}
            >
              <i className="fas fa-angle-double-left"></i>
            </button>
            <button
              title="Previous"
              className="btn btn-icon btn-muted"
              onClick={change.prev}
              disabled={disabled.prev}
            >
              <i className="fas fa-angle-left"></i>
            </button>
          </div>
          {/* to-page navigation */}
          <select
            role="navigation"
            title="Page"
            onChange={e => props.onPageChange(+e.target.value)}
            value={page}
          >
            {populate(page, maxPage, total).map(value => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
          {/* next buttons */}
          <div className="next">
            <button
              type="button"
              title="Next"
              className="btn btn-icon btn-muted"
              onClick={change.next}
              disabled={disabled.next}
            >
              <i className="fas fa-angle-right"></i>
            </button>
            <button
              type="button"
              title="Last"
              className="btn btn-icon btn-muted"
              onClick={change.last}
              disabled={disabled.next}
            >
              <i className="fas fa-angle-double-right"></i>
            </button>
          </div>
        </nav>
      </ShowIf>
    </>
  );
}

Paginator.propTypes = {
  children: PropTypes.node,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  perPage: PropTypes.number,
  total: PropTypes.number
};
