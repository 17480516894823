import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from '../../utils';

const api = createApi({
  reducerPath: 'loginAPI',
  baseQuery: getBaseQuery(),
  endpoints: builder => ({
    login: builder.mutation({
      query: body => ({ url: `/token`, method: 'POST', body })
    })
  })
});

export const { useLoginMutation } = api;

export default api;
