import React from 'react';

import PropTypes from 'prop-types';

import { generatePath, useParams } from 'react-router';
import { connections } from '../../redux/services';
import { Link } from 'react-router-dom';
import { paths } from '../../constants';
import { ShowIf } from '../fragments';

export default function ConnectionsTable(props) {
  const { accountId } = useParams();
  const [remove, mutation] = connections.useRemoveConnectionMutation();

  return (
    <table className="table connections">
      <thead>
        <tr>
          <th></th>
          <th>Relationship</th>
          <th>Account Name</th>
          <th>External ID</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.items.map((item, i) => {
          const [parent, child] = item.source
            ? [item.account_id, accountId]
            : [accountId, item.account_id];

          const id = `${parent}-${child}`;
          const isPending =
            // @todo: the record briefly flashes back from disabled between the request completing and the query reloading.
            // if we re-use the joint PK, a re-added pair will be disabled if the mutation hasn't been reset.
            mutation.isLoading && mutation.originalArgs?.id === id;

          const icon = item.direct ? 'angle' : 'angle-double';
          const dir = item.source ? 'up' : 'down';

          return (
            <tr key={i} className={isPending ? 'disabled' : ''}>
              <td className="archived-column">
                <i
                  title="Archived"
                  className={item.archived ? 'fas fa-archive' : ''}
                ></i>
              </td>
              <td>
                <i className={`fa fa-${icon}-${dir}`} />
              </td>
              <td>
                <Link
                  to={generatePath(paths.accounts.show, {
                    accountId: item.account_id
                  })}
                >
                  {item.account_name}
                </Link>
              </td>
              <td>{item.account_to_crm_id}</td>
              <td>
                <ShowIf value={item.direct}>
                  <button
                    className="btn btn-icon btn-error"
                    disabled={isPending}
                    onClick={() =>
                      remove({ id, accountId: parent, targetId: child })
                    }
                  >
                    <i className="fa fa-minus-circle" />
                  </button>
                </ShowIf>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

ConnectionsTable.propTypes = {
  items: PropTypes.array
};
