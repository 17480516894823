import React from 'react';
import PropTypes from 'prop-types';
import { LICENSE, paths } from '../../../constants';
import { EntitlementForm, ShowIf } from '../../fragments';
import { useRouteMatch, useParams, generatePath } from 'react-router-dom';

export default function Form(props) {
  const params = useParams();
  const noSelection = props.selected?.length === 0;

  // Determine whether to show a delete button.
  // Show the delete button if the user is editing, or creating a batch.
  const match = params?.batch_id
    ? useRouteMatch(
        generatePath(paths.batches.edit, params) ||
          generatePath(paths.batches.create, params)
      )
    : null;

  return (
    <EntitlementForm {...props}>
      {({ valid, onChange, onSubmit, onReset, onRemove, value }) => (
        <div className="form flex bulk-edit-form">
          <div className="form-group">
            <label hidden htmlFor="perpetual">
              License Type
            </label>
            <select
              id="perpetual"
              name="perpetual"
              onChange={onChange}
              value={value.perpetual ?? ''}
              disabled={noSelection}
            >
              <option value="">- Select One -</option>
              <option value={LICENSE.SUBSCRIPTION.id}>Subscription</option>
              <option value={LICENSE.PERPETUAL.id}>Perpetual</option>
            </select>
          </div>
          <ShowIf value={!props.alternate}>
            <div className="form-group">
              <label hidden htmlFor="start_date">
                Start Date
              </label>
              <input
                id="start_date"
                name="start_date"
                label="Start Date: "
                type="date"
                max={value.end_date ?? ''}
                value={value.start_date ?? ''}
                onChange={onChange}
                disabled={noSelection || value.perpetual === null}
              />
            </div>
          </ShowIf>
          <div className="form-group">
            <label hidden htmlFor="end_date">
              End Date
            </label>
            <input
              id="end_date"
              name="end_date"
              label="End Date: "
              type="date"
              disabled={
                noSelection ||
                value.perpetual === LICENSE.PERPETUAL.id ||
                value.perpetual === null
              }
              min={value.start_date ?? ''}
              value={value.end_date ?? ''}
              onChange={onChange}
            />
          </div>
          <div className="form-actions">
            <button
              title="Submit"
              type="button"
              className="btn"
              disabled={noSelection || !valid}
              onClick={() => onSubmit(value)}
            >
              Apply Changes
            </button>

            <button
              title="Reset"
              type="button"
              className="btn btn-outline btn-muted"
              disabled={noSelection}
              onClick={() => onReset()}
            >
              Clear Fields
            </button>

            <ShowIf value={match}>
              <button
                title="Delete"
                id="delete"
                type="button"
                className="btn btn-outline btn-muted"
                onClick={() => onRemove()}
                disabled={noSelection}
              >
                Delete
              </button>
            </ShowIf>
          </div>
        </div>
      )}
    </EntitlementForm>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  onRemove: PropTypes.func,
  selected: PropTypes.array,
  alternate: PropTypes.bool,
  value: PropTypes.object
};
