import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

const fields = [
  { id: 'title', name: 'Batch Title' },
  { id: 'account_name', name: 'Account' },
  { id: 'entitlement_batch_to_crm_id', name: 'External ID' }
];

function BatchesFilter({ onChange, onReset, value }) {
  const { accountId } = useParams();

  return (
    <div className="my">
      <h4>Filters</h4>
      <form className="flex">
        {fields
          // we don't want to show the account input on the account page
          .filter(field => field.id !== 'account_name' || !accountId)
          .map(({ id, name }) => (
            <div className="form-group" key={id}>
              <label htmlFor={id}>{name}</label>
              <input
                id={id}
                name={id}
                type="text"
                onChange={onChange}
                value={value[id] ?? ''}
              />
            </div>
          ))}
        <div className="form-group">
          <label htmlFor="complete">Status</label>
          <select
            id="complete"
            name="complete"
            onChange={onChange}
            value={value.complete ?? ''}
          >
            <option value="">Any</option>
            <option value="true">Published</option>
            <option value="false">Draft</option>
          </select>
        </div>
        <button
          type="button"
          onClick={onReset}
          className="btn btn-outline btn-muted"
        >
          Clear Filter
        </button>
      </form>
    </div>
  );
}

BatchesFilter.propTypes = {
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  value: PropTypes.object
};

export default BatchesFilter;
