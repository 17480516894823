import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  AuthBlockedForm,
  AuthTypeForm,
  PasswordInput,
  ShowIf,
  ShowWith
} from '../fragments';
import { setForm } from '../../redux/slices/forms';
import SelectRolesForm from './SelectRolesForm';
import { useParams } from 'react-router';
import ResetSQSForm from '../fragments/ResetSQSForm';

const fields = [{ id: 'username', name: 'Username' }];

function PasswordAuthForm(props) {
  const params = useParams();
  const isCreate = !(params.authenticator_id ?? null);

  return (
    <div id="password-auth" className="user-details">
      <AuthTypeForm fields={fields}>
        <PasswordInput
          label="Password"
          rquired={isCreate}
          value={props.form.password ?? ''}
          onChange={password => props.setForm({ ...props.form, password })}
          options={{ strict: true, length: 16 }}
        />
        <ShowWith value={props.error}>{error => <p>{error}</p>}</ShowWith>
        <AuthBlockedForm />
        <ShowIf value={!isCreate}>
          <ResetSQSForm />
        </ShowIf>
        <SelectRolesForm privileged />
      </AuthTypeForm>
    </div>
  );
}

PasswordAuthForm.propTypes = {
  setForm: PropTypes.func,
  form: PropTypes.object,
  error: PropTypes.string
};

const mapStateToProps = state => ({ form: state.forms.auth });
const mapDispatchToProps = {
  setForm: data => setForm({ data, type: 'auth' })
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordAuthForm);
