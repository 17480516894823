import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EntitlementForm, MessageBlock, Tabs, Tab, Search } from '../fragments';
import { LICENSE } from '../../constants';
import { getCreateBatchErrors, getErrors } from '../../utils';
import * as services from '../../redux/services';
import { SelectAccountsTable, SelectProductsTable } from '../tables';
import { EntitlementAccountsFilter } from '../filters';
import ProductsFilter from '../filters/ProductsFilter';
import SelectAllItems from '../fragments/SelectAllItems';
import { DEFAULT_VALUE as EMPTY_ENTITLEMENT_FORM } from '../fragments/EntitlementForm';

const FormTab = { ISBN: 'ISBN', SEARCH: 'SEARCH' };

function toISBNs(text) {
  return (text ?? '')
    .split('\n')
    .filter(str => !!str)
    .map(str => str.trim());
}

const DEFAULT_STATE = {
  tab: FormTab.ISBN,
  errors: [],
  accounts: [],
  products: [],
  isbns: '',
  entitlementPrefill: EMPTY_ENTITLEMENT_FORM
};

export default function AddContentForm(props) {
  // some state tracking
  const [form, setForm] = useState({
    ...DEFAULT_STATE,
    accounts: props.accounts,
    entitlementPrefill: props.entitlementPrefill ?? EMPTY_ENTITLEMENT_FORM
  });
  const [create, createMutation] =
    services.entitlements.useCreateEntitlementsMutation();

  const handle = {
    onChangeTab: tab => setForm({ ...form, tab }),
    onChangeAccounts: accounts => setForm({ ...form, accounts }),
    onSubmit: async value => {
      const data = {
        perpetual: value.perpetual,
        start_date: value.start_date,
        end_date: value.end_date,
        isbns: toISBNs(form.isbns ?? ''),
        products: form.products ?? [],
        accounts: form.accounts ?? [props.accountId],
        batch_id: props.batchId
      };

      // clearing these here so the user can switch between tabs without losing their work but also won't submit ISBNs and IDs simultaneously
      if (form.tab === FormTab.ISBN) {
        data.products = [];
      } else {
        data.isbns = [];
      }

      // client-side validation errors
      const errors = getCreateBatchErrors(data);

      if (!errors.length) {
        setForm({ ...form, errors: [] });
        // add content, catching validation errors and ignoring the rest
        const res = await create(data)
          .unwrap()
          // instead of updating state with the error messages, we'll just let the MessageBlock pull them from `createMutation`
          .catch(() => null);

        if (res !== null) {
          // If user value was subscription, prefill the form, else return form to empty state
          setForm({
            ...DEFAULT_STATE,
            accounts: form.accounts,
            entitlementPrefill: value
          });

          props.onClose([res.message, ...res.errors], form.accounts, value);
        }
      } else {
        setForm({ ...form, errors });
      }
    }
  };

  // first error is always a status message
  const [message, ...errors] = [
    ...form.errors,
    ...getErrors(createMutation.error)
  ];

  // disable "add items" until an account has been selected and ISBN text or entitlements exist
  const isSubmitDisabled =
    !(form.isbns.trim() || form.products.length) || !form.accounts.length;

  return (
    <div>
      <h1 className="h2">Add Products</h1>
      <form>
        <Tabs selectedId={form.tab} onChangeTab={handle.onChangeTab}>
          {/* @todo: refactor using EntitlementForm `errors` */}
          <MessageBlock
            error
            className="my"
            copy={errors.length > 0}
            messages={errors}
            outline
          >
            {message || null}
          </MessageBlock>
          {/* switched on "form.tab" */}
          <Tab title="Item Number" id={FormTab.ISBN}>
            <div className="form-group">
              <label htmlFor="isbn">Item Number Entry</label>
              <textarea
                id="isbn"
                name="isbns"
                rows={3}
                onChange={e => setForm({ ...form, isbns: e.target.value })}
                value={form.isbns ?? ''}
                required
              />
            </div>
          </Tab>
          <Tab title="Search" id={FormTab.SEARCH}>
            <Search
              query={services.products.useGetProductsQuery}
              filter={ProductsFilter}
              table={tableProps => (
                <>
                  <SelectAllItems {...tableProps} selected={form.products} />
                  <SelectProductsTable
                    {...tableProps}
                    selected={form.products}
                    onSelect={products => setForm({ ...form, products })}
                  />
                </>
              )}
            />
          </Tab>
        </Tabs>
        <Search
          className="mt"
          filter={EntitlementAccountsFilter}
          params={{ src_account_id: props.accountId, archived: false }}
          query={services.accounts.useGetAccountsQuery}
          table={tableProps => (
            <SelectAccountsTable
              onSelect={accounts => setForm({ ...form, accounts })}
              selected={form.accounts}
              {...tableProps}
            />
          )}
        />
        <EntitlementForm prefill={form.entitlementPrefill}>
          {({ value, onChange }) => (
            <>
              {/* perpetual/subscription */}
              <div className="form-group mt">
                <label htmlFor="perpetual">License Type</label>
                <select
                  id="perpetual"
                  name="perpetual"
                  onChange={onChange}
                  value={value.perpetual ?? ''}
                  required
                >
                  <option value="">- Select One -</option>
                  {Object.values(LICENSE).map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              {/* start date */}
              <div className="flex mt">
                <div className="form-group">
                  <label htmlFor="start_date">Start Date</label>
                  <input
                    type="date"
                    id="start_date"
                    name="start_date"
                    onChange={onChange}
                    value={value.start_date ?? ''}
                    max={value.end_date}
                    required
                  />
                </div>
                {/* end date */}
                <div className="form-group">
                  <label htmlFor="end_date">End Date</label>
                  <input
                    type="date"
                    id="end_date"
                    name="end_date"
                    disabled={value.perpetual === LICENSE.PERPETUAL.id}
                    required={value.perpetual === LICENSE.SUBSCRIPTION.id}
                    onChange={onChange}
                    value={value.end_date ?? ''}
                    min={value.start_date ?? ''}
                  />
                </div>
              </div>
              <div className="my">
                <button
                  className="btn"
                  type="button"
                  onClick={() => handle.onSubmit(value)}
                  disabled={isSubmitDisabled || createMutation.isLoading}
                >
                  Add {form.tab === FormTab.ISBN ? 'Item Numbers' : 'Items'}
                </button>
                <button
                  className="btn btn-muted btn-outline"
                  type="button"
                  onClick={() => props.onClose()}
                  disabled={createMutation.isLoading}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </EntitlementForm>
      </form>
    </div>
  );
}

AddContentForm.propTypes = {
  accountId: PropTypes.number,
  batchId: PropTypes.number,
  onClose: PropTypes.func,
  accounts: PropTypes.array,
  entitlementPrefill: PropTypes.object
};
