import React from 'react';
import PropTypes from 'prop-types';

import { entitlements } from '../../../redux/services';
import { InteractiveTable, MessageBlock } from '../../fragments';
import { useGetEntitlementIDsQuery } from '../../../redux/services/entitlements';
import Table from './Table';
import Form from './Form';
import SelectAllItems from '../../fragments/SelectAllItems';
import { MAX_SELECT_ALL_RESULTS } from '../../../constants';

export default function BulkEntitlementsTable(props) {
  const query =
    props.total <= MAX_SELECT_ALL_RESULTS
      ? useGetEntitlementIDsQuery(props.filter)
      : {};

  const table = tableProps => <Table {...tableProps} />;
  const selectAllItems = tableProps => (
    <SelectAllItems {...tableProps} ids={query.data ?? []} />
  );

  return (
    <>
      <MessageBlock
        className={`msg mb msg-${props.status.state} msg-outline mt`}
      >
        {props.status.message}
      </MessageBlock>
      <InteractiveTable
        {...props}
        table={table}
        form={Form}
        selectAllItems={selectAllItems}
        updateMutation={entitlements.useUpdateEntitlementsMutation}
        removeMutation={entitlements.useRemoveEntitlementsMutation}
      />
    </>
  );
}

BulkEntitlementsTable.propTypes = {
  items: PropTypes.array,
  isLoading: PropTypes.bool,
  filter: PropTypes.object,
  status: PropTypes.object,
  total: PropTypes.number
};
